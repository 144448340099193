import request from "@/libs/request";

// 首页菜单栏二级导航
export function homeBrandSubmenuApi() {
  return request({
    url: "/get_menus",
    method: "get",
  });
}
export function homeArticleSubmenuApi() {
  return request({
    url: "/article/list",
    method: "get",
  });
}

// 产品推荐选项卡
/*
 * 获取首页推荐产品
 * */
export function recommendProductList(data) {
  return request({
    url: "/home_index",
    method: "get",
    params: data,
  });
}

// 首页轮播图
export function homeBannerApi() {
  return request({
    url: "/home_banner",
    method: "get",
  });
}

// 首页资源文档
export function homeArticleApi() {
  return request({
    url: "/home_article",
    method: "get",
  });
}
// 首页底部
export function homeFooterApi() {
  return request({
    url: "/home_config",
    method: "get",
  });
}

// 下载文件
export function downloadFilesApi(params) {
  return request({
    url: `/download`,
    method: "get",
    params,
    responseType: "blob",
  });
}
