<template>
  <div class="recommendTabs">
    <div :class="$store.state.media.isDesktop == true ? 'tabs-Pc' : 'tabs-notPc'">
      <div
        class="tabs-item"
        :class="{ active: currentTab == index }"
        v-for="(item, index) in tabList"
        :key="index"
        @click.stop="changeTab(index)"
      >
        <h3
          class="item-title"
          v-html="$store.state.media.isDesktop == true ? item.title1 : item.title2"
        ></h3>
        <!-- 有样式 排版统一的需求，需使用v-html做标签识别渲染-->
        <div
          class="item-text"
          v-html="item.text"
          v-if="$store.state.media.isDesktop == true"
        ></div>
      </div>
    </div>

    <div :class="$store.state.media.isDesktop == true ? 'tabPane-Pc' : 'tabPane-notPc'">
     <product-card :List="productList"></product-card>
    </div>
  </div>
</template>

<script setup>
import { recommendProductList } from "@/api/home";
import ProductCard from '@/components/productCard.vue'
import {
  ref,
  reactive,
  onMounted,
  defineOptions,
  watchEffect,
  computed,
  getCurrentInstance,
} from "vue";


defineOptions({
  name: "RecommendTabs",
});


let currentTab = ref(0);
let productList = reactive([]);
let tabList = reactive([
  {
    title1: "个人证书(DV 证书)",
    title2: "个人证书<br />(DV 证书)",
    text: "个人博客、个人项目等<br />可选择DV SSL证书。",
  },
  {
    title1: "传统行业(OV 证书)",
    title2: "传统行业<br />(OV 证书)",
    text: `企业官网、电商、教育、医疗、公共 部门等<br />可选择OV SSL证书。`,
  },
  {
    title1: "金融机构(EV 证书)",
    title2: "金融机构<br />(EV 证书)",
    text: "银行、金融、保险、电子商务、中大型企 业、政府机关等，可选择EV SSL证书。",
  },
]);
const changeTab = (e) => {
  currentTab.value = e;
  getproductList({ validation_level: currentTab.value });
};
// 获取列表
const getproductList = (level) => {
  // console.log(level);
  recommendProductList(level).then((res) => {
    if (res.status == 200) {
      res.data.list.map((item) => {
        item.showButton = false;
        return item;
      });
      productList.length = 0; // 清空数组
      productList.push(...res.data.list); // 添加新数据到数组
    }
  });
};

onMounted(() => {
  // placeOrderFormData.periods =purchasePeriod.periods_price_txt[0]
  getproductList({ validation_level: currentTab.value });
});

</script>

<style lang="less" scoped>
.recommendTabs {
  background-color: #fff;
}
// 选项卡
.tabs-Pc {
  background-color: #fff;
  display: flex;
  width: 100%;
  padding: 0 25%;
  .tabs-item {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33.3%;
    // height: 110px;
    color: #a5a5a5;
    background-color: #fff;
    box-sizing: border-box;
    transition: all 200ms;
    cursor: pointer;
    padding: 15px 0;
    position: relative;
    .item-title {
      color: #000;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .item-text {
      width: 100%;
      max-width: 300px;
      min-height: 63px;
      text-align: center;
    }
  }
  .tabs-item::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .active {
    color: #0178fa;
    background-image: linear-gradient(to top, #f3f5f7, #fff);
    .item-title {
      color: #0178fa;
      font-weight: 600;
      margin-bottom: 15px;
    }
  }
  .active::after {
    border-bottom: 4px solid #0178fa;
  }
}

// 选项卡列表
.tabPane-Pc {
  background-color: #f2f5fa;
  padding: 20px 5% 30px;

}

//----------- 兼容部分----------
// 选项卡
.tabs-notPc {
  background-color: #fff;
  display: flex;
  width: 100%;

  .tabs-item {
    // flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 33.3%;
    // height: 110px;
    color: #a5a5a5;
    background-color: #fff;
    box-sizing: border-box;
    transition: all 200ms;
    cursor: pointer;
    padding: 15px 0;
    position: relative;
    .item-title {
      color: #000;
      font-weight: 600;
    }
    .item-text {
      width: 100%;
      max-width: 300px;
      text-align: center;
    }
  }
  .tabs-item::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .active {
    color: #0178fa;
    background-image: linear-gradient(to top, #f3f5f7, #fff);
    .item-title {
      color: #0178fa;
      font-weight: 600;
    }
  }
  .active::after {
    border-bottom: 4px solid #0178fa;
  }
}
.tabPane-notPc {
  background-color: #f2f5fa;
  padding: 20px 10% 30px;

}

</style>
