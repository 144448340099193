import request from "@/libs/request";

/*
 * 登录
 * */
export function AccountLogin(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}
// 微信扫码登录
export function weChatLoginApi() {
  return request({
    url: "/scan_login_wx",
    method: "get",
  });
}
// 微信扫码登录
export function checkWeChatScanLoginApi(data) {
  return request({
    url: `/check_scan_login/${data.key}`,
    method: "get",
  });
}
/*
 * 注册
 * */
export function AccountRegister(data) {
  return request({
    url: "/register",
    method: "post",
    data,
  });
}
// 获取发送验证码的key
export function MsgCodeKey() {
  return request({
    url: "/verify_code",
    method: "get",
  });
}
// 获取短信图像码
export function MsgCaptch(data) {
  return request({
    url: "/sms_captcha",
    method: "get",
    data,
  });
}
// 获取邮箱验证码-注册
export function MailCode(data) {
  return request({
    url: "/reset/reg_verify",
    method: "post",
    params: data,
  });
}
// 重置账号密码
export function resetpwdApi(data) {
  return request({
    url: "/resetpwd",
    method: "post",
    data,
  });
}
// 获取邮箱验证码-重置密码
export function resetPwdMailCode(data) {
  return request({
    url: "/reset/reset_pwd_verify",
    method: "post",
    params: data,
  });
}
// 第三方直登录
export function thirdLoginApi(data) {
  return request({
    url: "/third_register",
    method: "post",
    data,
  });
}
// QQ登录
export function QQLoginApi(data) {
  return request({
    url: "/qq_login",
    method: "post",
    data,
  });
}
