<template>
  <div class="home">
    <home-banner></home-banner>
    <recommend-tabs></recommend-tabs>
    <service></service>
    <document></document>
  </div>
</template>

<script>
import HomeMenu from "@/components/home-menu";
import HomeBanner from "@/components/home-banner";
import GlobalFooter from "@/components/global-footer";
import RecommendTabs from "@/components/recommendTabs.vue";
import Service from "@/components/service";
import Document from "@/components/document";
export default {
  name: "HomeView",
  components: {
    HomeMenu,
    HomeBanner,
    GlobalFooter,
    RecommendTabs,
    Service,
    Document,
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.ivu-layout-header {
  padding: 0px;
}

.layout-footer-center {
  text-align: center;
}
.dev-run-preview .dev-run-preview-edit {
  display: none;
}
.ivu-layout-header {
  background-color: #fff;
}

::deep(.ivu-menu-horizontal) {
  height: 66px !important;
}

.ivu-menu-horizontal.ivu-menu-light:after {
  display: none;
}
</style>
