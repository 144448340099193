<template>
  <div :class="$store.state.media.isDesktop == true
    ? 'personal_center-Pc'
    : 'personal_center-notPc'
    ">
    <div class="personal_left" v-if="ArticleCategory != []">
      <div class="left-item" v-for="(art, index) in ArticleCategory" :key="art.id">
        <div class="category" @click="isshowItemList(index)">
          <div class="title">{{ art.title }}</div>
          <Icon :class="art.showItem == true ? 'arrow-bottom' : 'arrow-right'" type="ios-arrow-forward" />
        </div>
        <transition name="slide-up">
          <div v-show="art.showItem">
            <div class="article" v-for="(artDetail, idx) in art.article" :key="idx"
              :class="{ active: breadCurrent == artDetail.id }" @click="getDetail(artDetail, idx)">
              {{ artDetail.title }}
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="personal_right">
      <Breadcrumb separator=">" class="bread">
        <BreadcrumbItem to="/" class="breadItem"> 首页 </BreadcrumbItem>
        <BreadcrumbItem class="breadItem"> 文档 </BreadcrumbItem>
        <BreadcrumbItem v-for="(item, idx) in breadcrumbItems" :key="idx" class="breadItem">
          {{ item.catename }}
        </BreadcrumbItem>
        <BreadcrumbItem v-for="(item, idx) in breadcrumbItems" :key="idx" class="breadItem">
          {{ item.title }}
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider />
      <div class="articleDetail">
        <div class="title">{{ detail.title }}</div>
        <div class="content" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watchEffect, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { articleListApi, articleDetailApi } from "@/api/article";
let router = useRouter();
// --------左侧菜单处理----------
const ArticleCategory = reactive([]);
const breadCurrent = ref(0);
let route = useRoute();
let detail = reactive({});
// let routeQuery = JSON.parse(history.state.obj)
let article_id = route.params.id;
let slug = route.params.slug;
let showItem = ref(false);
const articleList = () => {
  articleListApi().then((res) => {
    if (res.status == 200) {
      ArticleCategory.length = 0; // 清空数组
      ArticleCategory.push(...res.data.list); // 添加新数据到数组
      if (route.query != {}) {
        breadCurrent.value = article_id;
        articleDetail({ slug: slug });
      }
    }
  });
};
const articleDetail = (data) => {
  articleDetailApi(data).then((res) => {
    if (res.status == 200) {
      Object.assign(detail, res.data.info);
      breadcrumbItems[0] = detail;
    }
  });
};
function getDetail(menu, index) {
  breadCurrent.value = menu.id;
  articleDetail({ slug: menu.slug });
  breadcrumbItems[0] = menu;
  router.push({ name: 'Article', params: { slug: `${menu.slug}` } });
}
const isshowItemList = (index) => {
  ArticleCategory[index].showItem = !ArticleCategory[index].showItem
}
//----------- 面包屑处理--------
let breadcrumbItems = reactive([]);
function checkShowItem() {
  if (ArticleCategory.length > 0 && article_id!=undefined) {
    ArticleCategory.map((item, index )=> {
      if(breadCurrent.value == article_id){
        ArticleCategory[index].showItem = true
      }
    })
  }
}

// 监听路由变化并更新面包屑
watchEffect(() => {
  checkShowItem()
});
// 在组件挂载完成后初始化面包屑
onMounted(() => {

  articleList();
});
</script>

<style lang="less" scoped>
.personal_center-Pc {
  padding: 25px 5% 40px;
  display: flex;
  flex-direction: row;

  .personal_left {
    width: 238px;
    height: 100%;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
    padding: 25px 0;

    .left-item {
      display: block;
      padding: 0px 10px 15px;
      min-width: 205px;
      text-align: left;
      color: #000;
      font-size: 16px;
    }

    .category {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .arrow-bottom {
        transform: rotate(90deg);
        transition: all 0.4s;
      }
      .arrow-right {
        transform: rotate(0deg);
        transition: all 0.4s;
      }
    }

    .article {
      // text-align: center;
      cursor: pointer;
      padding: 10px 10px;
      white-space: nowrap;
      /*强制内容在一行显示*/
      overflow: hidden;
      /*超出部分溢出*/
      text-overflow: ellipsis;
      /*溢出的部分使用省略号*/
    }

    .active {
      border-right: 4px solid;
      color: #2b85e4;
      background: rgba(0, 120, 249, 0.18);
    }
  }

  .breadItem {
    cursor: pointer;
  }

  .personal_right {
    // margin-top: 8px;
    width: calc(100% - 264px);

    height: 700px;
    padding: 25px 42px;
    margin-left: 15px;
    background-color: #fff;

    :deep(.ivu-breadcrumb) {
      font-size: 16px;
    }

    :deep(.ivu-divider-horizontal) {
      margin: 12px 0;
    }

    .articleDetail {
      .title {
        font-size: 21px;
        font-weight: 600;
        text-align: center;
        padding: 10px 0;
      }

      .content {
        padding: 15px;
      }
    }
  }
}

.personal_center-notPc {
  padding: 10px 5% 40px;
  display: flex;
  flex-direction: column;

  .personal_left {
    // width: 238px;
    height: 100%;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0;
    margin-bottom: 24px;

    .left-item {
      display: block;
      padding: 0px 10px 15px;
      width: 100%;
      text-align: left;
      color: #000;

      font-size: 16px;
    }
    .category {
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .arrow-bottom {
        transform: rotate(90deg);
        transition: all 0.4s;
      }
      .arrow-bottom {
        transform: rotate(90deg);
        transition: all 0.4s;
      }
    }

    .article {
      // text-align: center;
      cursor: pointer;
      padding: 10px 10px;
      white-space: nowrap;
      /*强制内容在一行显示*/
      overflow: hidden;
      /*超出部分溢出*/
      text-overflow: ellipsis;
      /*溢出的部分使用省略号*/
    }

    .active {
      border-right: 4px solid;
      color: #2b85e4;
      background: rgba(0, 120, 249, 0.18);
    }
  }

  .personal_right {
    .articleDetail {
      .title {
        font-size: 21px;
        font-weight: 600;
        text-align: left;
        padding: 10px 0;
        color: #000;
      }

      .content {
        padding: 15px;
      }
    }
  }
}
</style>
