<template>
  <Footer
    class="global-footer"
    :class="
      $store.state.media.isDesktop == true ? 'global-padding-Pc' : 'global-padding-notPc'
    "
  >
    <div
      :class="
        $store.state.media.isDesktop == true
          ? 'footer-content-Pc'
          : 'footer-content-notPc'
      "
    >
      <div class="content-left lines">
        <div class="left-logo">
          <img src="@/assets/images/logo-2.png" mode="" />
        </div>
        <!-- <div class="left-phoneNumber">
          <Icon type="ios-call" size="27" />
          <div class="number">{{config.phone}}</div>
        </div> -->
      </div>
      <div class="content-center">
        <div class="center-aboutUs">
          <div class="aboutUs-title">关于我们</div>
          <div class="about-list">
            <div class="list-item">认识瑞杰信</div>
            <div class="list-item">公司介绍</div>
            <!-- <div class="list-item">社会招聘</div> -->
          </div>
        </div>
        <div class="center-aboutUs">
          <div class="aboutUs-title">服务</div>
          <div class="about-list">
            <div class="list-item" @click="goToPage('Product')">产品选购</div>
            <div class="list-item" >合作专区</div>
            <div class="list-item" @click="goToPage('Article')">资源文档</div>
            <div class="list-item">服务协议</div>
          </div>
        </div>
        <div class="center-aboutUs about">
          <div class="aboutUs-title">联系方式</div>
          <div class="about-list">
            <div class="list-item">
              <div class="item-image">
                <img src="@/assets/images/tel.png" alt="" />
              </div>
              <div class="item-text">手机号：{{ config.phone }}</div>
            </div>
            <div class="list-item">
              <div class="item-image">
                <img src="@/assets/images/email.png" alt="" />
              </div>
              <div class="item-text">邮箱：{{ config.email }}</div>
            </div>
            <div class="list-item">
              <div class="item-image">
                <img src="@/assets/images/customer.png" alt="" />
              </div>
              <!-- <Icon type="ios-mail" size="23" /> -->
              <div class="item-text">客服: {{ config.tqq }}</div>
            </div>
            <div class="list-item">
              <div class="item-image" style="width: 23px">
                <img src="@/assets/images/address.png" alt="" />
              </div>
              <div class="item-text">地址：{{ config.company_name }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right" v-if="$store.state.media.isDesktop == true">
        <div class="qrCodeName">瑞杰信公众号</div>
        <div class="qrCodePic">
          <img :src="config.gzh_image" alt="" />
        </div>
      </div>
    </div>
    <div
      :class="
        $store.state.media.isDesktop == true ? 'footer-link-Pc' : 'footer-link-notPc'
      "
    >
      <div class="link">
        <div class="link-title">友情链接：</div>
        <div class="link-list">
          <div class="list-item">IP证书</div>
          <div class="list-item text-lines">通配符证书</div>
          <div class="list-item text-lines">百度</div>
          <div class="list-item text-lines">DNSPOD</div>
        </div>
      </div>
      <!-- <div class="authentication">
        <div class="pic"></div>
        <div class="pic"></div>
        <div class="pic"></div>
      </div> -->
    </div>
    <div
      :class="
        $store.state.media.isDesktop == true
          ? 'footer-filings-Pc'
          : 'footer-filings-notPc'
      "
    >
      <div class="filings-left">
        COPYRIGHT ◎2019-2024 {{ config.name }}. ALL RIGHTS RESERVED.
      </div>
      <div class="filings-right">
        <div class="right-item">
          <!-- <Icon type="ios-mail" size="23" /> -->
          <div class="item-text">{{ config.beian }}</div>
        </div>
        <div class="right-item">
          <!-- <div class="item-image">
                <img src="@/assets/images/customer.png" alt="" />
              </div> -->
          <!-- <Icon type="ios-mail" size="23" /> -->
          <div class="item-text">{{ config.gwab }}</div>
        </div>
      </div>
    </div>
  </Footer>
</template>

<script setup>
import { homeFooterApi } from "@/api/home";
import { ref, reactive, onMounted, defineOptions } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
let router = useRouter();
let config = reactive({});
const homeFooter = () => {
  homeFooterApi().then((res) => {
    Object.assign(config, res.data.info);
  });
};
const goToPage = (name) => {
  // 点击logo返回首页处理 多端共用
  // if (name == "Home") {
  //   meunActive.value = null;
  //   drawerShow.value = false;
  //   twoLevelDrawerShow.value = false;
  // // }
  router.replace({ name: name });
};
onMounted(() => {
  homeFooter();
});
defineOptions({
  name: "GlobalFooter",
});
</script>

<style lang="less" scoped>
.global-footer {
  background-color: #0b1e2d;
  color: #9caab5;
}
.footer-content-Pc {
  margin-top: 60px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .content-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 60px;
    position: relative;
    .left-logo {
      width: 140px;
      height: 42px;
      margin: 20px 0;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .left-phoneNumber {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      color: #fff;
      background-color: #007bf8;
      padding: 5px 10px;
      border-radius: 3px;
      min-width: 160px;
      max-width: 160px;
      cursor: pointer;
      .number {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
  .lines::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 1px;
    left: 100%;
    height: 80%;
    background-color: #233444;
    transform: translate(-50%, -60%);
  }
  .content-center {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    // margin: 0 35px;

    position: relative;

    .center-aboutUs {
      padding: 0 10% 0 3%;
      min-width: 160px;
      .aboutUs-title {
        font-size: 14px;
        color: #fff;
        margin-bottom: 20px;
      }
      .list-item {
        margin-bottom: 15px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        .item-text {
          margin-left: 8px;
          min-width: 187px;
        }
        .item-image {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    // 单独处理联系方式的
    .about {
      .list-item {
        min-width: 70px;
      }
    }
  }
  .content-right {
    display: flex;
    flex-direction: column;
    // padding: 0 15px;
    margin: 0 50px;
    z-index: 10;

    .qrCodeName {
      font-size: 14px;
      color: #fff;
      margin-bottom: 20px;
      text-align: center;
    }
    .qrCodePic {
      width: 120px;
      height: 120px;
      // background-color: #fff;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.footer-link-Pc {
  border-top: 1px solid #233444;
  border-bottom: 1px solid #233444;
  padding: 10px 5px;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .link {
    display: flex;
    flex-direction: row;
    // width: 60%;
    .link-title {
      font-size: 14px;
      color: #fff;
      margin-right: 20px;
    }
    .link-list {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      .list-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        padding: 0 20px;
        position: relative;
      }
    }
  }
  .authentication {
    display: flex;
    flex-direction: row;

    .pic {
      width: 60px;
      height: 20px;
      background-color: #fff;
      margin-left: 10px;
    }
  }
}
.footer-filings-Pc {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .filings-right {
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    .right-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      margin-right: 5px;

      .item-text {
        margin-left: 5px;
      }
      .item-image {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          background-size: object-fit;
        }
      }
    }
  }
}
.text-lines::after {
  display: none;
}
// --------------兼容移动端-------------------
// 底部关于我们
.footer-content-notPc {
  margin-top: 60px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .content-left {
    display: flex;
    flex-direction: column;
    margin-right: 60px;
    .left-logo {
      width: 140px;
      height: 42px;
      margin: 20px 0;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .left-phoneNumber {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      color: #fff;
      background-color: #007bf8;
      padding: 5px 10px;
      border-radius: 3px;
      min-width: 160px;
      max-width: 160px;
      cursor: pointer;
      .number {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
  .content-center {
    display: flex;
    // width: 60%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin: 0 35px;
    position: relative;
    margin: 10px 0;
    padding: 10px 0;
    border-top: 1px solid #233444;
    border-bottom: 1px solid #233444;

    .center-aboutUs {
      padding: 0 10px;
      margin-bottom: 10px;
      min-width: 160px;
      .aboutUs-title {
        font-size: 14px;
        color: #fff;
        margin-bottom: 5px;
      }
      .list-item {
        margin-top: 10px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        .item-text {
          margin-left: 8px;
          min-width: 187px;
        }
        .item-image {
          width: 20px;
          height: 20px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
    // 单独处理联系方式的
    .about {
      .list-item {
        min-width: 70px;
      }
    }
  }
  .lines::before {
    display: none;
  }

  .content-right {
    display: flex;
    flex-direction: column;
    // padding: 0 30px;
    align-items: center;
    justify-items: center;

    .qrCodeName {
      font-size: 14px;
      color: #fff;
      margin-bottom: 20px;
    }
    .qrCodePic {
      width: 120px;
      height: 120px;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
// 底部友情链接
.footer-link-notPc {
  border-top: 1px solid #233444;
  border-bottom: 1px solid #233444;
  padding: 10px 5px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .link {
    display: flex;
    flex-direction: column;
    // width: 60%;]
    margin-bottom: 10px;
    .link-title {
      font-size: 14px;
      color: #fff;
    }
    .link-list {
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;

      .list-item {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-items: center;
        align-items: center;
        padding: 0 10px;
        position: relative;
      }
    }
  }
  .authentication {
    display: flex;
    flex-direction: row;

    .pic {
      width: 60px;
      height: 20px;
      background-color: #fff;
      margin-left: 10px;
    }
  }
}
.footer-filings-notPc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  .filings-right {
    display: flex;
    flex-direction: column;
    .right-item {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-items: center;
      align-items: center;
      margin-right: 5px;
      .item-text {
        margin-left: 5px;
      }
      .item-image {
        width: 20px;
        height: 20px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          background-size: object-fit;
        }
      }
    }
  }
}
</style>
