import { createStore } from "vuex";
import media from "./module/media";
import VuexPersistence from "vuex-persist";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  plugins: [
    new VuexPersistence({
      reducer: (state) => ({
        media: state.media,
      }),
      storage: window.localStorage,
    }).plugin,
  ],
  modules: {
    media,
  },
});

