<template>
  <div
    class="Service-Pc"
    :class="
      $store.state.media.isDesktop == true ? 'Service-Pc' : 'Service-notPc'
    "
  >
    <div class="Service-box">
      <div class="title">专家服务</div>
      <div class="Service-item">
        <div class="item-box noline">
          <div class="box-icon">
            <img src="@/assets/images/content_1.jpg" alt="" />
          </div>
          <div class="box-title">7*24小时服务</div>
          <div class="box-text">
            全天候在线客服服务及电话咨询，随时随地快速响应，及时解决您的问题
          </div>
        </div>
        <div class="item-box">
          <div class="box-icon">
            <img src="@/assets/images/content_2.jpg" alt="" />
          </div>
          <div class="box-title">文档中心</div>
          <div class="box-text">
            为您提供全面的产品文档，新手教程及使用手册，助您轻松解决疑难困惑
          </div>
          <!-- <div class="box-tool">
            <div class="tool-center">SSL证书</div>
          </div> -->
        </div>
        <div class="item-box">
          <div class="box-icon">
            <img src="@/assets/images/content_3.jpg" alt="" />
          </div>
          <div class="box-title">常用工具</div>
          <div class="box-text">
            为客户或者运维人员提供检测、效验、文件转化、数据查询等工具
          </div>
          <!-- <div class="box-tool">
            <div class="tool-left">生成CSR</div>
            <div class="tool-right">SSL证书格式转换工具</div>
          </div> -->
        </div>
        <div class="item-box">
          <div class="box-icon">
            <img src="@/assets/images/content_4.jpg" alt="" />
          </div>
          <div class="box-title">技术工单服务</div>
          <div class="box-text">
            众多技术专家为您提供产品配置指导故障排查等技术支持，快速解决技术难题
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Service",
};
</script>
<style lang="less" scoped>
.Service-Pc {
  padding: 0 5%;
  background-color: #fff;
  .Service-box {
    position: relative;
    text-align: center;
    .title {
      margin-top: 15px;
      font-size: 24px;
      color: #000;
      position: relative;
      display: inline-block;
    }
    .title::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 60%; /* 横线的宽度，可以根据需要调整 */
      height: 3px;
      border-radius: 3px;
      background-color: #0178fa;
    }
    .Service-item {
      padding: 25px 0;
      display: flex;
      //   flex-direction: row;;
      flex-wrap: wrap;
      .item-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        position: relative;
        flex: 1;
        // width: calc(100% / 4);
        padding-bottom: 15px;
        .box-icon {
          width: 120px;
          height: 120px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .box-title {
          font-size: 18px;
          color: #000;
          font-weight: 600;
          margin: 20px 0 15px;
        }
        .box-text {
          padding: 0 60px;
          color: #878787;
        }
      }
      .item-box::before {
        content: "";
        position: absolute;
        top: 50%;
        width: 0.1px;
        right: 100%;
        height: 70%;
        background-color: #f1efef;
        transform: translate(-50%, -50%);
      }
      .noline::before {
        display: none;
      }
      .box-tool {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-top: 15px;
        padding: 0 10px;
        align-items: center;
        color: #0178fa;
        .tool-center {
          width: 100%;
        }
        .tool-left,
        .tool-center,
        .tool-right {
          cursor: pointer;
        }
      }
    }
  }
}
.Service-notPc {
  padding: 0 5%;
  background-color: #fff;
  .Service-box {
    position: relative;
    text-align: center;
    .title {
      margin-top: 15px;
      font-size: 24px;
      color: #000;
      position: relative;
      display: inline-block;
    }
    .Service-item {
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      .item-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        position: relative;
        flex: 1;
        // width: calc(100% / 4);
        padding-bottom: 15px;
        .box-icon {
          width: 120px;
          height: 120px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .box-title {
          font-size: 18px;
          color: #000;
          font-weight: 600;
          margin: 20px 0 15px;
        }
        .box-text {
          padding: 0 30px;
          color: #878787;
        }
      }
      .item-box::before {
        display: none;
      }
      .box-tool {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        margin-top: 15px;
        padding: 0 15%;
        align-items: center;
        color: #0178fa;
        .tool-center {
          width: 100%;
        }
        .tool-left,
        .tool-center,
        .tool-right {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
