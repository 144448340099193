import request from "@/libs/request";

// 产品列表
export function productListApi(data) {
  return request({
    url: `/get_product_list`,
    method: "get",
    params: data,
  });
}
// 计算价格
export function calculatePriceApi(data) {
  return request({
    url: `/order/price`,
    method: "post",
    data,
  });
}
