<template>
    <!-- 目前未使用 -->
  <div class="notFound">
    <div class="imgBox">
      <img src="@/assets/images/pay.jpg" alt="" />
    </div>
    <div class="tipBox">
      <div class="tip">正在跳转支付宝支付!请稍等~</div>
    </div>
  </div>
  <div v-html="apply"></div>
</template>
<script>
export default {
  name: "apply",
  data() {
    return {
      apply: "",
    };
  },
  mounted() {
    let form = this.$route.query.htmls; //接收表单内容
    this.apply = form;
    console.log(form);
    setTimeout(() => {
        document.forms[0].submit();
    }, 100);
    // this.$nextTick(() => {
    //   document.forms[0].submit(); //自动执行表单提交事件
    // });
  },
};
</script>


<style lang="less" scoped>
.notFound {
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: 500px;
  align-items: center;
  flex-direction: column;

  .imgBox {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 300px;
      height: 300px;
    }
  }

  .tipBox {
    text-align: center;
    .tip {
      font-size: 18px;
      margin-bottom: 10px;
      span {
        color: red;
        margin-right: 15px;
      }
    }
  }
}
</style>