import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import PersonalCenter from "@/views/User/Personal_Center.vue";
import User from "@/views/User/components/user";
import Info from "@/views/User/components/info";
import Balance from "@/views/User/components/balance";
import Cert from "@/views/User/components/cert";
import Order from "@/views/User/components/order";
import Company from "@/views/User/components/company";
import AccessKey from "@/views/User/components/accessKey";
import OrderDetail from "@/views/Order/orderDetail";
import CertDetail from "@/views/Cert/certDetail";
import Payment from "@/views/Order/payment";
import SanPayment from "@/views/Cert/sanPayment";
import Apply from "@/views/Order/apply";
import Product from "@/views/Product/product";
import Article from "@/views/Article/article";
import GenerateCsr from "@/views/Tool/generate_csr";
import CertConvert from "@/views/Tool/cert_convert";
import NotFound from "@/components/404";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: '/home',
    name: 'homeAlias',
    component: Home,
    alias: '/'
  },
  {
    path: "/Personal_Center",
    name: "Personal_Center",
    meta: {
      title: "个人中心",
    },
    component: PersonalCenter,
    redirect: "/user",
    children: [
      {
        path: "/user",
        name: "User",
        meta: {
          title: "首页",
        },
        component: User,
      },
      {
        path: "/info",
        name: "Info",
        meta: {
          title: "账号管理",
        },
        component: Info,
      },
      {
        path: "/balance",
        name: "Balance",
        meta: {
          title: "我的余额",
        },
        component: Balance,
      },
      {
        path: "/cert",
        name: "Cert",
        meta: {
          title: "我的证书",
        },
        component: Cert,
      },
      {
        path: "/order",
        name: "Order",
        meta: {
          title: "我的订单",
        },
        component: Order,
      },
      {
        path: "/company",
        name: "Company",
        meta: {
          title: "公司管理",
        },
        component: Company,
      },
      {
        path: "/accessKey",
        name: "AccessKey",
        meta: {
          title: "公司管理",
        },
        component: AccessKey,
      },
    ],
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    meta: {
      title: "订单详情",
    },
    component: OrderDetail,
  },
  {
    path: "/sanPayment",
    name: "SanPayment",
    meta: {
      title: "订单支付",
    },
    component: SanPayment,
  },
  {
    path: "/payment",
    name: "Payment",
    meta: {
      title: "订单支付",
    },
    component: Payment,
  },
  {
    path: "/certDetail",
    name: "CertDetail",
    meta: {
      title: "证书详情",
    },
    component: CertDetail,
  },
  {
    path: "/product/:slug.html",
    name: "Product",
    meta: {
      title: "产品选购",
    },
    component: Product,
    props: true,
    // beforeEnter: (to, from, next) => {
    //   const { slug } = to.params;
    //   next({ path: `/${slug}` });
    // },
  },
  // {
  //   path: '/:slug.html',
  //   component: Product
  // },
  {
    path: "/apply",
    name: "Apply",
    meta: {
      title: "支付宝支付",
    },
    component: Apply,
  },
  {
    path: "/article/:slug.html",
    name: "Article",
    meta: {
      title: "文档",
    },
    component: Article,
    props: true
  },
  // {
  //   path: '/article_index',
  //   name: 'ArticleAlias',
  //   component: Article,
  //   alias: '/'
  // },
  {
    path: "/generate_csr",
    name: "GenerateCsr",
    meta: {
      title: "CSR文件生成工具",
    },
    component: GenerateCsr,
  },
  {
    path: "/cert_convert",
    name: "CertConvert",
    meta: {
      title: "SSL证书格式转换工具",
    },
    component: CertConvert,
  },
  {
    path: '/404',
    name: '/404',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)',
    //访问主页的时候 重定向到index页面
    redirect: '/404',
  },
];

const router = createRouter({
  routes,
  history: createWebHistory(process.env.VITE_PUBLIC_PATH),
  pathMatch: (mode) => mode === 'history' ? /^\/.*\.html?$/ : /^\/.*$/,
  scrollBehavior() {
    return { top: 0 };
  },
});
// 添加全局的 beforeEach 导航守卫
router.beforeEach((to, from, next) => {
  if (to.matched.leng===0) {
    // 如果没有匹配到任何路由，则重定向到 404 页面
    next("/");
  } else {
    // 否则继续正常的导航
    next();
  }
});
export default router;
