import { createApp } from "vue";
import ViewUIPlus from "view-ui-plus";
import "view-ui-plus/dist/styles/viewuiplus.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/index.less";
import Cookies from "js-cookie";
import i18n from "./libs/i18n/i18";
import { createPinia } from "pinia";

// import './mock'

const app = createApp(App);

const pinia = createPinia();
app.config.globalProperties.$cookies = Cookies;

app.use(store).use(router).use(ViewUIPlus).use(pinia).use(i18n).mount("#app");
