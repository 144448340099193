<template>
  <div
    class="home-menu"
    :class="$store.state.media.isDesktop == true ? 'global-padding-Pc' : 'scope-padding'"
  >
    <div class="menu-logo" @click="goToPage('Home')">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="menu-nav-pc" v-if="$store.state.media.isDesktop == true">
      <div
        class="menuNavItem"
        @mouseenter="showSubmenuPc('1')"
        @mouseleave="hiddenSubmenuPC"
      >
        <div class="title">产品选购</div>
        <transition name="slide-up">
          <div class="menuBox" @click.self="hiddenSubmenuPC" v-show="meunActive == '1'">
            <div class="menuItemBox">
              <div class="menuItem brand_List">
                <div class="item-title brandCate">
                  <div class="item-title">按品牌</div>
                  <div class="allBrand" @click.self="goToPage('Product')">全部品牌</div>
                </div>
                <div class="item-content">
                  <div
                    class="imgItem"
                    v-for="brand in homeMenu.product.brands"
                    :key="brand.id"
                    @click="goToPageBySub('brand', brand.id,brand)"
                  >
                    <img :src="brand.image" alt="" />
                    <!-- <img src="@/assets/images/DV.png" alt="" /> -->
                  </div>
                </div>
              </div>
              <div class="menuItem menuItem_list">
                <div class="item-title">按审核要求</div>
                <div class="item-content">
                  <div class="item-list">
                    <div
                      class="item-list-item"
                      v-for="sh in homeMenu.product.sh"
                      :key="sh.sh_type"
                      @click="goToPageBySub('examine', sh.sh_type,sh)"
                    >
                      {{ sh.name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="menuItem menuItem_list">
                <div class="item-title">按使用功能</div>
                <div class="item-content">
                  <div class="item-list">
                    <div
                      class="item-list-item"
                      v-for="gn in homeMenu.product.gn"
                      :key="gn.gn_type"
                      @click="goToPageBySub('function', gn.gn_type,gn)"
                    >
                      {{ gn.name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="menuNavItem">
        <div class="title">合作专区</div>
      </div>
      <div
        class="menuNavItem"
        @mouseenter="showSubmenuPc('4')"
        @mouseleave="hiddenSubmenuPC()"
      >
        <div class="title">资源/文档</div>
        <transition name="slide-up">
          <div class="menuBox" @click.self="hiddenSubmenuPC" v-show="meunActive == '4'">
            <div class="menuItemBox">
              <div
                class="menuItem menuItem_list"
                v-for="art in articleSubmenuList"
                :key="art.id"
              >
                <div class="item-title">{{ art.title }}</div>
                <div class="item-content">
                  <div class="item-list">
                    <div
                      class="item-list-item"
                      v-for="artItem in art.article"
                      :key="artItem.id"
                      @click="goToPageBySub('Article', artItem.id, artItem)"
                    >
                      {{ artItem.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <Dropdown @on-click="toolsMenuClick">
        <div
          style="
            width: 100px;
            height: 60px;
            line-height: 60px;
            cursor: pointer;
            text-align: center;
          "
        >
          热门工具
        </div>
        <template #list>
          <DropdownMenu>
            <DropdownItem name="GenerateCsr">生成CSR</DropdownItem>
            <DropdownItem name="CertConvert">SSL证书格式转换工具</DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
      <div class="menuNavItem" v-show="isLoggedIn == true" @click="goToPage('User')">
        <div class="title">用户中心</div>
      </div>
      <div class="menuNavItem" v-show="isLoggedIn == false" @click="isShowLoginModal">
        <div class="title">登录</div>
      </div>
    </div>
    <div class="menu-nav-mobile" v-if="$store.state.media.isDesktop != true">
      <Icon type="md-menu" v-if="drawerShow == false" size="30" @click="openDrawer" />
      <Icon type="md-close" v-if="drawerShow == true" @click="closeDrawer" size="30" />
    </div>
  </div>

  <Drawer
    :mask="false"
    :closable="false"
    width="100"
    :styles="{
      padding: '66px 0 50px',
    }"
    v-model="drawerShow"
  >
    <div class="cellgroup">
      <div class="cell" @click="openSubmenu(1)">
        <div class="title">产品选购</div>
        <Icon type="ios-arrow-forward" />
      </div>
      <!-- <div class="cell" @click="openSubmenu(2)">
        <div class="title">品牌手册</div>
        <Icon type="ios-arrow-forward" />
      </div> -->
      <div class="cell" @click="openSubmenu(3)">
        <div class="title">合作专区</div>
        <Icon type="ios-arrow-forward" />
      </div>
      <div class="cell" @click="openSubmenu(4)">
        <div class="title">资源/文档</div>
        <Icon type="ios-arrow-forward" />
      </div>
      <div class="cell" @click="openSubmenu(5)">
        <div class="title">热工工具</div>
        <Icon type="ios-arrow-forward" />
      </div>
      <div class="cell" @click="goToPageByMobile('User')" v-show="isLoggedIn == true">
        <div class="title">用户中心</div>
        <Icon type="ios-arrow-forward" />
      </div>
      <div class="cell" @click="isShowLoginModalByMobile" v-show="isLoggedIn == false">
        <div class="title">登录</div>
        <Icon type="ios-arrow-forward" />
      </div>
    </div>
  </Drawer>
  <Drawer
    :mask="false"
    :closable="false"
    width="100"
    :styles="{
      padding: '66px 0 50px',
    }"
    v-model="twoLevelDrawerShow"
  >
    <div class="container">
      <div class="back">
        <Icon type="ios-arrow-back" size="16 " />
        <div class="backMenu" @click="twoLevelDrawerShow = false">返回主菜单</div>
      </div>
      <div class="content">
        <div class="menuItemBox-notPc" v-if="submenuActive == 1">
          <div class="menuTitle">产品选购</div>
          <div class="menuItem brand_List">
            <div class="item-title brandCate">
              <div class="title">按品牌</div>
              <div class="allBrand" @click.self="goToPageByMobile('Product')">
                全部品牌
              </div>
            </div>
            <div class="item-content">
              <div
                class="imgItem"
                v-for="brand in homeMenu.product.brands"
                :key="brand.id"
                @click="goToPageByMobileSub('brand', brand.id)"
              >
                <img :src="brand.image" alt="" />
                <!-- <img src="@/assets/images/DV.png" alt="" /> -->
              </div>
            </div>
          </div>
          <div class="menuItem menuItem_list">
            <Collapse simple :accordion="true">
              <Panel name="1">
                按审核要求
                <template #content>
                  <div class="item-list">
                    <div
                      class="item-list-item"
                      v-for="sh in homeMenu.product.sh"
                      :key="sh.sh_type"
                      @click="goToPageByMobileSub('examine', sh.sh_type)"
                    >
                      {{ sh.name }}
                    </div>
                  </div>
                </template>
              </Panel>
              <Panel name="2">
                按使用功能
                <template #content>
                  <div class="item-list">
                    <div
                      class="item-list-item"
                      v-for="gn in homeMenu.product.gn"
                      :key="gn.gn_type"
                      @click="goToPageByMobileSub('function', gn.gn_type)"
                    >
                      {{ gn.name }}
                    </div>
                  </div>
                </template>
              </Panel>
            </Collapse>
          </div>
        </div>
        <div class="menuItemBox-notPc" v-if="submenuActive == 4">
          <div class="menuTitle">资源/文档</div>
          <div class="menuItem menuItem_list">
            <Collapse simple :accordion="true">
              <Panel
                :name="index1"
                v-for="(art, index1) in articleSubmenuList"
                :key="index1"
              >
                {{ art.title }}
                <template #content>
                  <div class="item-list">
                    <div
                      class="item-list-item"
                      v-for="artItem in art.article"
                      :key="artItem.id"
                      @click="goToPageByMobileSub('Article', artItem.id, artItem)"
                    >
                      {{ artItem.title }}
                    </div>
                  </div>
                </template>
              </Panel>
            </Collapse>
          </div>
        </div>
        <div class="menuItemBox-notPc" v-if="submenuActive == 5">
          <div class="menuTitle">热门工具</div>
          <div class="menuItem menuItem_list">
            <div class="cellgroup">
              <div class="cell" @click="toolsMenuClickByMobile('GenerateCsr')">
                <div class="title">生成CSR</div>
                <Icon type="ios-arrow-forward" />
              </div>
            </div>
            <div class="cellgroup">
              <div class="cell" @click="toolsMenuClickByMobile('CertConvert')">
                <div class="title">SSL证书格式转换工具</div>
                <Icon type="ios-arrow-forward" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer"></div>
    </div>
  </Drawer>
  <login-modal ref="loginModal"></login-modal>
</template>

<script setup>
import { ref, computed, watch, onMounted, reactive } from "vue";
import LoginModal from "@/components/login-modal.vue";
// import LoginModal from './components/LoginModal.vue';
import { storeToRefs } from "pinia";
import { useLoginStore } from "@/store/loginStatus";
import { useMenuStatus } from "@/store/menuStatus";
import { homeBrandSubmenuApi, homeArticleSubmenuApi } from "@/api/home";
import { getCookies } from "@/libs/util";
import { useRouter } from "vue-router";

// -----------pc端-------------
let drawerShow = ref(false);
let homeMenu = reactive({
  product: [],
});
let articleSubmenuList = reactive([]);

const homeSubmenu = () => {
  homeBrandSubmenuApi().then((res) => {
    // console.log(res.data);
    Object.assign(homeMenu.product, res.data);
  });
  homeArticleSubmenuApi().then((res) => {
    articleSubmenuList.length = 0; // 清空数组
    articleSubmenuList.push(...res.data.list); // 添加新数据到数组
  });
};
// 顶部导航栏点击
const menuSelect = (e) => {};
const showSubmenuPc = (e) => {
  meunActive.value = e;
};
const hiddenSubmenuPC = () => {
  meunActive.value = null;
};
// 热门工具点击
const toolsMenuClick = (e) => {
  console.log(e);
  if (e && e == "GenerateCsr") {
    router.push({
      path: "/generate_csr",
    });
  } else {
    router.push({
      path: "/cert_convert",
    });
  }
};
// pc端菜单一级跳转
const goToPage = (name) => {
  // 点击logo返回首页处理 多端共用
  // if (name == "Home") {
    meunActive.value = null;
    drawerShow.value = false;
    twoLevelDrawerShow.value = false;
  // }
  router.replace({ name: name });
};
// 二级菜单跳转
const goToPageBySub = (type, id, item) => {
  if (type == "brand") {
    console.log(item);
    router.push({
      name: "Product",
      params: {
        brand_id: id,
        slug: item.slug,
      },
    });
  }
  if (type == "examine") {
    router.push({
      name: "Product",
      params: {
        sh_type: id,
        slug: item.slug,
      },
    });
  }
  if (type == "function") {
    router.push({
      name: "Product",
      params: {
        gn_type: id,
        slug: item.slug,
      },
    });
  }
  if (type == "Article") {
    router.push({
      name: "Article",
      params: {
        id: item.id,
        slug: item.slug,
      },
    });
  }
  meunActive.value = null;
};

// --------公共-----------
// 处理登录弹窗切换
const loginStore = useLoginStore();
const menuStatus = useMenuStatus();
const { isModalVisible, isLoggedIn } = storeToRefs(loginStore);
const { meunActive } = storeToRefs(menuStatus);
const isShowLoginModal = () => {
  isModalVisible.value = 1;
};

const router = useRouter();

const checkUserStatus = () => {
  const token = getCookies("token");
  if (token) {
    isLoggedIn.value = true;
  } else {
    isLoggedIn.value = false;
  }
};
watch(() => {});
// --------移动端-------
let twoLevelDrawerShow = ref(false);
let submenuActive = ref(0);
// 一级弹窗显示
let openDrawer = () => {
  drawerShow.value = true;
};
// 一级、二级弹窗关闭
let closeDrawer = () => {
  drawerShow.value = false;
  twoLevelDrawerShow.value = false;
};
// 手机端调起登录弹窗
const isShowLoginModalByMobile = () => {
  isModalVisible.value = 1;
  drawerShow.value = false;
};
// 移动端菜单跳转
const goToPageByMobile = (name) => {
  router.replace({ name: name });
  drawerShow.value = false;
  twoLevelDrawerShow.value = false;
};
// 二级菜单跳转
const goToPageByMobileSub = (type, id, item) => {
  if (type == "brand") {
    router.push({
      name: "Product",
      params: {
        brand_id: id,
        slug: item.slug,
      },
    });
  }
  if (type == "examine") {
    router.push({
      name: "Product",
      params: {
        sh_type: id,
        slug: item.slug,
      },
    });
  }
  if (type == "function") {
    router.push({
      name: "Product",
      params: {
        gn_type: id,
        slug: item.slug,
      },
    });
  }
  if (type == "Article") {
    router.push({
      name: "Article",
      params: {
        id: item.id,
        slug: item.slug,
      },
    });
  }
  drawerShow.value = false;
  twoLevelDrawerShow.value = false;
};
const toolsMenuClickByMobile = (e) => {
  console.log(e);
  if (e && e == "GenerateCsr") {
    router.push({
      path: "/generate_csr",
    });
  } else {
    router.push({
      path: "/cert_convert",
    });
  }
  drawerShow.value = false;
  twoLevelDrawerShow.value = false;
};
const openSubmenu = (e) => {
  submenuActive.value = e;
  twoLevelDrawerShow.value = true;
};
onMounted(() => {
  checkUserStatus(), homeSubmenu();
});
</script>

<style lang="less" scoped>
.home-menu {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.2);
  // z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  z-index: 1001;
  width: 100% !important;
  background-color: #fff;

  .menu-logo {
    width: 100px;
    min-width: 100px;
    height: 30px;
    min-height: 30px;
    display: flex;
    justify-items: center;
    align-items: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .menu-nav-pc {
    min-width: 550px;
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: flex-end;
    // pointer-events:none;

    .menuNavItem {
      .title {
        width: 100px;
        height: 66px;
        line-height: 66px;
        text-align: center;
        cursor: pointer;
      }

      .menuBox {
        position: fixed;
        top: 66px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1001;
        background-color: rgba(0, 0, 0, 0.5);
        // margin-top: 1px;
        border-top: 1px solid #ababab;

        .menuItemBox {
          z-index: 1001;
          position: relative;
          background-color: #fff;
          padding: 40px 5% 20px;
          display: flex;
          flex-direction: row;
          min-height: 375px;

          .menuItem {
            flex: 1;

            .item-title {
              font-weight: 600;
              color: #000;
              margin-bottom: 15px;
            }

            .item-content {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              gap: 10px;
              width: auto;

              .imgItem {
                // width: calc(100% / 4);
                min-width: 140px;
                margin-bottom: 5px;
                padding: 5px 10px;
                box-shadow: 4px 2px 5px 4px rgba(133, 133, 133, 0.1);
                border-radius: 8px;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 140px;
                  height: 28px;
                }
              }

              .imgItem:hover {
                box-shadow: 4px 2px 5px 4px rgba(70, 69, 69, 0.1);
              }

              .item-list {
                display: flex;
                flex-direction: column;
              }
            }
          }

          .brand_List {
            min-width: 680px;
            justify-content: center;

            .brandCate {
              display: flex;
              flex-direction: row;
              justify-content: space-between;

              .allBrand {
                cursor: pointer;
                color: rgb(151, 151, 151);
              }
            }
          }

          .menuItem_list {
            display: flex;
            flex-direction: column;
            .item-title {
              text-align: center;
              padding-right: 45px;
            }

            .item-content {
              width: 100%;
              .item-list {
                // width: 100%;
                display: flex;
                margin: 0 auto;
                .item-list-item {
                  margin-bottom: 25px;
                  cursor: pointer;
                  color: #000;
                }
                .item-list-item:hover {
                  color: #0c4684;
                }
              }
            }
          }
        }
      }
    }
  }

  .menu-nav-mobile {
    display: flex;
    align-items: center;
    justify-items: center;
  }
}

.scope-padding {
  padding: 0 15px;
}

// ---------移动菜单适配-------
.cellgroup {
  padding-top: 15px;

  .cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0px;
    margin: 0 15px;
    border-bottom: 1px solid #d4d4d4;
  }
}

.container {
  padding: 30px 15px;
  color: #000;

  .back {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;

    .backMenu {
      margin-left: 8px;
    }
  }

  .menuItemBox-notPc {
    z-index: 1001;
    position: relative;
    background-color: #fff;
    // padding: 40px 5% 20px;
    display: flex;
    flex-direction: column;
    min-height: 375px;

    .menuTitle {
      font-size: 20px;
      padding: 10px 0;
      margin: 20px 0 0;
      font-weight: 600;
      color: #000;
      border-bottom: 1px solid #d4d4d4;
    }

    .menuItem {
      flex: 1;

      .item-title {
        font-weight: 600;
        color: #000;

        .title {
          font-size: 14px;
          padding: 10px;
        }
      }

      .item-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        // gap: 10px;
        .imgItem {
          width: calc(100% / 2);
          min-width: 140px;
          margin-bottom: 5px;
          padding: 5px 10px;
          box-shadow: 4px 2px 5px 4px rgba(133, 133, 133, 0.1);
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 140px;
            height: 28px;
          }
        }

        .imgItem:hover {
          box-shadow: 4px 2px 5px 4px rgba(70, 69, 69, 0.1);
        }

        .item-list {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .brand_List {
      // min-width: 680px;
      justify-content: center;

      .brandCate {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .allBrand {
          cursor: pointer;
          color: rgb(151, 151, 151);
        }
      }

      .item-content {
        padding-bottom: 30px;
      }
    }

    .menuItem_list {
      .item-list {
        .item-list-item {
          padding: 10px 0;
          border-bottom: 1px solid #d4d4d4;
        }

        .item-list-item:last-of-type {
          border: none;
        }
      }

      :deep(.ivu-collapse) {
        border: none;
        border-bottom: 1px solid #d4d4d4;
      }

      :deep(.ivu-collapse-header) {
        padding-left: 0;
        margin: 10px 0;
      }

      :deep(.ivu-icon) {
        margin-right: 0;
      }
    }
  }
}

// ---------移动菜单适配-------
.ivu-menu-item:last-of-type {
  padding: 0 10px;
}

:deep(.ivu-menu-submenu-title-icon) {
  display: none;
}

::deep(.ivu-layout-header) {
  height: atuo !important;
  line-height: 1.5 !important;
}
</style>
