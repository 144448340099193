import { defineStore } from "pinia";
export const useMenuStatus = defineStore("menuStatus", {
  state: () => ({
    meunActive: null,
  }),
  actions: {
    setSelectedMenu(menuId) {
      this.meunActive = menuId;
    },
  },
});
