<template>
  <div class="notFound">
    <div class="imgBox">
      <img src="@/assets/images/404.jpg" alt="" />
    </div>
    <div class="tipBox">
      <div class="tip">未找到指定页面!</div>
      <!-- <div class="tip"><span>404!</span>找不到指定页面!</div> -->
      <Button type="primary" to="/">返回首页</Button>
    </div>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.notFound {
  background-color: #fff;
  display: flex;
  justify-content: center;
  height: 700px;
  align-items: center;
  flex-direction: column;

  .imgBox {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 350px;
      height: 350px;
    }
  }

  .tipBox {
    text-align: center;
    .tip {
      font-size: 18px;
      margin-bottom: 10px;
      span {
        color: red;
        margin-right: 15px;
      }
    }
  }
}
</style>
