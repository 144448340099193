<template>
  <div
    :class="
      $store.state.media.isDesktop == true
        ? 'personal_center-Pc'
        : 'personal_center-notPc'
    "
  >
    <div class="personal_left" v-if="userMenu != []">
      <div
        class="left-item"
        v-for="(menu, index) in userMenu"
        :key="index"
        :class="{ active: menuCur == menu.key }"
        @click="goPage(menu, index)"
      >
        {{ menu.title }}
      </div>
    </div>
    <div class="personal_right">
      <Breadcrumb separator=">" class="bread">
        <BreadcrumbItem
          v-for="(item, idx) in breadcrumbItems"
          :key="idx"
          :to="item.path"
          class="breadItem"
        >
          {{ item.meta.title }}
        </BreadcrumbItem>
      </Breadcrumb>
      <Divider />
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watchEffect, onMounted } from "vue";
import {  useRouter } from "vue-router";

let router = useRouter();
// --------左侧菜单处理----------
const userMenu = reactive([
  {
    key: "/user",
    path: "/user",
    title: "首页",
  },
  {
    key: "/info",
    path: "/info",
    title: "账户管理",
  },
  {
    key: "/balance",
    path: "/balance",
    title: "我的余额",
  },
  {
    key: "/cert",
    path: "/cert",
    title: "我的证书",
  },
  {
    key: "/order",
    path: "/order",
    title: "我的订单",
  },
  {
    key: "/company",
    path: "/company",
    title: "公司管理",
  },
  {
    key: "/accessKey",
    path: "/accessKey",
    title: "API密钥",
  },
]);
const menuCur = ref("/user");

function goPage(menu, index) {
  menuCur.value = menu.key;
  router.push(menu.path);
}
//----------- 面包屑处理--------
let breadcrumbItems = reactive([]);
// 初始化面包屑
function initializeBreadcrumb() {
  const route = router.currentRoute.value;
  breadcrumbItems.length = 0; // 清空数组
  breadcrumbItems.push(...route.matched); // 添加新数据到数组
}
// 监听路由变化并更新面包屑
watchEffect(() => {
  initializeBreadcrumb();
  menuCur.value = breadcrumbItems[breadcrumbItems.length - 1].path;

  router.push(breadcrumbItems[breadcrumbItems.length - 1].path);
});
// 在组件挂载完成后初始化面包屑
onMounted(() => {
  initializeBreadcrumb();
});
</script>

<style lang="less" scoped>
.personal_center-Pc {
  padding: 25px 5% 40px;
  display: flex;
  flex-direction: row;
  .personal_left {
    width: 238px;
    height: 100%;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
    padding: 25px 0;
    .left-item {
      display: block;
      padding: 15px;
      min-width: 205px;
      text-align: center;
      color: #666;
      cursor: pointer;
      border-right: 4px solid #fff;
    }
    .active {
      border-right: 4px solid;
      color: #2b85e4;
      background: rgba(0, 120, 249, 0.18);
    }
  }
  .breadItem {
    cursor: pointer;
  }
  .personal_right {
    // margin-top: 8px;
    width: calc(100% - 264px);
    padding: 25px 42px;
    margin-left: 15px;
    background-color: #fff;
    :deep(.ivu-breadcrumb) {
      font-size: 16px;
    }
    :deep(.ivu-divider-horizontal) {
      margin: 12px 0;
    }
  }
}
.personal_center-notPc {
  padding: 10px 5% 40px;
  display: flex;
  flex-direction: column;
  .personal_left {
    // width: 238px;
    height: 100%;
    background-color: #fff;
    font-size: 16px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 0;
    margin-bottom: 24px;
    .left-item {
      display: block;
      padding: 15px;
      flex-basis: 33%;
      // min-width: 95px;
      text-align: center;
      color: #666;
      cursor: pointer;
    }
    .active {
      border-bottom: 4px solid;
      color: #2b85e4;
      background: rgba(0, 120, 249, 0.18);
    }
  }
}
</style>
