<template>
  <div :class="$store.state.media.isDesktop == true ? 'company-Pc' : 'company-notPc'">
    <div class="topTips">
      公司信息可方便您申请OV/EV证书时候快速填充，避免填写大量重复表单。
    </div>
    <div class="companyList">
      <div class="listItem">
        <Card
          class="pf1 cpyItem"
          v-for="(item, index) in companyList"
          :key="item.id"
          @mouseenter="showBtn(index)"
          @mouseleave="hiddenBtn(index)"
          @click="isShowInMedia(index)"
        >
          <div class="organization">
            <div class="name">{{ item.organization }}</div>
            <div class="msg">
              <div class="contact">
                <Icon type="ios-call" size="16" />
                <div class="contactItem">{{ item.contact_name }}</div>
                <div class="contactItem">{{ item.contact_phone }}</div>
              </div>
              <div class="address">
                <Icon type="ios-pin" size="16" />
                <div class="addressItem">{{ item.country }}</div>
                <div class="addressItem">{{ item.state }}</div>
                <div class="addressItem">{{ item.city }}</div>
                <div class="addressItem">{{ item.registered_address_line1 }}</div>
              </div>
            </div>
            <div class="change" v-show="item.showButton">
              <Tag color="success" @click="edit(item.id)">修改</Tag>
              <Tag color="error" @click="del(item.id)">删除</Tag>
            </div>
          </div>
        </Card>
        <Card class="pf16 cpyItem" @click="add">
          <div class="addCompany">
            <Icon type="md-add" size="36" />
            <div class="add">添加公司</div>
          </div>
        </Card>
      </div>
    </div>
    <companyDetailDrawer @refresh="reFreshInfo" ref="companyDetailRef"></companyDetailDrawer>
  </div>
</template>

<script setup>
import {
  companyApi,
  companyDetailApi,
  companySaveApi,
  companyDeleteApi,
} from "@/api/personalCenter";
import { ref, reactive, onMounted, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Message, Modal } from "view-ui-plus"
import CompanyDetailDrawer from '@/components/companyDetailDrawer'
const store = useStore();
const { proxy } = getCurrentInstance(); // 获取组件实例

const postData = reactive({
  page: 1,
  limit: 15,
});

let companyList = reactive([]);
let showButton = ref(false);
let companyDetailRef = ref(null);
function getList(data) {
  companyApi(data).then((res) => {
    if (res.status === 200) {
      //   console.log(res.data.list);
      companyList.length = 0; // 清空数组
      companyList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
const reFreshInfo = ()=>{
  getList(postData)
}
// -------处理操作-----------
const showBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    companyList[index].showButton = true;
  }
};
const hiddenBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    companyList[index].showButton = false;
  }
};
const isShowInMedia = (index) => {
  if (store.state.media.isDesktop != true) {
    companyList[index].showButton = !companyList[index].showButton;
    // console.log(this.storeList[index].showButton);
  }
};
const add = ()=>{
  companyDetailRef.value.addCompany()
}
const edit = (id)=>{
  companyDetailRef.value.editCompany(id)
}
// 删除公司按钮
const del = (id) => {
  Modal.confirm({
    title: "删除确定",
    content: "确定删除该家公司吗？",
    loading: true,
    onOk() {
      companyDeleteApi({ id: id })
        .then((res) => {
          Message.success("删除成功");
          setTimeout(() => {
            Modal.remove();
            getList(postData);
          }, 200);
        })
        .catch((res) => {
          Modal.remove();
          Message.error(res.msg);
        });
    },
  });
};

onMounted(() => {
  getList(postData);
});
defineExpose({
  companyForm: proxy.$refs.companyForm,
});
</script>

<style lang="less" scoped>
.company-Pc {
  min-height: 700px;
  .topTips {
    padding: 20px 10px;
    background-color: #dff0d8;
    width: 100%;
    min-width: 336px;
    color: #3c763d;
    margin-bottom: 35px;
  }
  .companyList {
    // height: 700px;
    .listItem {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 15px;

      .cpyItem,
      .addCompany {
        width: calc(100% / 3);
        min-width: 300px;
        max-width: 360px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 10px;
      }
      .cpyItem {
        position: relative;
        .name {
          color: #000;
          font-family: SourceHanSansSC-regular;
          font-size: 18px;
          margin-bottom: 25px;
          //   font-weight: 600;
        }
        .msg {
          .contact {
            display: flex;
            flex-direction: row;
            margin-bottom: 5px;
            .contactItem {
              margin-left: 10px;
            }
          }
          .address {
            display: flex;
            flex-direction: row;
            .addressItem {
              margin-left: 8px;
            }
          }
        }
        .change {
          display: flex;
          flex-direction: row;
          position: absolute;
          right: 15px;
          bottom: 10px;
          cursor: pointer;
        }
      }
      .addCompany {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .add {
          margin-top: 5px;
        }
      }
    }
  }
  .drawerBox {
    .drawer-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
  }
}
.company-notPc {
  min-height: 700px;
  background-color: #fff;
  .topTips {
    padding: 20px 10px;
    background-color: #dff0d8;
    width: 100%;
    min-width: 336px;
    color: #3c763d;
    margin-bottom: 35px;
  }
  .companyList {
    // height: 700px;
    .listItem {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;

      .cpyItem,
      .addCompany {
        width: calc(100% / 3);
        min-width: 330px;
        max-width: 360px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 10px;
      }
      .cpyItem {
        position: relative;
        .name {
          color: #000;
          font-family: SourceHanSansSC-regular;
          font-size: 18px;
          margin-bottom: 25px;
          //   font-weight: 600;
        }
        .msg {
          .contact {
            display: flex;
            flex-direction: row;
            margin-bottom: 5px;
            .contactItem {
              margin-left: 10px;
            }
          }
          .address {
            display: flex;
            flex-direction: row;
            .addressItem {
              margin-left: 8px;
            }
          }
        }
        .change {
          display: flex;
          flex-direction: row;
          position: absolute;
          right: 15px;
          bottom: 10px;
          cursor: pointer;
        }
      }
      .addCompany {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .add {
          margin-top: 5px;
        }
      }
    }
  }
  .drawerBox {
    .drawer-footer {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      border-top: 1px solid #e8e8e8;
      padding: 10px 16px;
      text-align: right;
      background: #fff;
    }
  }
}
</style>
