import request from "@/libs/request";

// 文章列表
export function articleListApi() {
  return request({
    url: `/article/list`,
    method: "get",
  });
}

// 文章详情
export function articleDetailApi(data) {
  return request({
    url: `/article/info`,
    method: "get",
    params: data,
  });
}
