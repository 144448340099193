<template>
  <div :class="$store.state.media.isDesktop == true ? 'accessKey-Pc' : 'accessKey-notPc'">
    <div class="accessKeyList">
      <div class="listItem">
        <Card
          class="pf1 cpyItem"
          v-for="(item, index) in accessKeyList"
          :key="item.id"
          @mouseenter="showBtn(index)"
          @mouseleave="hiddenBtn(index)"
          @click="isShowInMedia(index)"
        >
          <div class="organization">
            <div class="name">{{ item.label }}</div>
            <div class="msg">
              <div class="msgItem">
                <div class="label">Key ID</div>
                <Tooltip content="点击复制" placement="right-start">
                  <div class="text" @click="copyAccess(item.access_key_id)">
                    {{ item.access_key_id }}
                  </div>
                </Tooltip>
              </div>
              <div class="msgItem">
                <div class="label">Key Secret</div>
                <Tooltip content="点击复制" placement="right-start">
                  <div class="text" @click="copyAccess(item.access_key_secret)">
                    {{ item.access_key_secret }}
                  </div>
                </Tooltip>
              </div>
            </div>
            <div class="change" v-show="item.showButton">
              <Tag color="primary" @click="setIpWhite(item.id)">设置白名单</Tag>
              <Tag color="success" @click="setLabel(item.id)">备注</Tag>
              <Tag color="error" @click="del(item.id)">删除</Tag>
            </div>
          </div>
        </Card>
        <Card class="pf16 cpyItem createAccessKey" @click="createAccessKey">
          <div class="addAccessKey">
            <Icon type="md-add" size="36" />
            <div class="add">添加密钥</div>
          </div>
        </Card>
      </div>
    </div>
    <Modal
      v-model="showModal"
      :title="modalTitle"
      :closable="true"
      :mask-closable="false"
      :loading="true"
    >
      <Form
        :model="changeData"
        ref="accessKeyForm"
        :rules="ruleInline"
        label-position="right"
        :label-width="100"
      >
        <FormItem label="备注" prop="label" v-if="typeNumber == 1">
          <Input v-model="changeData.label" placeholder="请输入备注"></Input>
        </FormItem>
        <FormItem label="白名单" prop="ip_whitelist" v-if="typeNumber == 2">
          <Input
            v-model="changeData.ip_whitelist"
            type="textarea"
            :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请输入白名单"
          ></Input>
        </FormItem>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="text" size="large" @click="cancel">取消</Button>
          <Button type="primary" size="large" @click="modalCheckBtn">确定</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script setup>
import {
  accessKeyApi,
  accessKeyLabelApi,
  accessKeyIpWhiteApi,
  accessKeyCreatApi,
  accessKeyDeleteApi,
} from "@/api/personalCenter";
import { ref, reactive, onMounted, defineExpose, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { Copy, Message, Modal } from "view-ui-plus";

const store = useStore();
const { proxy } = getCurrentInstance(); // 获取组件实例

const postData = reactive({
  page: 1,
  limit: 15,
});

let accessKeyList = reactive([]);
let showButton = ref(false);
let showModal = ref(false); //弹窗开关状态
let modalTitle = ref(""); // 弹窗标题
let typeNumber = ref(0); // 弹窗表单显示内容区分
let changeData = reactive({
  ip_whitelist: "",
  label: "",
});
const ruleInline = {
  label: [{ required: true, message: "请输入昵称", trigger: "blur" }],
  ip_whitelist: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
  // code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
};
// ---------请求-----------
function getList(data) {
  accessKeyApi(data).then((res) => {
    if (res.status === 200) {
      //   console.log(res.data.list);
      accessKeyList.length = 0; // 清空数组
      accessKeyList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
// 设置备注请求
const accessKeyLabel = (data) => {
  accessKeyLabelApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "设置成功");
        getList(postData);
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 设置白名单请求
const accessKeyIpWhite = (data) => {
  accessKeyIpWhiteApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "设置成功");
        getList(postData);
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 生成密匙请求
const accessKeyCreat = (data) => {
  accessKeyCreatApi(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "生成成功");
        getList(postData);
        showModal.value = false;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 操作按钮现隐设置
const showBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    accessKeyList[index].showButton = true;
  }
};
// 操作按钮现隐设置
const hiddenBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    accessKeyList[index].showButton = false;
  }
};
const isShowInMedia = (index) => {
  if (this.$store.state.media.isDesktop != true) {
    this.storeList[index].showButton = !this.storeList[index].showButton;
    // console.log(this.storeList[index].showButton);
  }
};
// 点击复制文本信息
const copyAccess = (text) => {
  Copy({
    text: text,
  });
};
// 操作按钮-设置备注
const setLabel = (id) => {
  changeData.id = id;
  showModal.value = true;
  typeNumber.value = 1;
  modalTitle.value = "设置备注";
};
// 操作按钮-设置白名单
const setIpWhite = (id) => {
  changeData.id = id;
  showModal.value = true;
  typeNumber.value = 2;
  modalTitle.value = "设置白名单";
};
// 添加密匙按钮
const createAccessKey = () => {
  accessKeyCreatApi()
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg || "生成成功");
        getList(postData);
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 操作按钮-删除
const del = (id) => {
  Modal.confirm({
    title: "删除确定",
    content: "确定删除该密匙吗？",
    loading: true,
    onOk() {
      accessKeyDeleteApi({ id: id })
        .then((res) => {
          Message.success("删除成功");
          setTimeout(() => {
            Modal.remove();
            getList(postData);
          }, 200);
        })
        .catch((res) => {
          Modal.remove();
          Message.error(res.msg);
        });
    },
  });
};
// 编辑弹窗确定按钮
const modalCheckBtn = () => {
  proxy.$refs.accessKeyForm.validate((valid) => {
    if (valid) {
      if (typeNumber.value == 1) {
        let data = {
          id: changeData.id,
          label: changeData.label,
        };
        accessKeyLabel(data);
      } else if (typeNumber.value == 2) {
        let data = {
          id: changeData.id,
          ip_whitelist: changeData.ip_whitelist,
        };
        accessKeyIpWhite(data);
      }
    } else {
      return false;
    }
  });
};
// 编辑弹窗取消按钮
const cancel = () => {
  showModal.value = false;
};
onMounted(() => {
  getList(postData);
});
defineExpose({
  accessKeyForm: proxy.$refs.accessKeyForm,
});
</script>

<style lang="less" scoped>
.accessKey-Pc {
  min-height: 700px;
  .accessKeyList {
    // height: 700px;
    .listItem {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 15px;

      .cpyItem,
      .addAccessKey {
        width: calc(100% / 3);
        min-width: 330px;
        max-width: 360px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 10px;
      }
      .createAccessKey {
        cursor: pointer;
      }
      .cpyItem {
        position: relative;
        min-width: 330px;
        .name {
          color: #000;
          font-family: SourceHanSansSC-regular;
          font-size: 18px;
          margin-bottom: 25px;
          //   font-weight: 600;
        }
        .msg {
          display: flex;
          flex-direction: column;
          width: 100%;
          .msgItem {
            color: #000;
            display: flex;
            flex-direction: row;
            font-family: SourceHanSansSC-regular;
            margin-bottom: 15px;
            .label {
              width: 75px;
              font-weight: 600;
              margin-right: 10px;
            }
            .text {
              cursor: pointer;
            }
          }
        }
        .change {
          display: flex;
          flex-direction: row;
          position: absolute;
          right: 15px;
          bottom: 10px;
          cursor: pointer;
          .edit {
            color: green;
          }
          .delete {
            color: red;
            margin-left: 15px;
          }
        }
      }
      .addAccessKey {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .add {
          margin-top: 5px;
        }
      }
    }
  }
}
.accessKey-notPc {
  min-height: 700px;
  background-color: #fff;
  .accessKeyList {
    // height: 700px;
    .listItem {
      height: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;

      .cpyItem,
      .addAccessKey {
        width: calc(100% / 3);
        min-width: 330px;
        max-width: 360px;
        display: flex;
        justify-content: center;
        align-items: center;

        padding: 30px 10px;
      }
      .createAccessKey {
        cursor: pointer;
      }
      .cpyItem {
        position: relative;
        min-width: 330px;
        .name {
          color: #000;
          font-family: SourceHanSansSC-regular;
          font-size: 18px;
          margin-bottom: 25px;
          //   font-weight: 600;
        }
        .msg {
          display: flex;
          flex-direction: column;
          width: 100%;
          .msgItem {
            color: #000;
            display: flex;
            flex-direction: row;
            font-family: SourceHanSansSC-regular;
            margin-bottom: 15px;
            .label {
              width: 75px;
              font-weight: 600;
              margin-right: 10px;
            }
            .text {
              cursor: pointer;
            }
          }
        }
        .change {
          display: flex;
          flex-direction: row;
          position: absolute;
          right: 15px;
          bottom: 10px;
          cursor: pointer;
          .edit {
            color: green;
          }
          .delete {
            color: red;
            margin-left: 15px;
          }
        }
      }
      .addAccessKey {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .add {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
