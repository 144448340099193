import request from "@/libs/request";

// 立即购买产品获取价格详情
export function getProductDetailsApi(data) {
  return request({
    url: `/get_product_details/${data.store_slug}`,
    method: "get",
  });
}

// 订单详情
export function orderDetailApi(data) {
  return request({
    url: `/order/order_detail/${data.id}`,
    method: "get",
  });
}
// 立刻下单
export function orderPlaceApi(data) {
  return request({
    url: `/order/place_order`,
    method: "post",
    data,
  });
}
// 立刻下单
export function orderPayApi(data) {
  return request({
    url: `/order/pay`,
    method: "post",
    data,
  });
}

// 获取支付二维码
export function payCodeApi(data) {
  return request({
    url: `/order/pay_qrcode`,
    method: "get",
    params:data,
  });
}

// 查询支付结果
export function checkOrderStatusApi(data) {
  return request({
    url: `/order/check_order_status`,
    method: "get",
    params:data,
  });
}
// 申请退款
export function applyRefundApi(data) {
  return request({
    url: `/order/money_return/${data.order_id}`,
    method: "get"
  });
}
 

