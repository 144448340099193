import { createI18n } from 'vue-i18n';

// import zh from 'view-ui-plus/dist/locale/zh-CN';
// import en from 'view-ui-plus/dist/locale/en-US';
import zh from '@/libs/i18n/zh';
import en from '@/libs/i18n//en';


// 自动根据浏览器系统语言设置语言

const language = (navigator.language || 'en').toLocaleLowerCase(); // 这是获取浏览器的语言
let lang = localStorage.getItem('lang') || language.split('-')[0] || 'zh' // 首先从缓存里拿，没有的话就用浏览器语言，

const i18n = createI18n({
  allowComposition: true,
  globalInjection: true,
  legacy: false,
  locale: lang,
  silentFallbackWarn:true,
  missingWarn:false,
  silentFallbackWarn:true,
  fallbackWarn:false,
  messages: {
      'zh': zh,
      'en': en
  }
});


// export const locale = eleLang[lang]
export default i18n;