<template>
  <transition name="ease">
    <div v-if="isModalVisible != 0" class="page-account">
      <div class="container">
        <div class="swiperPross" v-if="$store.state.media.isDesktop == true">
          <div class="swiperPic">
            <img src="@/assets/images/login_1.png" />
          </div>
        </div>
        <div
          v-show="isModalVisible == 1"
          class="index_from page-account-container loginBox"
        >
          <div class="page-account-top">
            <div class="page-account-top-title">账号密码登录</div>
          </div>
          <Form
            ref="loginForm"
            :model="loginData"
            :rules="ruleInline"
            @keyup.enter="handleSubmit('loginData')"
          >
            <FormItem prop="account">
              <Input
                type="text"
                v-model="loginData.account"
                prefix="ios-contact-outline"
                placeholder="请输入用户名"
                size="large"
              />
            </FormItem>
            <FormItem prop="password">
              <Input
                type="password"
                v-model="loginData.password"
                password
                prefix="ios-lock-outline"
                placeholder="请输入密码"
                size="large"
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                long
                size="large"
                @click="handleSubmit('loginData')"
                class="btn"
                >登录</Button
              >
            </FormItem>
            <div class="page-account-center">
              <div class="forgot" @click="forgotpwd">忘记密码?</div>
              <div class="register" @click="triggerBox('register')">注册</div>
            </div>
            <Divider :plain="true" class="another">其他登录方式</Divider>
            <div class="loginSelect">
              <div class="select-item" @click="weChatLoginBtn">
                <div class="item-icon">
                  <img src="@/assets/images/wechatLogin.png" mode="" />
                </div>
                <div class="item-text">微信登录</div>
              </div>
              <div class="select-item" @click="QQLoginBtn">
                <div class="item-icon">
                  <img src="@/assets/images/qq.png" mode="" />
                </div>
                <div class="item-text">QQ登录</div>
              </div>
              <!-- <div class="select-item">
                <div class="item-icon">
                  <img src="@/assets/images/ipay.png" mode="" />
                </div>
                <div class="item-text">支付宝登录</div>
              </div>
              <div class="select-item">
                <div class="item-icon">
                  <img src="@/assets/images/mail.png" mode="" />
                </div>
                <div class="item-text">邮箱登录</div>
              </div> -->
            </div>
            <div class="loginNotice">登陆注册即同意《隐私保护协议》</div>
          </Form>
        </div>
        <div
          v-show="isModalVisible == 2"
          class="index_from page-account-container registerBox"
        >
          <div class="page-account-top">
            <div class="page-account-top-title">账号注册</div>
          </div>
          <Form
            ref="registerForm"
            v-if="registerData"
            :model="registerData"
            @keyup.enter="registerSubmit('registerData')"
          >
            <FormItem prop="account">
              <Input
                type="text"
                v-model="registerData.account"
                prefix="ios-contact-outline"
                placeholder="请输入用户名"
                size="large"
              />
            </FormItem>
            <FormItem prop="password">
              <Input
                type="password"
                v-model="registerData.password"
                password
                prefix="ios-lock-outline"
                placeholder="请输入密码"
                size="large"
              />
            </FormItem>

            <FormItem prop="mobile">
              <Input
                type="tel"
                v-model="registerData.mobile"
                prefix="ios-phone-portrait"
                placeholder="请输入手机号码"
                size="large"
              />
            </FormItem>
            <FormItem prop="email">
              <div class="code">
                <Input
                  type="email"
                  v-model="registerData.email"
                  prefix="ios-mail-outline"
                  placeholder="请输入邮箱"
                  size="large"
                />
                <Button
                  class="getCode"
                  type="primary"
                  :disabled="isSending"
                  @click="mailCodeSubmit('register')"
                  >{{ sendText }}</Button
                >
              </div>
            </FormItem>
            <FormItem prop="captcha">
              <div class="code">
                <Input
                  type="text"
                  v-model="registerData.captcha"
                  prefix="ios-keypad-outline"
                  placeholder="请输入邮箱验证码"
                  size="large"
                />
                <img :src="imgcode" class="pictrue" @click="captchas" />
              </div>
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                long
                size="large"
                @click="registerSubmit('loginData')"
                class="btn"
                >注册并登录</Button
              >
            </FormItem>
            <div class="page-account-center isCheckBox">
              <!-- <Checkbox v-model="single" @on-change="isChange">《隐私保护协议》</Checkbox> -->
              <div class="register" @click="triggerBox('login')">去登录</div>
            </div>
          </Form>
        </div>
        <div class="weChatLogin" v-show="isModalVisible == 3">
          <div class="QrCode">
            <div class="codeBox">
              <div class="page-account-top">
                <div class="page-account-top-title">微信扫码登录</div>
              </div>
              <div class="greenBox">
                <div class="code">
                  <img
                    id="qr-code"
                    v-if="codeLinks != ''"
                    :src="codeLinks"
                    alt="微信登录二维码"
                  />
                  <Spin fix v-else>
                    <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                    <div>Loading</div>
                  </Spin>
                </div>
              </div>
              <div class="refreash">刷新二维码<Icon type="md-refresh" /></div>
              <div class="page-account-center isCheckBox">
                <div
                  class="register"
                  style="margin-top: 50px"
                  @click="triggerBox('login')"
                >
                  其他登录方式
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-show="isModalVisible == 4"
          class="index_from page-account-container forgotPwd"
        >
          <div class="page-account-top">
            <div class="page-account-top-title">重置账号密码</div>
          </div>
          <Form
            ref="resetPwdForm"
            v-if="resetPwdData"
            :model="resetPwdData"
            @keyup.enter="resetPWdSubmit('resetPwdData')"
          >
            <FormItem prop="new_password">
              <Input
                type="password"
                v-model="resetPwdData.new_password"
                password
                prefix="ios-lock-outline"
                placeholder="请输入新密码"
                size="large"
              />
            </FormItem>
            <FormItem prop="email">
              <div class="code">
                <Input
                  type="email"
                  v-model="resetPwdData.email"
                  prefix="ios-mail-outline"
                  placeholder="请输入邮箱"
                  size="large"
                />
                <Button
                  class="getCode"
                  type="primary"
                  :disabled="isRestetSending"
                  @click="mailCodeSubmit('resetPwd')"
                  >{{ sendResetPwdText }}</Button
                >
              </div>
            </FormItem>
            <FormItem prop="captcha">
              <div class="code">
                <Input
                  type="text"
                  v-model="resetPwdData.captcha"
                  prefix="ios-keypad-outline"
                  placeholder="请输入邮箱验证码"
                  size="large"
                />
                <img :src="imgcode" class="pictrue" @click="captchas" />
              </div>
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                long
                size="large"
                @click="resetPWdSubmit('resetPwdData')"
                class="btn"
                >重置密码</Button
              >
            </FormItem>
            <div class="page-account-center isCheckBox">
              <!-- <Checkbox v-model="single" @on-change="isChange">《隐私保护协议》</Checkbox> -->
              <div class="register" @click="triggerBox('login')">去登录</div>
            </div>
          </Form>
        </div>
        <div v-show="isModalVisible == 5" class="index_from page-account-container">
          <div class="page-account-top">
            <div class="page-account-top-title">继续以完成第三方帐号绑定</div>
            <div class="page-account-top-title-tip">
              你已通过 授权，完善以下登录信息即可完成绑定
            </div>
          </div>
          <Form
            ref="bindAccountForm"
            v-if="bindAccountData"
            :model="bindAccountData"
            @keyup.enter="bindAccountSubmit('bindAccountData')"
          >
            <FormItem prop="account">
              <Input
                type="text"
                v-model="bindAccountData.account"
                prefix="ios-contact-outline"
                placeholder="请输入用户名"
                size="large"
              />
            </FormItem>
            <FormItem prop="password">
              <Input
                type="password"
                v-model="bindAccountData.password"
                password
                prefix="ios-lock-outline"
                placeholder="请输入密码"
                size="large"
              />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                long
                size="large"
                @click="bindAccoutSubmit('loginData')"
                class="btn"
                >登录并绑定已有账号</Button
              >
            </FormItem>
            <div class="page-account-center bindAccountBox">
              <div class="register">
                没有账号？
                <span style="color: orange" @click="thirdLogin"> 快速注册</span>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div class="loginClose">
        <div class="closeIcon">
          <img class="closeIconLine" src="@/assets/images/close_1.png" mode="" />
          <img @click="hideLoginModal" src="@/assets/images/close_2.png" mode="" />
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup>
import {
  ref,
  computed,
  watch,
  reactive,
  getCurrentInstance,
  onUnmounted,
  onMounted,
  defineExpose,
  watchEffect,
} from "vue";
import { storeToRefs } from "pinia";
import { useLoginStore } from "../store/loginStatus";
import {
  AccountLogin,
  MsgCodeKey,
  MailCode,
  AccountRegister,
  weChatLoginApi,
  checkWeChatScanLoginApi,
  resetpwdApi,
  resetPwdMailCode,
  thirdLoginApi,
} from "@/api/account";
import { useRouter } from "vue-router";
import { Message } from "view-ui-plus";
import { setCookies } from "@/libs/util";
import QC from "@/libs/qc_jssdk";

// ----------登录部分-----------
const router = useRouter();
const { proxy } = getCurrentInstance(); // 获取组件实例
const loginStore = useLoginStore();

const loginData = computed(() => loginStore.loginData);

const { isModalVisible, isLoggedIn } = storeToRefs(loginStore);

// 登录表单校验
const ruleInline = {
  account: [{ required: true, message: "请输入用户名", trigger: "blur" }],
  password: [{ required: true, message: "请输入密码", trigger: "blur" }],
  // code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
};
// 隐藏登录弹窗
const hideLoginModal = () => {
  isModalVisible.value = 0;
};
// 登录表单提交
const handleSubmit = () => {
  proxy.$refs.loginForm.validate((valid) => {
    if (valid) {
      loginStore.login(loginData.value);
    }
  });
};
// 跳转忘记密码
const forgotpwd = () => {
  isModalVisible.value = 4;
};
// 切换注册
const register = () => {};

// --------------注册部分--------------
const imgcode = ref("");
const registerData = reactive({
  account: "",
  password: "",
  email: "",
  mobile: "",
  captcha: "",
  invite: "",
});
let sendText = ref("获取验证码");
let sendResetPwdText = ref("获取验证码");
let isSending = ref(false);
let isRestetSending = ref(false);
const counter = ref(0);
let timer = null;
let formType = ref("");
// 是否勾选用户协议状态
const single = ref(false);
// 注册用户协议勾选切换
const isChange = () => {};

const startCountdown = () => {
  if (counter.value > 0) {
    counter.value--;
    if (formType.value == "register") {
      sendText.value = `${counter.value} 秒后重新发送`;
    } else {
      sendResetPwdText.value = `${counter.value} 秒后重新发送`;
    }
  } else {
    stopCountdown();
  }
};
const stopCountdown = () => {
  if (timer !== null) {
    clearInterval(timer);
    timer = null;
    counter.value = 60;
    if (formType.value == "register") {
      isSending.value = false;
      sendText.value = "重新发送验证码";
    } else {
      isRestetSending.value = false;
      sendResetPwdText.value = "重新发送验证码";
    }
  }
};
// 获取邮箱验证码按钮
const mailCodeSubmit = (type) => {
  if (type == "register") {
    if (isSending.value) return;
  } else {
    if (isRestetSending.value) return;
  }
  // 模拟发送验证码
  formType.value = type;
  if (type == "register") {
    isSending.value = true;
    sendText.value = `${counter.value} 秒后重新发送`;
  } else {
    isRestetSending.value = true;
    sendResetPwdText.value = `${counter.value} 秒后重新发送`;
  }
  getMsgCodeKey(type);
  // 启动倒计时
  counter.value = 60;
  timer = setInterval(startCountdown, 1000);
};

// 获取邮箱key======> 通用
const getMsgCodeKey = (type) => {
  MsgCodeKey().then((res) => {
    if (res.status == 200) {
      let newData = {
        key: res.data.key,
        email: type == "register" ? registerData.email : resetPwdData.email,
        captcha: "",
      };
      if (type == "register") {
        getMailCode(newData);
      } else if (type == "resetPwd") {
        getResetPwdMailCode(newData);
      }
    }
  });
};
// 获取邮箱验证码 注册
const getMailCode = (data) => {
  MailCode(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success("获取成功！请前往邮箱查看");
      } else {
        Message.warning(res.msg);
      }
    })
    .catch((err) => {
      Message.warning(err.msg);
      stopCountdown();
    });
};
// 获取邮箱验证码 重置密码
const getResetPwdMailCode = (data) => {
  resetPwdMailCode(data)
    .then((res) => {
      if (res.status == 200) {
        Message.success("获取成功！请前往邮箱查看");
      } else {
        Message.warning(res.msg);
      }
    })
    .catch((err) => {
      Message.warning(err.msg);
      stopCountdown();
    });
};
const registerAccount = (data) => {
  AccountRegister(data)
    .then((res) => {
      if (res.status == 200) {
        setCookies("token", res.data.token);
        setCookies("expires_time", res.data.expires_time);
        setCookies("is_agent", res.data.is_agent);
        isLoggedIn.value = true;
        isModalVisible.value = 0;
        Message.success("注册成功！");
        router.replace({
          name: "User",
        });
      }
    })
    .catch((err) => {
      // this.isModalVisible = 0;
      Message.error(err.msg);
    });
};

// 注册按钮
const registerSubmit = () => {
  registerAccount(registerData);
};
// ------------微信登录----------
let codeLinks = ref("");
let weChatLoginKey = ref("");
const weChatLogin = (data) => {
  weChatLoginApi(data).then((res) => {
    if (res.status == 200) {
      codeLinks.value = res.data.qrcode;
      weChatLoginKey.value = res.data.key;
      startPolling();
    }
  });
};
const checkWeChatScanLogin = (data) => {};
let pollingInterval;
// 微信扫码支付结果轮询
const startPolling = () => {
  pollingInterval = setInterval(() => {
    checkWeChatScanLoginApi({ key: weChatLoginKey.value }).then((res) => {
      if (res.status == 200) {
        if (res.data.status == 3) {
          Message.success("扫码成功");
          setCookies("token", res.data.token);
          setCookies("expires_time", res.data.expires_time);
          setCookies("is_agent", res.data.is_agent);
          stopPolling();
          isModalVisible.value = 0;
          isLoggedIn.value = true;
          router.replace({
            name: "User",
          });
        } else if (res.data.status == 0) {
          Message.warning("二维码已失效，请点击刷新");
          stopPolling();
        } else if (res.data.status == 4) {
          stopPolling();
          isModalVisible.value = 5;
        }
      }
    });
  }, 3000);
};
const stopPolling = () => {
  clearInterval(pollingInterval);
};
// ---------------微信扫码账号绑定-------------
let bindAccountData = reactive({
  account: "",
  password: "",
  key: "",
});
// 登录并绑定账号
const bindAccoutSubmit = () => {
  bindAccountData.key = weChatLoginKey.value;
  AccountLogin(bindAccountData)
    .then((res) => {
      if (res.status == 200) {
        Message.success(res.msg);
        setCookies("token", res.data.token);
        setCookies("expires_time", res.data.expires_time);
        setCookies("is_agent", res.data.is_agent);
        isLoggedIn.value = true;
        isModalVisible.value = 0;
        router.replace({
          name: "User",
        });
      }
    })
    .catch((err) => {
      // this.isModalVisible = 0;
      Message.error(err.msg);
    });
};
// 已有账号直接登录
const thirdLogin = () => {
  thirdLoginApi({ key: weChatLoginKey.value })
    .then((res) => {
      if (res.status == 200) {
        Message.success("已注册并登录成功");
        setCookies("token", res.data.token);
        setCookies("expires_time", res.data.expires_time);
        setCookies("is_agent", res.data.is_agent);
        isLoggedIn.value = true;
        isModalVisible.value = 0;
        router.replace({
          name: "User",
        });
      }
    })
    .catch((err) => {
      // this.isModalVisible = 0;
      Message.error(err.msg);
    });
};
// ---------------重置密码-------------
const resetPwdData = reactive({
  new_password: "",
  email: "",
  captcha: "",
});
// 重置密码按钮
const resetPwdSubmit = () => {
  getResetpwd(resetPwdData);
};

const getResetpwd = (data) => {
  resetpwdApi(data)
    .then((res) => {
      if (res.status == 200) {
        isModalVisible.value = 1;
        Message.success("重置成功！请前往登录");
      }
    })
    .catch((err) => {
      // this.isModalVisible = 0;
      Message.error(err.msg);
    });
};
// -----------公用---------
// 登录/注册表单展示切换
const triggerBox = (param) => {
  proxy.$refs.registerForm.resetFields();
  if (param == "login") {
    isModalVisible.value = 1;
  } else {
    isModalVisible.value = 2;
  }
  stopPolling();
};
// 微信登录切换
const weChatLoginBtn = () => {
  isModalVisible.value = 3;
  weChatLogin();
};
const QQLoginBtn = () => {
  // isModalVisible.value = 3;
  // weChatLogin();
  QC.Login.showPopup({
    // appId: "102348734", // 填写在QQ互联上申请的AppId
    // redirectURI: "https://h.ssl-zs.com/user", //填写回调地址 登录成功后会自动跳往该地址
  });
};
// 判断是否是邀请
const isRedirected = ref(false);

// 获取URL中的查询参数
function getQueryParams(url) {
  const params = new URLSearchParams(url.search);
  return Object.fromEntries(params.entries());
}

// 检查是否有注册邀请的参数
function isPaymentRedirected() {
  const queryParams = getQueryParams(window.location);
  // 检查是否有支付宝返回的参数，例如`out_trade_no`
  if (queryParams.invite) {
    return queryParams.invite;
  }
  return "";
}
onMounted(() => {});
watchEffect(() => {
  isRedirected.value = isPaymentRedirected();
  if (isRedirected.value) {
    // console.log(isRedirected.value);
   if(registerData){
    registerData.invite = isRedirected.value
    isModalVisible.value=2
   }
  }
});
onUnmounted(() => {
  clearInterval(pollingInterval);
});
defineExpose({
  hideLoginModal,
  handleSubmit,
  triggerBox,
  registerData,
  resetPwdData,
  bindAccountData,
  imgcode,
  loginForm: proxy.$refs.loginForm,
  registerForm: proxy.$refs.registerForm,
  resetPwdForm: proxy.$refs.resetPwdForm,
  bindAccountForm: proxy.$refs.bindAccountForm,
});
</script>
<style scoped lang="less">
.page-account {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 8999;
}
.registerClose {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 8999;
  .closeIcon {
    display: flex;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    .closeIconLine {
      margin-left: 2px;
    }
  }
}
.loginClose {
  cursor: pointer;
  display: flex;

  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 8999;
  .closeIcon {
    display: flex;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    .closeIconLine {
      margin-left: 2px;
    }
  }
}
.page-account {
  .code {
    display: flex;
    align-items: center;
    justify-content: center;
    .pictrue {
      height: 40px;
    }
  }
}
.container {
  //   height: 400px !important;
  padding: 0 !important;
  border-radius: 6px;
  z-index: 1;
  display: flex;
  background-color: #fff;
}
.swiperPross {
  border-radius: 6px 0px 0px 6px;
  overflow: hidden;
  .swiperPic {
    width: 300px;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.index_from {
  padding: 0 30px 15px 30px;
  width: 360px;
  //   height: 400px;
  box-sizing: border-box;
}

.page-account-top {
  padding: 30px 0 15px !important;
  box-sizing: border-box !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &-title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
  }
  &-title-tip {
    font-size: 12px;
    margin-top: 5px;
  }
}
.page-account-center {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  // margin-bottom: 25px;
  .forgot {
    color: #000;
    cursor: pointer;
  }
  .register {
    color: #0178fa;
    cursor: pointer;
    text-align: right;
  }
  .code {
    display: flex;
    align-items: center;
    justify-content: center;
    .pictrue {
      height: 40px;
      width: 80px;
      margin-left: 5px;
    }
  }
}
.page-account-container {
  border-radius: 6px;
  background-color: #fff;
}

.btn {
  background: linear-gradient(
    90deg,
    rgba(25, 180, 241, 1) 0%,
    rgba(14, 115, 232, 1) 100%
  ) !important;
}

.captchaBox {
  width: 310px;
}

input {
  display: block;
  width: 290px;
  line-height: 40px;
  margin: 10px 0;
  padding: 0 10px;
  outline: none;
  border: 1px solid #c8cccf;
  border-radius: 4px;
  color: #6a6f77;
}

#msg {
  width: 100%;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
}

a:link,
a:visited,
a:hover,
a:active {
  margin-left: 100px;
  color: #0366d6;
}

.index_from ::deep(.ivu-input-large) {
  font-size: 14px !important;
}

.isCheckBox {
  justify-content: flex-end;
}
.bindAccountBox {
  justify-content: center;
  margin-top: 100px;
}
.another {
  color: #9fa19f;
}
.loginSelect {
  display: flex;
  .select-item {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    .item-icon {
      width: 40px;
      height: 40px;
      margin-bottom: 5px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .item-text {
      font-size: 12px;
      color: #999;
      // font-weight: 600;
    }
  }
}
.loginNotice {
  font-size: 12px;
  color: #999;
  // font-weight: 600;
  text-align: center;
  margin-top: 15px;
  //   margin: 20px 0 15px;
}
::deep(.ivu-layout-header) {
  height: atuo !important;
  line-height: 1.5 !important;
}
:deep(.ivu-form-item) {
  margin-bottom: 20px !important;
}
.getCode {
  padding: 0 5px;
  height: 40px;
}
.QrCode {
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  .codeBox {
    width: 360px;
    height: 441px;
    background-color: #fff;
    border-radius: 6px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
    .payType {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .typeIcon {
        width: 35px;
        height: 35px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .typeName {
        font-size: 16px;
        color: #000;
        font-weight: 400;
      }
    }
    .greenBox {
      border-radius: 10px;
      width: 200px;
      height: 200px;
      margin: 30px 0 30px;
      .code {
        width: 200px;
        height: 200px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .demo-spin-icon-load {
          animation: ani-demo-spin 1s linear infinite;
        }
      }
    }
    .refreash {
      cursor: pointer;
    }
  }
}
</style>
