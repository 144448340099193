<template>
  <div
    :class="$store.state.media.isDesktop == true ? 'payment-Pc' : 'payment-notPc'"
  >
    <div class="routerBox">
      <Breadcrumb separator=">">
        <BreadcrumbItem key="1" to="/user" class="breadItem"> 个人中心 </BreadcrumbItem>
        <BreadcrumbItem key="2" to="/order" class="breadItem"> 我的订单 </BreadcrumbItem>
        <BreadcrumbItem key="3" to="/payment" class="breadItem">
          确认订单
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <!-- pc -->
    <div class="orderTip" v-if="$store.state.media.isDesktop == true">
      <div class="tip-left">
        <div>订单提交成功！去付款咯~</div>
        <div class="times">
          <div>剩余时间：</div>
          <countDown
            v-if="orderData.cancel_unix"
            :is-day="false"
            :tip-text="' '"
            :day-text="' '"
            :hour-text="' 小时 '"
            :minute-text="' 分钟 '"
            :second-text="'秒 '"
            :datatime="orderData.cancel_unix"
          ></countDown>
        </div>
      </div>
      <div class="tip-right">
        <img src="@/assets/images/payment_bg.png" alt="" />
      </div>
    </div>
    <!-- h5 -->
    <div class="orderTip" v-if="$store.state.media.isDesktop != true">
      <div class="tip-left">
        <div>订单提交成功！去付款咯~</div>
        <div class="times">
          <div>剩余时间：</div>
          <countDown
            v-if="orderData.cancel_unix"
            :is-day="false"
            :tip-text="' '"
            :day-text="' '"
            :hour-text="' 小时 '"
            :minute-text="' 分钟 '"
            :second-text="'秒 '"
            :datatime="orderData.cancel_unix"
          ></countDown>
        </div>
      </div>
    </div>
    <div class="detail">
      <div class="item">订单编号：{{ orderData.order_id }}</div>
      <div class="item line1" v-if="orderData.product">
        产品名称：{{ orderData.product.store_name }}
      </div>
      <div class="item">
        订单价格：<span class="order_price">{{ orderData.pay_price }}元</span>
      </div>
      <div class="item">附加域名：{{ orderData.san }}</div>
      <div class="item">购买时长：{{ orderData.periods_text }}</div>
    </div>
    <div class="payType">
      <div class="title">选择以下支付方式</div>
      <div class="type">
        <div
          v-if="orderData.pay_yue_open == 1"
          class="item acea-row row-center-wrapper"
          :class="orderForm.pay_type === 'yue' ? 'on' : ''"
          @click="currentPay('yue')"
        >
          <div class="iconImage">
            <img src="@/assets/images/yue.png" alt="" />
          </div>
          <div class="item-tip">
            <div class="name">余额支付</div>
            <div class="yue">余额：{{ now_money }}</div>
          </div>
          <div v-if="orderForm.pay_type === 'yue'" class="isChecked">
            <img src="@/assets/images/checked.png" alt="" />
          </div>
        </div>
        <div
          v-if="orderData.pay_weixin_open == 1"
          class="item acea-row row-center-wrapper"
          :class="orderForm.pay_type === 'wx' ? 'on' : ''"
          @click="currentPay('wx')"
        >
          <div class="iconImage">
            <img src="@/assets/images/WeChatPay.png" alt="" />
          </div>
          <div>
            <div class="name">微信支付</div>
          </div>
          <div v-if="orderForm.pay_type === 'wx'" class="isChecked">
            <img src="@/assets/images/checked.png" alt="" />
          </div>
        </div>
        <div
          v-if="orderData.pay_zfb_open == 1"
          class="item acea-row row-center-wrapper"
          :class="orderForm.pay_type === 'zfb' ? 'on' : ''"
          @click="currentPay('zfb')"
        >
          <div class="iconImage">
            <img src="@/assets/images/ipay2.png" alt="" />
          </div>
          <div>
            <div class="name">支付宝支付</div>
          </div>
          <div v-if="orderForm.pay_type === 'zfb'" class="isChecked">
            <img src="@/assets/images/checked.png" alt="" />
          </div>
        </div>
      </div>
      <div
        class="payQrCode"
        v-if="orderForm.pay_type != 'yue' && store.state.media.isDesktop == true"
      >
        <div class="codeBox">
          <div class="payType">
            <div class="typeIcon">
              <img
                v-if="orderForm.pay_type == 'wx'"
                src="@/assets/images/WeChatPay.png"
                alt=""
              />
              <img
                v-if="orderForm.pay_type == 'zfb'"
                src="@/assets/images/ipayIcon.png"
                alt=""
              />
            </div>
            <div class="typeName">{{ orderForm.pay_type == 'wx'?'微信支付' :'支付宝支付'}}</div>
          </div>
          <div :class="orderForm.pay_type == 'wx' ? 'greenBox' : 'blueBox'">
            <div class="code">
              <img
                id="qr-code"
                v-if="codeLinks != ''"
                :src="codeLinks"
                alt="支付二维码"
              />
              <Spin fix v-else>
                <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
                <div>Loading</div>
              </Spin>
            </div>
          </div>
        </div>
      </div>
      <div class="goPay">
        <Button
          class="button"
          @click="goPay"
          v-if="orderForm.pay_type == 'yue'"
          :loading="disabeld"
          >去支付</Button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { orderDetailApi } from "@/api/personalCenter";
import { orderPayApi, payCodeApi, checkOrderStatusApi } from "@/api/order";
import countDown from "@/components/countDown.vue";
import {
  ref,
  reactive,
  watchEffect,
  onMounted,
  defineProps,
  getCurrentInstance,
  defineComponent,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import wx from "weixin-js-sdk";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { Message } from "view-ui-plus";

const { proxy } = getCurrentInstance(); // 获取组件实例

let router = useRouter();
let route = useRoute();
const store = useStore();
const order_id = route.query.order_id;
let orderData = reactive({});
let orderForm = reactive({
  order_id: order_id,
  pay_type: "yue",
});
let now_money = ref(0);
let disabeld = ref(false);
let codeLinks = ref("");

// 判断当前页面是否是从支付宝重定向回来的
const isRedirected = ref(false);

// 获取URL中的查询参数
function getQueryParams(url) {
  const params = new URLSearchParams(url.search);
  return Object.fromEntries(params.entries());
}

// 检查是否有支付宝支付后的查询参数
function isPaymentRedirected() {
  const queryParams = getQueryParams(window.location);
  // 检查是否有支付宝返回的参数，例如`out_trade_no`
  if (queryParams.out_trade_no) {
    return true;
  }
  return false;
}


// ----- --接口请求----------
const orderDetail = (data) => {
  orderDetailApi(data)
    .then((res) => {
      if (res.status == 200) {
        Object.assign(orderData, res.data.info);
        // orderData = res.data.info;
        now_money.value = res.data.now_money;
      }
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
let jsConfig = reactive({});
// 当前支付方式
function currentPay(pay_type) {
  stopPolling();
  orderForm.pay_type = pay_type;
  if (orderForm.pay_type == "wx") {
    orderForm.from = store.state.media.isDesktop == true ? "pc" : "weixinh5";
    orderPayApi(orderForm).then((res) => {
      if (res.status == 200) {
        if (res.data.result.jsConfig) {
          if (orderForm.from == "pc") {
            payCode({ text: res.data.result.jsConfig });
          } else {
            Object.assign(jsConfig, res.data.result.jsConfig);
          }
        }
      }
    });
  }
  // -------------------------支付宝处理-------------------------
  // 支付宝支付
  if (orderForm.pay_type == "zfb") {
    orderForm.from = store.state.media.isDesktop == true ? "zfbpc" : "zfbh5";
    orderPayApi(orderForm).then((res) => {
      if (res.status == 200) {
        if (res.data.result.jsConfig) {
          if (orderForm.from == "zfbpc") {
            payCode({ text: res.data.result.jsConfig.qrCode });
          } else {
            document.write(res.data.result.jsConfig)
            startPolling();
          }
        }
      }
    });
  }
}

// 获取支付二维码
const payCode = (data) => {
  payCodeApi(data).then((res) => {
    // console.log(res);
    if (res.status == 200) {
      codeLinks.value = res.data.code_path;
      startPolling();
    }
  });
};
let pollingInterval;
// 微信扫码支付结果轮询
const startPolling = () => {
  pollingInterval = setInterval(() => {
    checkOrderStatusApi({ order_id: orderForm.order_id })
      .then((res) => {
        if (res.status == 200) {
          if (res.data.status == true) {
            Message.success("支付成功");
            setTimeout(() => {
              stopPolling();
              router.push({ path: "/orderDetail", query: { id: orderForm.order_id } });
            }, 1000);
          }
        }
      })
      .catch((err) => {
        Message.error(err.msg);
        setTimeout(() => {
          stopPolling();
          router.push({ path: "/order?type=4" });
        }, 1000);
      });
  }, 3000);
};
const stopPolling = () => {
  clearInterval(pollingInterval);
};
// 余额支付按钮
const goPay = () => {
  disabeld.value = true;
  if (
    orderForm.pay_type == "yue" &&
    parseFloat(now_money.value) < parseFloat(orderData.pay_price)
  ) {
    Message.error("用户余额不足");
    disabeld.value = false;
    return;
  }
  orderPayApi(orderForm)
    .then((res) => {
      if (res.status == 200) {
        setTimeout(() => {
          Message.success(res.msg);
          router.push({ path: "/orderDetail", query: { id: orderForm.order_id } });
        }, 1000);
        disabeld.value = false;
      }
    })
    .catch((err) => {
      Message.error(err.msg);
      disabeld.value = false;
      setTimeout(() => {
        router.push({ path: "/order?type=4" });
      }, 1000);
    });
};
onMounted(() => {
  orderDetail({ id: order_id });
  isRedirected.value = isPaymentRedirected();
  if (isRedirected.value) {
    // 如果是从支付宝重定向回来的，可以在这里处理支付成功的逻辑
    startPolling()
  }
});
onUnmounted(() => {
  clearInterval(pollingInterval);
});
defineExpose({});
</script>

<style lang="less" scoped>
.payment-Pc {
  padding: 25px 5% 40px;
  .routerBox {
    margin-bottom: 15px;
  }
  .orderTip {
    // padding: 27px 30px 31px 64px;
    font-size: 32px;
    color: #243f5d;
    // width: 1200px;
    height: 150px;
    background-color: #ecf4ff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .tip-left {
      margin-top: 27px;
      margin-left: 64px;
    }
    .times {
      font-size: 16px;
      margin-top: 12px;
      display: flex;
      flex-direction: row;
    }
  }
  .detail {
    width: 100%;
    // height: 182px;
    background-color: #fff;
    padding: 31px 30px;
    .item {
      font-size: 16px;
      .order_price {
        color: #e93323;
      }
      & ~ .item {
        margin-top: 12px;
      }
    }
  }
  .payType {
    width: 100%;
    // height: 387px;
    background-color: #fff;
    margin-top: 17px;
    padding-bottom: 40px;
    .title {
      height: 57px;
      padding: 0 30px;
      border-bottom: 1px solid #e4e3e3;
      line-height: 57px;
      font-size: 18px;
    }
    .type {
      padding: 22px 30px 45px 30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .item {
        width: 300px;
        // height: 86px;
        padding: 30px 0;
        border: 1px solid #e4e3e3;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        & ~ .item {
          margin-left: 20px;
        }
        .iconImage {
          width: 50px;
          height: 50px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-tip {
          .name {
            font-size: 16px;
            color: #4e4e4e;
          }
          .yue {
            font-size: 16px;
            color: #969696;
            margin-top: 6px;
          }
        }
        .isChecked {
          width: 33px;
          height: 33px;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .payQrCode {
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .codeBox {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        .payType {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .typeIcon {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .typeName {
            font-size: 16px;
            color: #000;
            font-weight: 400;
          }
        }
        .greenBox,
        .blueBox {
          border-radius: 10px;
          width: 200px;
          height: 200px;
          margin: 30px 0 30px;
          .code {
            width: 180px;
            height: 180px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .demo-spin-icon-load {
              animation: ani-demo-spin 1s linear infinite;
            }
          }
        }
        .greenBox {
          border: 10px solid #43c93e;
        }
        .blueBox {
          border: 10px solid #06b4fd;
        }
      }
    }
    .goPay {
      display: flex;
      justify-content: right;
      margin-right: 100px;
      button {
        width: 150px;
        height: 50px;
        font-size: 16px;
        color: #fff;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        background-color: #047afe;
      }
    }
  }
}
.payment-notPc {
  padding: 25px 5% 20px;
  .routerBox {
    margin-bottom: 15px;
  }
  .orderTip {
    // padding: 27px 30px 31px 64px;
    font-size: 18px;
    color: #243f5d;
    background-color: #ecf4ff;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    background: url("/src/assets/images/payment_bg.png") no-repeat;
    background-size: 100% 100%;
    .tip-left {
      margin: 20px 15px;
    }
    .times {
      font-size: 14px;
      margin-top: 12px;
      display: flex;
      flex-direction: row;
    }
  }
  .detail {
    width: 100%;
    // height: 182px;
    background-color: #fff;
    padding: 31px 30px;
    .item {
      font-size: 16px;
      .order_price {
        color: #e93323;
      }
      & ~ .item {
        margin-top: 12px;
      }
    }
  }
  .payType {
    width: 100%;
    height: 100%;
    background-color: #fff;
    margin-top: 17px;
    .title {
      height: 57px;
      padding: 0 30px;
      border-bottom: 1px solid #e4e3e3;
      line-height: 57px;
      font-size: 18px;
    }
    .type {
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item {
        width: 300px;
        // height: 86px;
        padding: 30px 0;
        border: 1px solid #e4e3e3;
        position: relative;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        .iconImage {
          width: 50px;
          height: 50px;
          margin-right: 15px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .name {
          width: 106px;
          font-size: 16px;
          color: #4e4e4e;
        }
        .yue {
          font-size: 16px;
          color: #969696;
          margin-top: 6px;
        }
        .isChecked {
          width: 33px;
          height: 33px;
          position: absolute;
          right: 0;
          bottom: 0;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .payQrCode {
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .codeBox {
        width: 350px;
        height: 400px;
        background-color: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        .payType {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          .typeIcon {
            width: 35px;
            height: 35px;
            margin-right: 10px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .typeName {
            font-size: 16px;
            color: #000;
            font-weight: 400;
          }
        }
        .greenBox,
        .blueBox {
          border-radius: 10px;
          width: 200px;
          height: 200px;
          margin: 30px 0 30px;
          .code {
            width: 180px;
            height: 180px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .demo-spin-icon-load {
              animation: ani-demo-spin 1s linear infinite;
            }
          }
        }
        .greenBox {
          border: 10px solid #43c93e;
        }
        .blueBox {
          border: 10px solid #06b4fd;
        }
      }
    }
    .goPay {
      display: flex;
      justify-content: center;
      padding-bottom: 15px;
      button {
        width: 150px;
        height: 50px;
        font-size: 16px;
        color: #fff;
        border-radius: 10px;
        text-align: center;
        cursor: pointer;
        background-color: #047afe;
      }
    }
  }
}
</style>
