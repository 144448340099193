<template>
  <div :class="$store.state.media.isDesktop == true ? 'document-Pc' : 'document-notPc'">
    <div class="document-box">
      <div class="title">资源文档</div>
      <div class="document-item">
        <div class="item-box" v-for="art in articleList" :key="art.id">
          <div class="box-icon">
            <img :src="art.image_input" alt="" />
          </div>
          <div class="box-title">
            {{ art.title }}
          </div>
          <div class="box-text">
            {{ art.synopsis }}
          </div>
        </div>
        <!-- <div class="item-box">
          <div class="box-icon">
            <img src="@/assets/images/document.png" alt="" />
          </div>
          <div class="box-title">
            自动在线签发单域名SSL证书、多域名SSL证书、通配符SSL证书
          </div>
          <div class="box-text">
            推荐的SSL证书，很多用户不知道怎么选择SSL，这里我们编辑推荐几个热卖的SSL证书，您可以在这里快捷的选购推荐的SSL证书。
          </div>
        </div>
        <div class="item-box">
          <div class="box-icon">
            <img src="@/assets/images/document.png" alt="" />
          </div>
          <div class="box-title">
            自动在线签发单域名SSL证书、多域名SSL证书、通配符SSL证书
          </div>
          <div class="box-text">
            推荐的SSL证书，很多用户不知道怎么选择SSL，这里我们编辑推荐几个热卖的SSL证书，您可以在这里快捷的选购推荐的SSL证书。
          </div>
        </div> -->
      </div>
      <!-- <div class="more">更多文档></div> -->
    </div>
  </div>
</template>

<script setup>
import { homeArticleApi } from "@/api/home";
import {
  ref,
  reactive,
  onMounted,
  defineOptions,
  watchEffect,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const store = useStore();
let router = useRouter();
let articleList = reactive([]);
const homeArticle = () => {
  homeArticleApi().then((res) => {
    articleList.length = 0; // 清空数组;
    // console.log(res.data.list);
    articleList.push(...res.data.list); // 添加新数据到数组
  });
};
onMounted(() => {
  homeArticle();
});
defineOptions({
  name: "Document",
});
</script>

<style lang="less" scoped>
.document-Pc {
  padding: 0 5%;
  background-color: #fff;
  padding-bottom: 50px;
  .document-box {
    position: relative;
    text-align: center;
    .title {
      margin-top: 15px;
      font-size: 24px;
      color: #000;
      position: relative;
      display: inline-block;
    }
    .title::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 60%; /* 横线的宽度，可以根据需要调整 */
      height: 3px;
      border-radius: 3px;
      background-color: #0178fa;
    }
    .document-item {
      padding: 25px 0;
      display: flex;
      //   flex-direction: row;;

      .item-box {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        position: relative;
        flex: 1;
        padding-bottom: 15px;
        margin-right: 25px;
        .box-icon {
          width: 100%;
          height: 280px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .box-title {
          font-size: 18px;
          color: #000;
          margin: 15px 0;
          font-family: "黑体";
          padding: 0 10px;
          text-align: left;
        }
        .box-text {
          padding: 0 10px;
          text-align: left;
          color: #878787;
        }
      }
      .item-box:last-of-type {
        margin-right: 0;
      }
    }
    .more {
      color: #0178fa;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
.document-notPc {
  padding: 0 5%;
  background-color: #fff;
  padding-bottom: 50px;
  .document-box {
    position: relative;
    text-align: center;
    .title {
      margin-top: 15px;
      font-size: 24px;
      color: #000;
      position: relative;
      display: inline-block;
    }
    .title::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 60%; /* 横线的宽度，可以根据需要调整 */
      height: 3px;
      border-radius: 3px;
      background-color: #0178fa;
    }
    .document-item {
      padding: 25px 0;
      display: flex;
      flex-direction: column;
      .item-box {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        position: relative;
        flex: 1;
        padding-bottom: 15px;
        margin-right: 25px;
        .box-icon {
          width: 100%;
          height: 150px;
          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        .box-title {
          font-size: 18px;
          color: #000;
          margin: 15px 0;
          font-family: "黑体";
          padding: 0 10px;
          text-align: left;
        }
        .box-text {
          padding: 0 10px;
          text-align: left;
          color: #878787;
        }
      }
      .item-box:last-of-type {
        margin-right: 0;
      }
    }
    .more {
      color: #0178fa;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
