<template>
  <div
    :class="$store.state.media.isDesktop == true ? 'generateCSR-Pc' : 'generateCSR-notPc'"
  >
    <div class="titleBox">
      <div class="title">CSR文件生成工具</div>
      <div class="typeTips">轻松生成您的CSR和私钥<span>（中英文填写均可）</span></div>
    </div>
    <div class="content">
      <Form
        :model="formData"
        :rules="ruleInline"
        ref="generateCsrForm"
        :label-width="100"
        style="width: 100%"
        label-position="top"
      >
        <Row :gutter="24">
          <Col span="12">
            <FormItem label="组织/公司" prop="organization">
              <Input
                v-model="formData.organization"
                placeholder="请输入组织/公司全称"
              ></Input> </FormItem
          ></Col>
          <Col span="12">
            <FormItem label="部门/单位" prop="department">
              <Input
                v-model="formData.department"
                placeholder="请输入所在部门/单位"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24">
          <Col span="12">
            <FormItem label="国家" prop="country">
              <Select v-model="formData.country">
                <Option value="beijing">New York</Option>
                <Option value="shanghai">London</Option>
                <Option value="shenzhen">Sydney</Option>
                <Option value="CN">中国</Option>
                <Option value="HK">中国香港</Option>
                <Option value="TW">中國臺灣</Option>
                <Option value="US">美国</Option>
                <Option value="AX">Åland</Option>
                <Option value="ME">Montenegro</Option>
                <Option value="RS">Serbia</Option>
                <Option value="MO">Macau</Option>
                <Option value="AG">Antigua and Barbuda</Option>
                <Option value="AF">Afghanistan</Option>
                <Option value="AZ">Azerbaijan</Option>
                <Option value="AW">Aruba</Option>
                <Option value="AE">الامارات</Option>
                <Option value="AM">Armenia</Option>
                <Option value="AO">Angola</Option>
                <Option value="AD">Andorra</Option>
                <Option value="AI">Anguilla</Option>
                <Option value="BB">Barbados</Option>
                <Option value="BM">Bermuda</Option>
                <Option value="BW">Botswana</Option>
                <Option value="BS">The Bahamas</Option>
                <Option value="DZ">Algeria</Option>
                <Option value="AL">Shqipëri</Option>
                <Option value="BZ">Belize</Option>
                <Option value="AR">Argentina</Option>
                <Option value="BA">Bosna i Hercegovina</Option>
                <Option value="SB">Solomon Islands</Option>
                <Option value="BR">Brasil</Option>
                <Option value="AS">American Samoa</Option>
                <Option value="BY">Belarus</Option>
                <Option value="AU">Australia</Option>
                <Option value="AT">Österreich</Option>
                <Option value="BG">Bulgaria</Option>
                <Option value="BH">البحرين</Option>
                <Option value="CG">Congo</Option>
                <Option value="BE">België</Option>
                <Option value="BD">Bangladesh</Option>
                <Option value="LK">Sri Lanka</Option>
                <Option value="BO">Bolivia</Option>
                <Option value="BJ">Benin</Option>
                <Option value="CC">Cocos (Keeling) Islands</Option>
                <Option value="MM">Myanmar</Option>
                <Option value="KY">Cayman Islands</Option>
                <Option value="BT">Bhutan</Option>
                <Option value="CM">Cameroon</Option>
                <Option value="BN">Brunei</Option>
                <Option value="CO">Colombia</Option>
                <Option value="CA">Canada</Option>
                <Option value="TD">Chad</Option>
                <Option value="BI">Burundi</Option>
                <Option value="CF">Central African Republic</Option>
                <Option value="KH">Cambodia</Option>
                <Option value="CD">Democratic Republic of Congo</Option>
                <Option value="CV">Cape Verde</Option>
                <Option value="DK">Danmark</Option>
                <Option value="EC">Ecuador</Option>
                <Option value="IE">Ireland</Option>
                <Option value="EG">مصر</Option>
                <Option value="GQ">Equatorial Guinea</Option>
                <Option value="ER">Eritrea</Option>
                <Option value="ET">Ethiopia</Option>
                <Option value="CZ">Česká republika</Option>
                <Option value="FM">Federated States of Micronesia</Option>
                <Option value="PF">French Polynesia</Option>
                <Option value="FR">France</Option>
                <Option value="CL">Chile</Option>
                <Option value="GA">Gabon</Option>
                <Option value="GE">Georgia</Option>
                <Option value="KM">Comoros</Option>
                <Option value="MP">Northern Mariana Islands</Option>
                <Option value="GD">Grenada</Option>
                <Option value="CR">Costa Rica</Option>
                <Option value="GL">Greenland</Option>
                <Option value="CU">Cuba</Option>
                <Option value="CK">Cook Islands</Option>
                <Option value="GU">Guam</Option>
                <Option value="GT">Guatemala</Option>
                <Option value="GY">Guyana</Option>
                <Option value="DJ">Djibouti</Option>
                <Option value="GR">Ellás</Option>
                <Option value="DM">Dominica</Option>
                <Option value="DO">Dominican Republic</Option>
                <Option value="EE">Eesti</Option>
                <Option value="SV">El Salvador</Option>
                <Option value="GF">Guyane Française</Option>
                <Option value="FJ">Fiji</Option>
                <Option value="FI">Suomi</Option>
                <Option value="FK">Falkland Islands</Option>
                <Option value="FO">Føroyar</Option>
                <Option value="GM">The Gambia</Option>
                <Option value="GH">Ghana</Option>
                <Option value="GI">Gibraltar</Option>
                <Option value="DE">Deutschland</Option>
                <Option value="GP">Guadeloupe</Option>
                <Option value="GN">Guinea</Option>
                <Option value="HN">Honduras</Option>
                <Option value="IS">Ísland</Option>
                <Option value="HT">Haiti</Option>
                <Option value="HU">Magyarország</Option>
                <Option value="IO">British Indian Ocean Territory</Option>
                <Option value="IT">Italia</Option>
                <Option value="CI">Côte d´Ivoire</Option>
                <Option value="JM">Jamaica</Option>
                <Option value="KP">North Korea</Option>
                <Option value="JO">الأردن</Option>
                <Option value="KZ">Kazakhstan</Option>
                <Option value="LB">لبنان</Option>
                <Option value="KR">한국</Option>
                <Option value="KW">الكويت</Option>
                <Option value="LA">Laos</Option>
                <Option value="LR">Liberia</Option>
                <Option value="LS">Lesotho</Option>
                <Option value="MG">Madagascar</Option>
                <Option value="LY">Libya</Option>
                <Option value="HR">Hrvatska</Option>
                <Option value="MW">Malawi</Option>
                <Option value="ML">Mali</Option>
                <Option value="ID">Republik Indonesia</Option>
                <Option value="MA">المغرب</Option>
                <Option value="MT">Malta</Option>
                <Option value="MU">Mauritius</Option>
                <Option value="MY">Malaysia</Option>
                <Option value="NC">New Caledonia</Option>
                <Option value="VU">Vanuatu</Option>
                <Option value="NL">Nederland</Option>
                <Option value="NP">Nepal</Option>
                <Option value="SR">Suriname</Option>
                <Option value="NZ">New Zealand</Option>
                <Option value="NG">Nigeria</Option>
                <Option value="PN">Pitcairn Islands</Option>
                <Option value="IR">Iran</Option>
                <Option value="IN">India</Option>
                <Option value="IL">Israel</Option>
                <Option value="IQ">Iraq</Option>
                <Option value="JP">日本</Option>
                <Option value="KE">Kenya</Option>
                <Option value="KI">Kiribati</Option>
                <Option value="CX">Christmas Island</Option>
                <Option value="KG">Kyrgyzstan</Option>
                <Option value="LT">Lietuva</Option>
                <Option value="SK">Slovenská</Option>
                <Option value="LI">Liechtenstein</Option>
                <Option value="LV">Latvija</Option>
                <Option value="LU">Luxembourg</Option>
                <Option value="MD">Moldova</Option>
                <Option value="MN">Mongolia</Option>
                <Option value="MQ">Martinique</Option>
                <Option value="YT">Mayotte</Option>
                <Option value="MS">Montserrat</Option>
                <Option value="MK">Macedonia</Option>
                <Option value="MC">Monaco</Option>
                <Option value="MV">Maldives</Option>
                <Option value="MR">Mauritania</Option>
                <Option value="NF">Norfolk Island</Option>
                <Option value="OM">عمان</Option>
                <Option value="MX">México</Option>
                <Option value="PK">Pakistan</Option>
                <Option value="MZ">Mozambique</Option>
                <Option value="PA">Panamá</Option>
                <Option value="NU">Niue</Option>
                <Option value="PG">Papua New Guinea</Option>
                <Option value="NE">Niger</Option>
                <Option value="NO">Norge</Option>
                <Option value="QA">قطر</Option>
                <Option value="NR">Nauru</Option>
                <Option value="PH">Philippines</Option>
                <Option value="NI">Nicaragua</Option>
                <Option value="PY">Paraguay</Option>
                <Option value="SA">السعودية</Option>
                <Option value="PE">Peru</Option>
                <Option value="PL">Polska</Option>
                <Option value="XX">Disputed Territory</Option>
                <Option value="PW">Palau</Option>
                <Option value="MH">Marshall Islands</Option>
                <Option value="RE">La Réunion</Option>
                <Option value="RU">Russia</Option>
                <Option value="PR">Puerto Rico</Option>
                <Option value="PM">Saint Pierre and Miquelon</Option>
                <Option value="XS">Spratly Islands</Option>
                <Option value="PT">Portugal</Option>
                <Option value="GW">Guinea-Bissau</Option>
                <Option value="RO">România</Option>
                <Option value="RW">Rwanda</Option>
                <Option value="KN">Saint Kitts and Nevis</Option>
                <Option value="SN">Sénégal</Option>
                <Option value="SM">San Marino</Option>
                <Option value="LC">St. Lucia</Option>
                <Option value="GS">South Georgia and the South Sandwich Islands</Option>
                <Option value="TK">Tokelau</Option>
                <Option value="SH">St Helena Ascension and Tristan da Cunha</Option>
                <Option value="TN">Tunisia</Option>
                <Option value="SL">Sierra Leone</Option>
                <Option value="ES">España</Option>
                <Option value="SC">Seychelles</Option>
                <Option value="SI">Slovenija</Option>
                <Option value="SO">Somalia</Option>
                <Option value="CH">Schweiz</Option>
                <Option value="TJ">Tajikistan</Option>
                <Option value="SD">Sudan</Option>
                <Option value="SY">Syria</Option>
                <Option value="TT">Trinidad and Tobago</Option>
                <Option value="TC">Turks and Caicos Islands</Option>
                <Option value="TO">Tonga</Option>
                <Option value="ZA">South Africa</Option>
                <Option value="TG">Togo</Option>
                <Option value="TR">Turkey</Option>
                <Option value="SG">Singapore</Option>
                <Option value="SE">Sverige</Option>
                <Option value="TZ">Tanzania</Option>
                <Option value="TH">Thailand</Option>
                <Option value="GB">United Kingdom</Option>
                <Option value="US">United States</Option>
                <Option value="UY">Uruguay</Option>
                <Option value="VG">British Virgin Islands</Option>
                <Option value="VI">US Virgin Islands</Option>
                <Option value="NA">Namibia</Option>
                <Option value="WF">Wallis and Futuna</Option>
                <Option value="UA">Ukrayina</Option>
                <Option value="VE">Venezuela</Option>
                <Option value="VA">Città del Vaticano</Option>
                <Option value="XE">Iraq-Saudi Arabia Neutral Zone</Option>
                <Option value="ZM">Zambia</Option>
                <Option value="WS">Samoa</Option>
                <Option value="ST">Sao Tome and Principe</Option>
                <Option value="VC">Saint Vincent and the Grenadines</Option>
                <Option value="TL">Timor-Leste</Option>
                <Option value="TV">Tuvalu</Option>
                <Option value="TM">Turkmenistan</Option>
                <Option value="UG">Uganda</Option>
                <Option value="BF">Burkina Faso</Option>
                <Option value="UZ">Uzbekistan</Option>
                <Option value="VN">Vietnam</Option>
                <Option value="SZ">Swaziland</Option>
                <Option value="EH">Western Sahara</Option>
                <Option value="XD">United Nations Neutral Zone</Option>
                <Option value="YE">Yemen</Option>
                <Option value="ZW">Zimbabwe</Option>
                <Option value="CY">Cyprus</Option>
                <Option value="UM">United States Minor Outlying Islands</Option>
                <Option value="HM">Heard Island and McDonald Islands</Option>
                <Option value="SJ">Svalbard and Jan Mayen</Option>
                <Option value="AQ">Antarctica</Option>
                <Option value="TF">French Southern Territories</Option>
                <Option value="PS">Palestine</Option>
                <Option value="BV">Bouvet Island</Option>
                <Option value="MF">Saint-Martin</Option>
                <Option value="BL">Saint-Barthélemy</Option>
                <Option value="BQ">Bonaire Saint Eustatius and Saba</Option>
                <Option value="SS">The Republic of South Sudan</Option>
                <Option value="CW">Curaçao</Option>
                <Option value="SX">Sint Maarten</Option>
                <Option value="DE">德国</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="省份" prop="province">
              <Input v-model="formData.province" placeholder="请输入所在省份"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24">
          <Col span="12">
            <FormItem label="城市" prop="city">
              <Input v-model="formData.city" placeholder="请输入所在城市"></Input>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="加密算法" prop="key_type">
              <Select v-model="formData.key_type" placeholder="请选择">
                <Option value="0" label="RSA"> </Option>
                <Option value="3" label="ECDSA"> </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24">
          <Col span="12">
            <FormItem label="哈希签名算法" prop="hash_algorithms">
              <Select v-model="formData.hash_algorithms" placeholder="请选择">
                <Option value="sha256" label="SHA256"> </Option>
                <Option value="sha384" label="SHA384"> </Option>
                <Option value="sha512" label="SHA512"> </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="12">
            <FormItem label="加密位数" prop="keysize">
              <Select v-model="formData.keysize" placeholder="请选择">
                <Option value="2048" label="2048"> </Option>
                <Option value="3072" label="3072"> </Option>
                <Option value="4096" label="4096"> </Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="24">
          <Col span="12">
            <FormItem label="邮箱" prop="email">
              <Input
                v-model="formData.email"
                placeholder="请输入常用邮箱"
              ></Input> </FormItem
          ></Col>
          <Col span="12">
            <FormItem label="域名" prop="domain">
              <Input
                v-model="formData.domain"
                placeholder="请输入需要申请的域名"
              ></Input> </FormItem
          ></Col>
        </Row>
      </Form>
      <Button type="primary" class="buildBtn" @click="submit">生成CSR文件</Button>
    </div>
    <div class="result" v-if="isBuild == true">
      <div class="result-title">搜索结果</div>
      <Divider />
      <div class="result-item">
        <div class="certType">
          <div class="type_name">CSR文件</div>
          <div class="type_tips">
            SSL证书请求CSR文件，请使用txt记事本将下面加密字符串存为yourdomain_csr文件或点击右侧按钮下载
          </div>
        </div>
        <!-- <Button type="primary" class="downLoad" @click="exoprtCert">点击下载</Button> -->
      </div>
      <pre class="buildPfx">{{result.csr}}</pre>
      <div class="result-item" style="margin-top: 4s0px">
        <div class="certType">
          <div class="type_name">KEY文件</div>
          <div class="type_tips">
            SSL证书密钥KEY文件，请使用txt记事本将下面加密字符串存为yourdomain_key文件或点击右侧按钮下载
          </div>
        </div>
        <!-- <Button type="primary" class="downLoad" @click="exoprtCert">点击下载</Button> -->
      </div>
      <pre class="buildPfx">{{result.privateKey}}</pre>
    </div>

    <div class="tips">
      <div class="tips-title">生成CSR注意事项：</div>
      <div class="tips-content">
        <ul>
          <li>
            目前市场上的付费证书（Sectigo，GlobalSign，Certum，Geotrust）都支持中文企业信息。如果申请OV和EV证书，建议参考企业营业执照或企业注册信息填写。
          </li>
          <li>
            <strong>加密算法和加密位数：</strong>如无特殊需求，请选择目前国际通用加密算法RSA，加密位数2048。如申请代码签名证书，加密位数请选择4096。
          </li>
          <li><strong>哈希签名算法: </strong>如无特殊需求, 选择SHA256。</li>
          <li>
            <strong>域名：</strong>
            <ul style="margin-left: 30px">
              <li>申请通配符SSL证书时，域名栏需要填写：*.yourdomain.com；</li>
              <li>单域名SSL证书输入域名即可，如果是保护子域名，则直接输入子域名；</li>
              <li>
                申请多域名SAN
                SSL证书，只要输入需要保护的一个主域名即可，其它域名在提交证书的时候输入
              </li>
            </ul>
          </li>
          <li>
            生成CSR文件同时会生成密钥KEY文件。请务必存好两个文件，CSR文件在申请证书时会用到，Key文件在部署安装证书文件时会用到。如果Key文件不慎丢失，无法再次找回；只能重新生成新的CSR文件和Key文件，再重新申请证书。
          </li>
          <li>瑞杰信提供的SSL在线工具不产生任何本地临时文件，不要担心信息泄露。</li>
          <li>
            瑞杰信提供的SSL在线工具所有用户均可免费使用，用户在使用中、使用后产生的任何错误或造成任何损失，瑞杰信不承担任何责任，用户使用本在线工具即表示接受本协议。
          </li>
          <li>使用本在线工具过程中，如有任何bug，欢迎反馈给我们，我们会不断改进完善。</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, defineExpose, getCurrentInstance } from "vue";
import { generateCsrApi } from "@/api/tools";
import { Message } from "view-ui-plus";

const { proxy } = getCurrentInstance(); // 获取组件实例

let formData = reactive({
  organization: "",
  department: "",
  serial_no: "",
  country: "CN",
  province: "",
  city: "",
  key_type: "0",
  hash_algorithms: "sha256",
  keysize: "2048",
  email: "",
  domain: "",
});
const ruleInline = {
    organization: [{ required: true, message: "请输入组织/公司全称", trigger: "blur" }],
    department: [{ required: true, message: "请输入部门/单位", trigger: "blur" }],
    serial_no: [{ required: true, message: "请选择国家", trigger: "blur" }],
    province: [{ required: true, message: "请选择省份", trigger: "blur" }],
    city: [{ required: true, message: "请输入城市", trigger: "blur" }],
    key_type: [{ required: true, message: "请选择加密算法", trigger: "blur" }],
    hash_algorithms: [{ required: true, message: "请选择哈希签名算法", trigger: "blur" }],
    keysize: [{ required: true, message: "请选择加密位数", trigger: "blur" }],
    email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
    domain: [{ required: true, message: "请输入域名", trigger: "blur" }],
};
let result = reactive({
  csr: "",
  privateKey: "",
});
let isBuild = ref(false);
const generateCsr = (data) => {
  generateCsrApi(data).then((res) => {
    if (res.status == 200) {
      Message.success("生成成功");
      isBuild.value = true
      Object.assign(result,res.data)
    }
  });
};
const submit = () => {
  proxy.$refs.generateCsrForm.validate((valid) => {
    if (valid) {
      generateCsr(formData);
    } else {
      return;
    }
  });
};
defineExpose({
  generateCsrForm: proxy.$refs.generateCsrForm,
});
</script>
<style lang="less" scoped>
.generateCSR-Pc {
  padding: 25px 5% 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .titleBox {
    margin-bottom: 15px;
    .title {
      font-size: 36px;
      color: #000;
    }
    .typeTips {
      text-align: center;
      span {
        color: #1374e0;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .buildBtn {
      width: 150px;
      height: 42px;
    }
  }
  :deep(.ivu-form-item-label) {
    text-align-last: left !important;
  }
  :deep(.ivu-form-item-content) {
    margin-left: 0px !important;
  }
  .result {
    width: 100%;
    .result-title {
      font-size: 18px;
    }
    .result-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .certType {
        .type_name {
          font-size: 18px;
        }
      }
    }
    .buildPfx {
      width: 100%;
      height: 450px;
      overflow: auto;
      background-color: #f4f5f9;
      padding: 14px 10px;
      border-radius: 5px;
    }
  }
  .tips {
    width: 100%;
    padding: 40px 25px;
    margin-top: 30px;
    background-color: #f4f5f9;
    .tips-title {
      font-size: 18px;
      color: #1374e0;
      margin-bottom: 25px;
    }
    .tips-content{
        li{
            margin-bottom: 5px;
        }
    }
  }
}
.generateCSR-notPc {
  padding: 25px 5% 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .titleBox {
    margin-bottom: 15px;
    .title {
      font-size: 36px;
      color: #000;
    }
    .typeTips {
      text-align: center;
      span {
        color: #1374e0;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .buildBtn {
      width: 150px;
      height: 42px;
    }
  }
  :deep(.ivu-form-item-label) {
    text-align-last: left !important;
  }
  :deep(.ivu-form-item-content) {
    margin-left: 0px !important;
  }
  .tips {
    width: 100%;
    padding: 40px 25px;
    margin-top: 30px;
    background-color: #f4f5f9;
    .tips-title {
      font-size: 18px;
      color: #1374e0;
      margin-bottom: 25px;
    }
  }
}
</style>
