<template>
  <div
    :class="$store.state.media.isDesktop == true ? 'productCard-Pc' : 'productCard-notPc'"
  >
    <div class="paneItemBox" v-if="props.List.length > 0">
      <div
        class="pane-item"
        v-for="(itm, index) in props.List"
        :key="index"
        @mouseenter="showBtn(index)"
        @mouseleave="hiddenBtn(index)"
        @click="isShowInMedia(index)"
      >
        <div class="pane-item-box">
          <div class="brand_image">
            <img :src="itm.brand_image" alt="" />
            <!-- <img src="@/assets/images/DV.png" alt="" /> -->
          </div>
          <div class="store_name">
            {{ itm.store_name }}
          </div>
          <div class="storeBox">
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">支持追加域名：</div>
              <div class="box-value">
                {{ itm.support_san == 0 ? "不支持" : "支持" }}
              </div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">支持通配符：</div>
              <div class="box-value">
                {{ itm.support_wildcard == 0 ? "不支持" : "支持" }}
              </div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">绿色地址栏：</div>
              <div class="box-value">
                {{ itm.support_greenbar == 0 ? "不显示" : "显示 " }}
              </div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">支持小程序：</div>
              <div class="box-value">{{ itm.ssl_type == 2 ? "不支持" : "支持" }}</div>
            </div>
            <div :class="itm.ssl_effect.length > 12 ? 'box-item-many' : 'box-item-less'">
              <div class="box-label">适用网站：</div>
              <div class="box-value">{{ itm.ssl_effect }}</div>
            </div>
          </div>
          <div class="store-price">
            <div class="annually_price" v-if="itm.ssl_free == 0">
              <span>
                {{ itm.periods_price.ordinary.annually_price }}
              </span>
              元/起
            </div>
            <div
              class="annually_price"
              v-if="itm.ssl_free == 1 && itm.periods_price.ordinary.quarterly_price <= 0"
            >
              <span> 免费 </span>
            </div>
            <div
              class="annually_price"
              v-if="itm.ssl_free == 1 && itm.periods_price.ordinary.quarterly_price > 0"
            >
              <span> {{ itm.periods_price.ordinary.quarterly_price }} </span>
            </div>
            <div class="market_price">市场价{{ itm.market_price }}元/年</div>
            <transition name="slide-down">
              <button
                class="store-buy"
                @click.stop="bugNow(itm, index)"
                v-show="itm.showButton"
              >
                立即购买
              </button>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="emptyBox" v-else>产品上新中···</div>
    <Drawer
      :z-index="1006"
      :closable="true"
      v-model="isShowDrawer"
      :mask-closable="false"
      :mask-style="{
        'z-index': '1003',
      }"
      class-name="drawerBox"
      :width="drawerWidth"
      @on-close="closeDrawer"
    >
      <template #header>
        <div style="font-size: 16px; padding: 10px 0; color: #000">
          {{ drawerTitle }}
        </div>
      </template>
      <Form
        :model="placeOrderFormData"
        :rules="ruleInline"
        ref="placeOrderForm"
        label-position="top"
      >
        <FormItem label="购买年限" prop="periods">
          <div class="periods first">
            <Select
              v-model="placeOrderFormData.periods"
              style="width: 250px"
              @on-select="periodsSelect"
            >
              <Option
                v-for="(ord, idx) in periods_price_txt"
                :value="ord.name"
                :key="idx"
                :label="ord.name"
              >
              </Option>
            </Select>
            <div class="price">{{ periodsPrice }}元</div>
          </div>
        </FormItem>
        <FormItem label="优惠券码" prop="coupon">
          <Input
            type="text"
            v-model="placeOrderFormData.coupon"
            placeholder="请输入优惠券码"
            style="width: '250px'"
          />
        </FormItem>
        <!-- 
          混合证书：显示三个   ssl_type == 3
          IP证书并且支持追加域名: 显示其他IP SAN ssl_type == 1
          通配符证书并且支持追加域名: 显示其他通配符 ssl_type == 2
          普通域名并且支持追加域名: 显示其他域名 ssl_type == 0
          -->
        <FormItem v-if="isShowOrdinary == true" prop="ordinary_san_num">
          <template #label>
            <div class="">其他域名</div>
          </template>
          <div
            class="periods"
            v-if="purchasePeriod.san_price.ordinary[purchasePeriod.default] > '0'"
          >
            <InputNumber
              min="0"
              :max="max_domain"
              v-if="purchasePeriod.ssl_type == 3"
              v-model="placeOrderFormData.ordinary_san_num"
              controls-outside
            />
            <InputNumber
              min="0"
              max="3000"
              v-else
              v-model="placeOrderFormData.ordinary_san_num"
              controls-outside
            />
            <div class="priceBox">
              <div class="label">每个域名为{{ ordinary_san_num }}/年</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="其他IP" v-if="isShowIp == true" prop="ip_san_num">
          <div
            class="periods"
            v-if="purchasePeriod.san_price.ip[purchasePeriod.default] > '0'"
          >
            <InputNumber
              min="0"
              v-model="placeOrderFormData.ip_san_num"
              controls-outside
            />
            <div class="priceBox">
              <div class="label">每个域名为{{ ip_san_num }}/年</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="其他通配符域名" v-if="isShowSan == true" prop="wildcard_san_num">
          <div
            class="periods"
            v-if="purchasePeriod.san_price.wildcard[purchasePeriod.default] > '0'"
          >
            <InputNumber
              min="0"
              v-model="placeOrderFormData.wildcard_san_num"
              controls-outside
            />
            <div class="priceBox" v-if="placeOrderFormData.wildcard_san_num > 0">
              <div class="label">每个域名为{{ wildcard_san_num }}/年</div>
            </div>
          </div>
        </FormItem>
      </Form>
      <div class="drawer-footer">
        <div class="sum">
          小计：<span>{{ sum }}</span
          >元
        </div>
        <Button
          type="primary"
          style="width: 100%; height: 40px; font-size: 16px"
          @click="drawerCheckBtn"
          >立刻下单</Button
        >
      </div>
    </Drawer>
  </div>
</template>

<script setup>
import { getProductDetailsApi, orderPlaceApi } from "@/api/order";
import { calculatePriceApi } from "@/api/product";
import * as math from "mathjs";
import {
  ref,
  reactive,
  onMounted,
  defineOptions,
  watchEffect,
  computed,
  getCurrentInstance,
  defineProps,
  onBeforeUnmount,
} from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { typeOf } from "view-ui-plus/src/utils/assist";
import { Message } from "view-ui-plus";
import { getCookies } from "@/libs/util";
const { proxy } = getCurrentInstance(); // 获取组件实例
// 获取全局管理的 title、keywords 和 description
const metaTags = getInitialMetaTags();

const store = useStore();
let router = useRouter();
const props = defineProps({
  List: Array,
  default: () => [],
});
let showButton = ref(false);
// console.log(List);
// 下单弹窗部分数据
let isShowDrawer = ref(false);
let drawerTitle = ref("");
let drawerWidth = ref("300");
let max_domain = ref(0);
let purchasePeriod = reactive({}); // 购买年限列表
let placeOrderFormData = reactive({
  store_slug: "",
  periods: "",
  ordinary_san_num: 0,
  ip_san_num: 0,
  wildcard_san_num: 0,
  coupon: "",
});
let periods_price_txt = reactive([]);
let ordinary_san_num = ref(0);
let ip_san_num = ref(0);
let wildcard_san_num = ref(0);
// -----控制表单项显隐------
let isShowIp = ref(false);
let isShowOrdinary = ref(false);
let isShowSan = ref(false);
let periodsPrice = ref(0); // 年限单价
let canDecreaseOrdinary = ref(0);
let canDecreaseIP = ref(0);
let canDecreaseWildcard = ref(0);

let sum = ref(0);
const ruleInline = {
  periods: [{ required: true, message: "请选择购买年限", trigger: "blur" }],
};

// 获取产品价格详情
const getProductDetails = (data) => {
  getProductDetailsApi(data).then((res) => {
    if (res.status == 200) {
      Object.assign(purchasePeriod, res.data.info);
      document.title = res.data.info.store_name;
      if (placeOrderFormData.ordinary_san_num == 0) {
        placeOrderFormData.ordinary_san_num = canDecreaseOrdinary.value;
      }
      max_domain.value = purchasePeriod.max_domain.toString();
      placeOrderFormData.store_slug = res.data.info.store_slug;
      placeOrderFormData.ssl_type = res.data.info.ssl_type;
      purchasePeriod.periods_price_txt.map((item) => {
        if (item.price > -1) {
          periods_price_txt.push(item);
        }
      });
      placeOrderFormData.periods = res.data.info.periods_price_txt[0].name;
      periodsPrice.value = res.data.info.periods_price_txt[0].price;
      showFormItem();
      // let token = getCookies("token");
      // if (token != "") {
      //   let newData = {
      //     store_slug: placeOrderFormData.store_slug,
      //     periods: res.data.info.default,
      //     ordinary_san_num: placeOrderFormData.ordinary_san_num,
      //     ip_san_num: placeOrderFormData.ip_san_num,
      //     wildcard_san_num: placeOrderFormData.wildcard_san_num,
      //     coupon: placeOrderFormData.coupon,
      //   };
      //   // calculatePrice(newData);
      // }
    }
  });
};
const orderPlace = (data) => {
  orderPlaceApi(data).then((res) => {
    if (res.status == 200) {
      Message.warning("下单成功！将前往订单支付页面~");
      router.push({
        path: "/payment",
        query: {
          order_id: res.data.order_id,
        },
      });
      document.title = metaTags.title;
    }
  });
};
// 列表部分 按钮
const showBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    props.List[index].showButton = true;
  }
};
const hiddenBtn = (index) => {
  if (store.state.media.isDesktop == true) {
    props.List[index].showButton = false;
  }
};
const isShowInMedia = (index) => {
  if (store.state.media.isDesktop != true) {
    props.List[index].showButton = !props.List[index].showButton;
  }
};
// 立刻购买
const bugNow = (item, index) => {
  // console.log(item);
  drawerTitle.value = item.store_name;
  isShowDrawer.value = true;
  periodsPrice.value = 0;
  proxy.$refs.placeOrderForm.resetFields();
  getProductDetails({ store_slug: item.store_slug });
};
// ------------下单弹窗按钮-------------

// 关闭弹窗按钮
const closeDrawer = () => {
  proxy.$refs.placeOrderForm.resetFields();
  placeOrderFormData.periods = "";
  isShowDrawer.value = false;
  // 重置显示
  isShowIp.value = false;
  isShowSan.value = false;
  isShowOrdinary.value = false;
  periods_price_txt.length = 0;
  document.title = metaTags.title;
};
// 立刻下单按钮
const drawerCheckBtn = () => {
  proxy.$refs.placeOrderForm.validate((valid) => {
    if (valid) {
      if (placeOrderFormData.periods) {
        periods_price_txt.map((item) => {
          if (item.name == placeOrderFormData.periods) {
            placeOrderFormData.periods = item.name_un;
          }
        });
        orderPlace(placeOrderFormData);
      }
    } else {
      return false;
    }
  });
};
// 购买年限下拉框
const periodsSelect = (e) => {
  // console.log(e);
  if (e != {}) {
    periods_price_txt.forEach((item) => {
      if (item.name == e.label) {
        placeOrderFormData.periods = item.name_un;
        periodsPrice.value =
          typeOf(item.price) == String ? Number(item.price) : item.price;
      }
    });
    // calculatePrice(placeOrderFormData)
  }
};
// 处理下单表单项的显隐
const showFormItem = () => {
  if (purchasePeriod.ssl_type != 2 && purchasePeriod.support_san != 0) {
    isShowIp.value = false;
    isShowOrdinary.value = false;
    isShowSan.value = false;
    if (purchasePeriod.ssl_type == 3) {
      isShowIp.value = true;
      isShowOrdinary.value = true;
      isShowSan.value = true;
    }
    if (purchasePeriod.support_san == 1) {
      isShowOrdinary.value = true;
    }
    if (periods_price_txt != []) {
      isShowIp.value =
        purchasePeriod.san_price.ip[purchasePeriod.default] > "0" ? true : false;
      isShowOrdinary.value =
        purchasePeriod.san_price.ordinary[purchasePeriod.default] > "0" ? true : false;
      isShowSan.value =
        purchasePeriod.san_price.wildcard[purchasePeriod.default] > "0" ? true : false;
    }
  }
};
const calculatePrice = (data) => {
  if (data.periods != "") {
    calculatePriceApi(data).then((res) => {
      if (res.status == 200) {
        sum.value = res.data.sum_price;
      }
    });
  }
};
// 处理混合域名的最小数量限制
const calculateMin = () => {
  let newData = {
    store_slug: placeOrderFormData.store_slug,
    periods: "",
    ordinary_san_num: placeOrderFormData.ordinary_san_num,
    ip_san_num: placeOrderFormData.ip_san_num,
    wildcard_san_num: placeOrderFormData.wildcard_san_num,
    coupon: placeOrderFormData.coupon,
  };
  periods_price_txt.forEach((item) => {
    if (item.name == placeOrderFormData.periods) {
      newData.periods = item.name_un;
      periodsPrice.value = typeOf(item.price) == String ? Number(item.price) : item.price;
    }
  });
  calculatePrice(newData);
};

// ===============处理网页标题，关键词，描述================
function getInitialMetaTags() {
  const title = document.title;
  const keywords = getMetaContent("keywords");
  const description = getMetaContent("description");

  return { title, keywords, description };
}

function getMetaContent(name) {
  const metaTag = document.querySelector(`meta[name="${name}"]`);
  return metaTag ? metaTag.getAttribute("content") : "";
}

function setMetaContent(name, content) {
  const metaTag = document.querySelector(`meta[name="${name}"]`);
  if (metaTag) {
    metaTag.setAttribute("content", content);
  } else {
    const meta = document.createElement("meta");
    meta.setAttribute("name", name);
    meta.setAttribute("content", content);
    document.head.appendChild(meta);
  }
}
watchEffect(() => {
  if (store.state.media.isDesktop == true) {
    drawerWidth.value = "480px";
  } else {
    drawerWidth.value = "100%";
  }
  if (placeOrderFormData.periods != "") {
    ordinary_san_num.value = purchasePeriod.san_price.ordinary.annually_price;
    ip_san_num.value = purchasePeriod.san_price.ip.annually_price;
    wildcard_san_num.value = purchasePeriod.san_price.wildcard.annually_price;
  }
  let token = getCookies("token");
  if (token && isShowDrawer.value == true) {
    calculateMin();
  }
});
defineExpose({
  placeOrderForm: proxy.$refs.placeOrderForm,
});
onBeforeUnmount(() => {
  document.title = metaTags.title;
  setMetaContent("keywords", metaTags.keywords);
  setMetaContent("description", metaTags.description);
});
defineOptions({
  name: "ProductCard",
});
</script>

<style lang="less" scoped>
.productCard-Pc {
  .paneItemBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    .pane-item {
      min-width: 300px;
      position: relative;
      text-align: center;
      background-color: #fff;
      // margin-right: 25px;
      margin-bottom: 10px;
      border-radius: 10px;
      overflow: hidden;

      cursor: pointer;
      .brand_image {
        width: 185px;
        height: 32px;
        margin: 20px auto 0;
        // padding: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .store_name {
        color: #0b1e2e;
        height: 40px;
        line-height: 40px;
        font-weight: 800;
        min-width: 270px;
      }
      .storeBox {
        padding: 15px 0 0;
        border-top: 1px solid #edf2f5;
        border-bottom: 1px solid #edf2f5;
        height: 214px;
        margin: 0 15px;
        .box-item-many {
          display: flex;
          flex-direction: row;
          padding: 0 10px 10px;
          justify-content: flex-start;
        }
        .box-item-less {
          display: flex;
          flex-direction: row;
          padding: 0 10px 20px;
          justify-content: flex-start;
        }
        .box-label {
          width: 100px;
          min-width: 100px;
          text-align: right;
          color: #9c9c9c;
        }
        .box-value {
          color: #0b1e2e;
          font-weight: 600;
          width: 138px;
          text-align: left;
        }
      }
      .store-price {
        padding: 15px;
        .annually_price {
          span {
            font-size: 24px;
            // font-weight: 600;
            color: #f50a26;
            margin-right: 4px;
          }
        }
        .market_price {
          font-size: 12px;
          color: #777;
          text-decoration: line-through;
        }
      }
      .store-buy {
        width: 100%;
        height: 84px;
        line-height: 84px;
        font-size: 22px;
        background-color: #0178f9;
        color: #ffffff;
        z-index: 99;
        text-align: center;
        cursor: pointer;
        position: absolute;
        left: 0;
        border: 0;
        bottom: 0px;
      }
    }
  }

  .emptyBox {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 42px;
    color: #ccc;
  }
}
.periods {
  display: flex;
  flex-direction: row;
  .priceBox {
    display: flex;
    flex-direction: row;
    width: 60%;
    margin-left: 15px;
    justify-content: space-between;
  }
}
.first {
  justify-content: space-between;
}
.drawer-footer {
  margin-top: 120px;
  .sum {
    text-align: right;
    margin-bottom: 10px;
    color: #000;
    span {
      font-size: 30px;
    }
  }
}
// -------------------兼容----------
.productCard-notPc {
  .paneItemBox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 25px;
  }
  .pane-item {
    flex: 1;
    // padding: 20px 10px;
    position: relative;
    text-align: center;
    background-color: #fff;
    margin-bottom: 15px;
    border-radius: 10px;
    min-width: fit-content;
    overflow: hidden;
    cursor: pointer;
    .brand_image {
      width: 185px;
      height: 32px;
      margin: 20px auto 0;

      // padding: 10px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .store_name {
      color: #0b1e2e;
      height: 40px;
      line-height: 40px;
      font-weight: 800;
      min-width: 270px;
    }
    .storeBox {
      padding: 15px 0 0;
      border-top: 1px solid #edf2f5;
      border-bottom: 1px solid #edf2f5;

      margin: 0 15px;
      .box-item-many {
        display: flex;
        flex-direction: row;
        padding: 0 10px 10px;
        justify-content: flex-start;
      }
      .box-item-less {
        display: flex;
        flex-direction: row;
        padding: 0 10px 20px;
        justify-content: flex-start;
      }
      .box-label {
        width: 100px;
        min-width: 100px;
        text-align: right;
        color: #9c9c9c;
      }
      .box-value {
        color: #0b1e2e;
        font-weight: 600;
      }
    }
    .store-price {
      padding: 15px;
      .annually_price {
        span {
          font-size: 24px;
          // font-weight: 600;
          color: #f50a26;
          margin-right: 4px;
        }
      }
      .market_price {
        font-size: 12px;
        color: #777;
        text-decoration: line-through;
      }
    }
    .store-buy {
      width: 100%;
      height: 84px;
      line-height: 84px;
      font-size: 22px;
      background-color: #0178f9;
      color: #ffffff;
      z-index: 99;
      text-align: center;
      cursor: pointer;
      position: absolute;
      left: 0;
      border: 0;
      bottom: 0px;
    }
  }
  .emptyBox {
    width: 100%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #ccc;
  }
}
:deep(.ivu-input-disabled) {
  color: #000 !important;
}
:deep(.ivu-icon-ios-add:before),
:deep(.ivu-icon-ios-remove:before) {
  font-weight: 600 !important;
}
:deep(.ivu-drawer-header) {
  padding: 24px 16px !important;
}
:deep(.ivu-input-number-controls-outside .ivu-input-number-input) {
  text-align: center;
}
</style>
