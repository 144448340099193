<template>
  <div id="app">
    <global-layout></global-layout>
  </div>
</template>

<script>
import { on, off } from "view-ui-plus/src/utils/dom";
import { setMatchMedia } from "view-ui-plus/src/utils/assist";
import { mapMutations } from "vuex";
import GlobalLayout from '@/components/layout.vue';
setMatchMedia();
export default {
  components: { GlobalLayout },
  name: "App",
  methods: {
    ...mapMutations("media", ["setDevice"]),
    handleWindowResize() {
      this.handleMatchMedia();
    },
    handleMatchMedia() {
      const matchMedia = window.matchMedia;

      if (matchMedia("(max-width: 600px)").matches) {
        var deviceWidth = document.documentElement.clientWidth || window.innerWidth;
        let css = "calc(100vw/7.5)";
        document.documentElement.style.fontSize = css;
        this.setDevice("Mobile");
      } else if (matchMedia("(max-width: 992px)").matches) {
        this.setDevice("Tablet");
      } else {
        this.setDevice("Desktop");
      }
    },
  },
  mounted() {
    on(window, "resize", this.handleWindowResize);
    this.handleMatchMedia();
  },
  beforeDestroy() {
    off(window, "resize", this.handleWindowResize);
  },
};
</script>

<style lang="less">
body{
  font-family: SourceHanSansSC-regular;
}
.ivu-layout-header {
  height: atuo !important;
  line-height: 1.5 !important;
}
.ivu-message {
  z-index: 9999 !important; /* 更改这个值来调整 Message 的层级 */
}
</style>
