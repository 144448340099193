<template>
  <div
    class="certDetail"
    :class="$store.state.media.isDesktop == true ? 'certDetail-Pc' : 'certDetail-notPc'"
  >
    <div class="routerBox">
      <Breadcrumb separator=">">
        <BreadcrumbItem key="1" to="/user" class="breadItem"> 个人中心 </BreadcrumbItem>
        <BreadcrumbItem key="2" to="/cert" class="breadItem"> 我的证书 </BreadcrumbItem>
        <BreadcrumbItem key="3" to="/certDetail" class="breadItem">
          证书详情
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="process">
      <div class="section-hd">状态：{{ certData.cert.status_text }}</div>
      <div class="section-bd tab-box">
        <Tabs v-model="activeTabs" @on-click="tabsClick">
          <TabPane label="基本信息" name="1">
            <div class="processDetail">
              <div class="detail-title">基本信息</div>
              <div class="detail-content">
                <div class="content-item">
                  <div class="item-label">CA订单编号：</div>
                  <div>{{ certData.cert.ca_id }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">订单编号：</div>
                  <div>{{ certData.order_id }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">产品名称：</div>
                  <div>{{ certData.product.store_name }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">附加域名：</div>
                  <div>{{ certData.san }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">购买时长：</div>
                  <div>{{ certData.periods_text }}</div>
                </div>
                <div class="content-item">
                  <div class="item-label">订单价格：</div>
                  <div>
                    <span class="order_price">{{ certData.pay_price }}元</span>
                  </div>
                  <Button
                    style="margin-left: 15px"
                    type="error"
                    v-if="certData.refund_status == 0"
                    @click="applyRefund(certData.order_id)"
                    >申请退款</Button
                  >
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="申请信息" name="2">
            <div class="processDetail">
              <div class="detail-title">申请信息</div>
              <div class="detail-content">
                <div class="applyForm">
                  <Form
                    ref="certDataForm"
                    :model="formItem"
                    :rules="ruleItem"
                    label-position="left"
                    :label-width="100"
                  >
                    <FormItem
                      label="主域名"
                      :prop="certData.product.support_san == 0 ? 'common_name' : 'san'"
                    >
                      <Input
                        v-model="formItem.all_domain"
                        v-if="certData.product.support_san == 0"
                        :placeholder="certData.placeholder.common_effect"
                      ></Input>
                      <Input
                        v-model="formItem.all_domain"
                        type="textarea"
                        v-if="certData.product.support_san == 1"
                        :autosize="{ minRows: 10, maxRows: 15 }"
                        :placeholder="certData.placeholder.san_effect"
                      ></Input>
                    </FormItem>
                    <FormItem label="邮箱" prop="email">
                      <Input
                        v-model="formItem.email"
                        placeholder="请输入联系人 Email"
                      ></Input>
                    </FormItem>
                    <FormItem
                      label="公司"
                      prop="company_id"
                      v-if="
                        certData.product.ssl_type == 2 ||
                        certData.product.validation_level == 1 ||
                        certData.product.validation_level == 2
                      "
                    >
                      <Select
                        v-model="formItem.company_id"
                        placeholder="请选择公司"
                        :clearable="true"
                      >
                        <Option
                          v-for="item in companyList"
                          :value="item.id"
                          :key="item.id"
                          >{{ item.organization }}</Option
                        >
                      </Select>
                      <div class="tips">
                        无公司信息？<a @click="showCompanyDrwaer">点我添加</a>
                      </div>
                    </FormItem>
                    <!-- OV EV 代码签名 需要公司信息 -->
                    <FormItem label="Csr：" prop="csr_type">
                      <div class="csr">
                        <div class="apply">
                          <Select
                            v-model="formItem.csr_type"
                            style="width: 150px"
                            placeholder="请选择"
                          >
                            <Option value="1" label="自动生成"> </Option>
                            <Option value="0" label="粘贴CSR"> </Option>
                          </Select>
                          <Select
                            v-model="formItem.cert_type"
                            style="width: 150px"
                            placeholder="请选择"
                            v-show="formItem.csr_type != 0"
                          >
                            <Option value="0" label="RSA"> </Option>
                            <Option value="3" label="ECDSA"> </Option>
                          </Select>
                          <Select
                            v-model="formItem.hash_algorithms"
                            v-show="formItem.csr_type != 0"
                            style="width: 150px"
                            placeholder="请选择"
                          >
                            <Option value="sha256" label="SHA256"> </Option>
                            <Option value="sha384" label="SHA384"> </Option>
                            <Option value="sha512" label="SHA512"> </Option>
                          </Select>
                          <Select
                            v-model="formItem.keysize"
                            style="width: 150px"
                            v-show="formItem.csr_type != 0"
                            placeholder="请选择"
                          >
                            <Option value="2048" label="2048"> </Option>
                            <Option value="3072" label="3072"> </Option>
                            <Option value="4096" label="4096"> </Option>
                          </Select>
                        </div>
                        <Input
                          v-show="formItem.csr_type == '0'"
                          v-model="formItem.csr"
                          type="textarea"
                          :autosize="{ minRows: 10, maxRows: 15 }"
                          placeholder="请在这里粘贴你的CSR"
                        ></Input>
                        <Button
                          type="primary"
                          v-if="certData.cert.status == 0"
                          class="applyBtn"
                          @click="applyCert"
                          >申请</Button
                        >
                        <div
                          class="btnBox"
                          v-if="
                            certData.cert.status == 1 &&
                            certData.product.support_san == 1 &&
                            certData.cert.place_order_data.san.length > 0
                          "
                        >
                          <Button
                            class="apply"
                            type="primary"
                            @click="showDeleteDomainModal = true"
                            >删除域名</Button
                          >
                          可以删除证书dcv验证未通过域名
                        </div>
                        <div class="btnBox" v-if="certData.cert.status == 2">
                          <Button
                            class="apply"
                            :loading="reissueLoading"
                            type="primary"
                            @click="certSREsueSubmit"
                            >重新颁发</Button
                          >
                          当前证书将在一周内吊销
                        </div>
                      </div>
                    </FormItem>
                  </Form>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="审核信息" name="3">
            <div class="processDetail" v-if="certData.cert.status != 1">
              <div class="detail-title">审核信息</div>
              <div class="detail-content">
                <div class="content-tip">证书状态非审核中</div>
              </div>
            </div>
            <div class="processDetail" v-if="certData.cert.status == 1">
              <div class="detail-title">审核信息</div>
              <div class="detail-top">
                <ButtonGroup>
                  <Button type="primary" :loading="noticeLoading" @click="noticeCACheck"
                    >重新验证</Button
                  >
                  <Dropdown>
                    <Button type="primary">
                      <Icon type="ios-arrow-down"></Icon>
                    </Button>
                    <template #list>
                      <DropdownMenu>
                        <DropdownItem>跟踪状态</DropdownItem>
                      </DropdownMenu>
                    </template>
                  </Dropdown>
                </ButtonGroup>
                <span>
                  请在全部添加完成再验证，否则因为DNS TTL等原因会导致下次验证需要等待更久
                </span>
              </div>
              <div class="detail-content">
                <div class="b-table">
                  <Table
                    :loading="loading"
                    border
                    :disabled-hover="true"
                    :columns="dcv_info"
                    :data="
                      certData.cert.place_order_data
                        ? certData.cert.place_order_data.dcv_info
                        : []
                    "
                  >
                    <template #authType="{ row }">
                      <Select
                        v-model="row.method"
                        style="width: 100%"
                        :transfer="true"
                        @on-change="changeDcv(row)"
                      >
                        <Option value="dns">DNS</Option>
                        <Option value="http">HTTP</Option>
                        <Option value="https">HTTPS</Option>
                        <OptionGroup
                          label="邮箱验证"
                          v-if="row.subdomain && row.subdomain != '*'"
                        >
                          <Option :value="'admin@' + row.domain"
                            >admin@{{ row.domain }}</Option
                          >
                          <Option :value="'administrator@' + row.domain"
                            >administrator@{{ row.domain }}</Option
                          >
                          <Option :value="'hostmaster@' + row.domain"
                            >hostmaster@{{ row.domain }}</Option
                          >
                          <Option :value="'postmaster@' + row.domain"
                            >postmaster@{{ row.domain }}</Option
                          >
                          <Option :value="'webmaster@' + row.domain"
                            >webmaster@{{ row.domain }}</Option
                          >

                          <Option :value="'admin@' + row.topdomain"
                            >admin@{{ row.topdomain }}</Option
                          >
                          <Option :value="'administrator@' + row.topdomain"
                            >administrator@{{ row.topdomain }}</Option
                          >
                          <Option :value="'hostmaster@' + row.topdomain"
                            >hostmaster@{{ row.topdomain }}</Option
                          >
                          <Option :value="'postmaster@' + row.topdomain"
                            >postmaster@{{ row.topdomain }}</Option
                          >
                          <Option :value="'webmaster@' + row.topdomain"
                            >webmaster@{{ row.topdomain }}</Option
                          >
                        </OptionGroup>
                        <OptionGroup label="邮箱验证" v-else>
                          <Option :value="'admin@' + row.topdomain"
                            >admin@{{ row.topdomain }}</Option
                          >
                          <Option :value="'administrator@' + row.topdomain"
                            >administrator@{{ row.topdomain }}</Option
                          >
                          <Option :value="'hostmaster@' + row.topdomain"
                            >hostmaster@{{ row.topdomain }}</Option
                          >
                          <Option :value="'postmaster@' + row.topdomain"
                            >postmaster@{{ row.topdomain }}</Option
                          >
                          <Option :value="'webmaster@' + row.topdomain"
                            >webmaster@{{ row.topdomain }}</Option
                          >
                        </OptionGroup>
                      </Select>
                    </template>

                    <template #dcvExplain="{ row }">
                      <div class="dcv" v-if="row.method == 'dns'">
                        <div class="dcv-content">
                          <span class="copy-title">请添加主机名</span>
                          （<span class="copy" @click="copy(row.dns_host)">复制</span>）
                          <div class="dns-host">
                            <div class="textBox" v-if="row.subdomain">
                              {{ row.dns_host }}
                            </div>
                            <div class="textBox" v-else>{{ row.dns_host }}</div>
                          </div>
                        </div>
                        <div class="dcv-content">
                          <span class="copy-title">类型</span>
                          <div class="dns-host">
                            <div class="textBox">{{ row.dns_type }}</div>
                          </div>
                        </div>
                        <div class="dcv-content dcv-foot">
                          <span class="copy-title">值</span>（<span
                            class="copy"
                            @click="copy(row.dns_value)"
                            >复制</span
                          >）
                          <div class="dns-host">
                            <div class="textBox">{{ row.dns_value }}</div>
                          </div>
                          <span class="copy-title">的DNS记录</span>
                        </div>
                      </div>

                      <div
                        class="dcv"
                        v-else-if="row.method == 'http' || row.method == 'https'"
                      >
                        <div class="dcv-content">
                          <span class="copy-title">请创建</span>（<span
                            class="copy"
                            @click="copy(row[row.method + '_filename'])"
                            >复制</span
                          >）
                          <div class="dns-host">
                            <div class="textBox">{{ row[row.method + "_filename"] }}</div>
                          </div>
                        </div>
                        <div class="dcv-content">
                          <span class="copy-title">内容为</span>（<span
                            class="copy"
                            @click="copy(row[row.method + '_filecontent'])"
                            >复制</span
                          >）
                          <div class="dns-host">
                            <div class="textBox">
                              {{ row[row.method + "_filecontent"] }}
                            </div>
                          </div>
                        </div>
                        <div class="dcv-content dcv-foot">
                          <span class="copy-title">上传到</span>
                          <div class="dns-host">
                            <div class="textBox">
                              {{ row[row.method + "_verifylink"] }}
                            </div>
                          </div>
                          <span class="copy-title">(上传后务必自测是否可访问)</span>
                        </div>
                      </div>
                      <div class="dcv" v-else>
                        <div class="dcv-content dcv-foot">
                          <span class="copy-title">请进入</span>
                          <div class="dns-host">
                            <div class="textBox">{{ row.method }}</div>
                          </div>
                          <span class="copy-title">邮箱收信</span>
                        </div>
                      </div>
                    </template>

                    <template #authStatus="{ row }">
                      <div class="dcv">
                        <div class="dcv-content">
                          <span class="copy-title">{{ row.status }}</span>
                        </div>
                        <div class="dcv-content">
                          <Button @click="checkDcv(row)">检查结果</Button>
                        </div>
                      </div>
                    </template>
                  </Table>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane label="签发信息" name="4">
            <div class="processDetail">
              <div class="detail-title">签发信息</div>
              <div class="detail-content" v-if="certData.cert.status != 2">
                <span
                  >证书未签发，请查看<span @click="tabsClick('3')">审核状态</span></span
                >
              </div>
              <div class="detail-content" v-if="certData.cert.status == 2">
                <div class="toolBox">
                  证书类型：
                  <RadioGroup
                    type="button"
                    button-style="solid"
                    v-model="certSignData.key_type"
                  >
                    <Radio label="0">RSA</Radio>
                    <Radio label="3">EC</Radio>
                  </RadioGroup>
                </div>
                <div class="toolBox">
                  <div class="item-title">下载、转换</div>
                  <div class="item-ctrlBtn">
                    <Button type="default" @click="exoprtCert('cert')">签发证书</Button>
                    <Button type="default" @click="exoprtCert('issuer')">中间证书</Button>
                    <Button type="default" @click="exoprtCert('both')"
                      >签发证书+中间证书</Button
                    >
                    <Button type="default" @click="showModal"
                      >导出IlS(pfx格式)证书</Button
                    >
                  </div>
                </div>
                <div class="toolBox">
                  <div class="item-title">签发证书</div>
                  <div class="item-ctrlBtn">
                    <pre class="buildPfx" v-if="certSignData.key_type == 0">{{
                      certData.cert.cert_ificate
                    }}</pre>
                    <pre class="buildPfx" v-if="certSignData.key_type == 3">{{
                      certData.cert.ec_cert_ificate
                    }}</pre>
                  </div>
                </div>
                <div class="toolBox">
                  <div class="item-title">中间证书</div>
                  <div class="item-ctrlBtn">
                    <!-- <div class="buildPfx" v-html='certData.cert.cert_ificate'></div> -->
                    <pre class="buildPfx" v-if="certSignData.key_type == 0">{{
                      certData.cert.ca_cert_ificate
                    }}</pre>
                    <pre class="buildPfx" v-if="certSignData.key_type == 3">{{
                      certData.cert.ec_ca_cert_ificate
                    }}</pre>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <Modal
            v-model="showExoprtCertModal"
            :closable="true"
            :mask-closable="false"
            :loading="true"
            title="导出IIS证书"
          >
            <Form
              class="form"
              ref="chargeForm"
              :model="chargeData"
              :rules="ruleValidate"
              label-width="100"
              :label-position="labelPosition"
            >
              <Row :gutter="24" type="flex">
                <Col span="24">
                  <FormItem label="PFX密码" prop="amount">
                    <Input
                      type="text"
                      v-model="pfxPwd"
                      placeholder="0.01~10000"
                      size="large"
                    />
                  </FormItem>
                </Col>
              </Row>
            </Form>
            <template #footer>
              <div class="footerBtn">
                <Button type="primary" size="large" @click="exportIISCert('zip')"
                  >转换PFX格式, 并打包 ZIP</Button
                >
                <Button type="success" size="large" @click="exportIISCert('')"
                  >转换PFX格式
                </Button>
                <Button type="primary" size="large" @click="showExoprtCertModal = false"
                  >取消</Button
                >
              </div>
            </template>
          </Modal>
          <Modal
            v-model="showDeleteDomainModal"
            :closable="true"
            :mask-closable="false"
            :loading="true"
            title="域名删除"
          >
            <div class="delDomain">
              <div
                class="domainList"
                v-for="(ite, domInex) in certData.cert.place_order_data.san"
                :key="domInex"
              >
                <div class="value">
                  {{ ite }}
                </div>
                <Icon
                  type="md-close"
                  size="32"
                  class="delIcon"
                  @click="delDomain(ite, domInex)"
                />
              </div>
            </div>
            <template #footer>
              <div class="footerBtn">
                <Button type="primary" size="large" @click="showDeleteDomainModal = false"
                  >取消</Button
                >
              </div>
            </template>
          </Modal>
          <companyDetailDrawer
            @refresh="reFreshCompanyList"
            ref="companyDetailRef"
          ></companyDetailDrawer>
        </Tabs>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  certApplyApi,
  checkDcvApi,
  changeDcvApi,
  noticeCACheckApi,
  companyApi,
  userInfoApi,
} from "@/api/personalCenter";
import {
  certDetailApi,
  certExportApi,
  iisCertDownApi,
  domainsDeleteApi,
  certSREsueApi,
} from "@/api/cert";
import { applyRefundApi } from "@/api/order";

import {
  ref,
  reactive,
  watchEffect,
  onMounted,
  defineProps,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { Message, Modal, Copy } from "view-ui-plus";
import CompanyDetailDrawer from "@/components/companyDetailDrawer";

const { proxy } = getCurrentInstance(); // 获取组件实例

let router = useRouter();
let route = useRoute();
let activeTabs = ref(1);
let showExoprtCertModal = ref(false);
let showDeleteDomainModal = ref(false);
let companyDetailRef = ref(null);
let pfxPwd = ref("");
const order_id = route.query.id;
const certData = reactive({
  add_time: "",
  pay_time: "",
  product: {
    store_name: "",
    support_san: 0,
    default_protect_domain: 0,
    max_domain: 0,
    ssl_free: 0,
  },
  placeholder: { common_effect: "" },
  cert: {
    ca_id: "",
    add_time: "",
    place_order_data: {
      dcv_info: [],
    },
  },
  refund_reason_time: "",
});
// 申请资料表单
let formItem = reactive({
  order_id: "",
  common_name: "",
  san: "",
  email: "",
  cert_type: "",
  csr_type: "1",
  csr: "",
  company_id: "",
});
// 申请资料表单验证
const ruleItem = reactive({
  common_name: [{ required: true, message: "主域名不能为空", trigger: "blur" }],
  email: [
    { required: true, message: "联系人 Email 不能为空", trigger: "blur" },
    { type: "email", message: "请输入正确的 Email", trigger: "blur" },
  ],
  company_id: [
    { required: true, type: "number", message: "请选择公司", trigger: "change" },
  ],
});
const dcv_info = reactive([
  {
    title: "FQDN",
    key: "domain",
    minWidth: 150,
  },
  {
    title: "验证类型",
    slot: "authType",
    minWidth: 80,
  },
  {
    title: "说明",
    slot: "dcvExplain",
    minWidth: 600,
  },
  {
    title: "验证状态",
    slot: "authStatus",
    minWidth: 100,
  },
]);
const buildCert = ref("");
let applyLoading = ref(false);
let loading = ref(false);
let noticeLoading = ref(false);
let companyList = reactive([]);
let certSignData = reactive({
  cert_id: "",
  key_type: "",
  type: "",
});
let reissueLoading = ref(false);
// ----- --接口请求----------
const certDetail = (data) => {
  certDetailApi(data).then((res) => {
    if (res.status == 200) {
      Object.assign(certData, res.data.info);
      if (certData.cert == null) {
        certData.cert = {
          ca_id: "",
          add_time: "",
        };
      } else {
        Object.assign(formItem, certData.cert.place_order_data);
        formItem.csr_type = formItem.csr_type.toString();
        certSignData.key_type = certData.cert.key_type.toString();
        certSignData.cert_id = certData.cert.id;
      }
      if (route.query.all_domain && certData.status_current == 4) {
        formItem.all_domain = route.query.all_domain;
        // console.log(formItem.all_domain);
        activeTabs.value = "2";
      } else {
        activeTabs.value =
          certData.status_current != 0 ? certData.status_current.toString() : "1";
      }
    }
  });
};
function getCompanyList(data) {
  companyApi(data).then((res) => {
    if (res.status === 200) {
      //   console.log(res.data.list);
      companyList.length = 0; // 清空数组
      companyList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
// -------操作按钮----------

const tabsClick = (e) => {
  // console.log(e);
  activeTabs.value = e;
};
// 申请退款
const applyRefund = (id) => {
  Modal.confirm({
    title: "退款确认",
    content:
      "您的订单将退款, 已签发的证书将在1到7日内吊销不能使用,资金将于7日内到达您的余额账户, 是否继续？",
    loading: true,
    onOk() {
      applyRefundApi({ order_id: id })
        .then((res) => {
          Message.success("退款成功");
          setTimeout(() => {
            Modal.remove();
            certDetail({ id: id });
          }, 200);
        })
        .catch((res) => {
          Modal.remove();
          Message.error(res.msg);
        });
    },
  });
};
// 添加公司提示
const showCompanyDrwaer = () => {
  companyDetailRef.value.addCompany();
};

// 删除域名
const delDomain = (domain, index) => {
  Modal.confirm({
    title: "删除确认",
    content: `您将删除域名：“${domain}”, 是否继续？`,
    loading: true,
    onOk() {
      domainsDeleteApi({ order_id: order_id, domain_name: domain })
        .then((res) => {
          Message.success("删除成功");
          setTimeout(() => {
            Modal.remove();
            certData.cert.place_order_data.san.splice(index, 1);
            certDetail({ id: order_id });
            activeTabs.value = "2";
          }, 200);
        })
        .catch((res) => {
          Modal.remove();
          Message.error(res.msg);
        });
    },
  });
};
// 刷新公司列表
const reFreshCompanyList = () => {
  getCompanyList();
};
// 去申请 按钮
const apply = () => {
  activeTabs.value = "2";
};
// 提交申请
const applyCert = () => {
  proxy.$refs.certDataForm.validate((valid) => {
    if (valid) {
      certApplyApi(formItem)
        .then((res) => {
          applyLoading.value = true;
          if (res.status == 200) {
            Message.success("申请成功");
            certDetail({ id: order_id });
            activeTabs.value = 3;
            applyLoading.value = false;
          }
        })
        .catch((err) => {
          Message.error(err.msg);
          applyLoading.value = false;
        });
    } else {
      Message.error(res.msg);
    }
  });
};
// 重签申请接口请求
const certSREsue = (data) => {
  certSREsueApi(data)
    .then((res) => {
      reissueLoading.value = true;
      // console.log(res);
      if (res.status == 200) {
        Message.success("申请成功");
        certDetail({ id: order_id });
        activeTabs.value = 3;
        reissueLoading.value = false;
      } else if (res.status == 300) {
        Modal.confirm({
          title: "订单超额提示",
          content: res.msg,
          loading: true,
          onOk() {
            Modal.remove();
            router.push({
              path: "/sanPayment",
              query: {
                order_id: order_id,
                san_order_id: res.data.order_id,
                all_domain: formItem.all_domain,
              },
            });
          },
          onCancel() {
            Modal.remove();
            reissueLoading.value = false;
          },
        });
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
// 重签申请按钮
const certSREsueSubmit = () => {
  proxy.$refs.certDataForm.validate((valid) => {
    if (valid) {
      let forms = {
        order_id: formItem.order_id,
        common_name: formItem.all_domain,
        email: formItem.email,
        cert_type: formItem.cert_type,
        csr_type: Number(formItem.csr_type),
        csr: formItem.csr,
        hash_algorithms: formItem.hash_algorithms,
        keysize: formItem.keysize.toString(),
        company_id: formItem.company_id,
      };
      certSREsue(forms);
    } else {
      Message.error(res.msg);
    }
  });
};
// 检查Dcv
const checkDcv = (row) => {
  let data = {
    order_id: order_id,
    domain: row.domain,
    type: row.method,
  };
  checkDcvApi(data)
    .then((res) => {
      row.status = "验证成功";
      Message.success("验证成功");
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 切换验证方式
const changeDcv = (row) => {
  let data = {
    order_id: order_id,
    domain: row.domain,
    type: row.method,
  };
  loading.value = true;
  changeDcvApi(data)
    .then((res) => {
      loading.value = false;
      Message.success("切换成功");
      certDetail({ id: order_id });
    })
    .catch((res) => {
      loading.value = false;
      Message.error(res.msg);
    });
};
const noticeCACheck = () => {
  noticeCACheckApi({ id: order_id })
    .then((res) => {
      noticeLoading.value = false;
      Message.success(res.msg || "已通知CA检查");
    })
    .catch((res) => {
      noticeLoading.value = false;
      Message.error(res.msg);
    });
};
// 立刻付款按钮
const goPay = (id) => {
  router.push({
    path: "/payment",
    query: {
      order_id: id,
    },
  });
};
// 导出证书
const exoprtCert = (type) => {
  certSignData.type = type;
  certExportApi(certSignData)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.path != "") {
          window.location.href = `https://h.ssl-zs.com/api/download?path=${res.data.path}`;
        }
        Message.success("下载成功");
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
// 展示弹窗
const showModal = () => {
  userInfoApi().then((res) => {
    if (res.status == 200) {
      pfxPwd.value = res.data.info.pfx_password;
    }
  });
  showExoprtCertModal.value = true;
};
// 导出IIS证书
const exportIISCert = (type) => {
  let data = {
    id: order_id,
    key_type: certSignData.key_type,
    action: type,
  };
  if (certData.cert.place_order_data.csr_type == "0") {
    data.key = cert.place_order_data.csr;
  }
  iisCertDownApi(data)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.path != "") {
          window.location.href = `https://h.ssl-zs.com/api/download?path=${res.data.path}`;
        }
        Message.success("下载成功");
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
const copy = (v) => {
  Copy({
    text: v,
  });
};
onMounted(() => {
  certDetail({ id: order_id });

  getCompanyList();
});
defineExpose({
  certDataForm: proxy.$refs.certDataForm,
});
</script>

<style lang="less" scoped>
.certDetail-Pc {
  padding: 25px 5% 40px;

  .routerBox {
    margin-bottom: 15px;
  }

  .process {
    background-color: #fff;
    padding: 20px 40px;
    margin-bottom: 50px;

    .section-hd {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
    }
  }

  .processDetail {
    background-color: #fff;

    .detail-title {
      padding: 26px 15px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .detail-top {
      padding: 26px 15px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      span {
        margin: 0 10px;
      }
    }

    .detail-content {
      padding: 37px 15px;
      font-size: 16px;
      border-top: 1px dashed #cecece;
      border-bottom: 1px dashed #cecece;

      .content-item {
        display: flex;
        flex-direction: row;
        color: #000;
        margin-bottom: 15px;
        align-items: center;
        .item-label {
          min-width: 70px;
        }

        .order_price {
          color: red;
        }

        // font-family: SourceHanSansSC-regular;
      }

      .b-table {
        .dcv-content {
          margin-top: 10px;

          .copy-title {
            font-weight: 700;
          }

          .copy {
            color: #337ab7;
            cursor: pointer;
          }

          .dns-host {
            .textBox {
              // margin: 14px 0;
              padding: 14px 10px;
              border-radius: 8px;
              background-color: #f5f5f5;
              border: 1px solid #cecece;
            }
          }
        }
      }

      .toolBox {
        color: #000;
        margin-bottom: 15px;

        .item-title {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .item-ctrlBtn {
          button {
            margin-right: 5px;
          }
        }

        .buildPfx {
          width: 100%;
          height: 450px;
          overflow: scroll;
          background-color: #f5f5f5;
          border: 1px solid #cecece;
          padding: 14px 10px;
          border-radius: 5px;
        }
      }
    }

    .detail-footer {
      padding: 35px 40px;
      text-align: right;

      button {
        height: 40px;
        width: 120px;
      }
    }
  }

  .apply {
    .ivu-select {
      margin-right: 25px;
      margin-bottom: 15px;
    }

    .applyBtn {
      margin-top: 15px;
    }
  }

  :deep(.ivu-tabs-nav) {
    font-size: 16px;
  }
  .btnBox {
    margin-top: 20px;
  }
}

.certDetail-notPc {
  padding: 25px 5% 20px;

  .routerBox {
    margin-bottom: 15px;
  }

  .process {
    background-color: #fff;
    padding: 20px 20px;
    margin-bottom: 25px;

    .section-hd {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
    }
  }
  .processDetail {
    background-color: #fff;

    .detail-title {
      padding: 26px 15px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .detail-top {
      padding: 26px 15px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      span {
        margin: 0 10px;
      }
    }

    .detail-content {
      padding: 10px 5px;

      border-top: 1px dashed #cecece;
      border-bottom: 1px dashed #cecece;

      .content-item {
        display: flex;
        flex-direction: row;
        color: #000;
        margin-bottom: 15px;

        .item-label {
          min-width: 70px;
        }

        .order_price {
          color: red;
        }

        // font-family: SourceHanSansSC-regular;
      }

      .b-table {
        .dcv-content {
          margin-top: 10px;

          .copy-title {
            font-weight: 700;
          }

          .copy {
            color: #337ab7;
            cursor: pointer;
          }

          .dns-host {
            .textBox {
              // margin: 14px 0;
              padding: 14px 10px;
              border-radius: 8px;
              background-color: #f5f5f5;
              border: 1px solid #cecece;
            }
          }
        }
      }

      .toolBox {
        color: #000;
        margin-bottom: 15px;

        .item-title {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .item-ctrlBtn {
          button {
            margin-right: 5px;
          }
        }

        .buildPfx {
          width: 100%;
          height: 450px;
          overflow: scroll;
          background-color: #f5f5f5;
          border: 1px solid #cecece;
          padding: 14px 10px;
          border-radius: 5px;
        }
      }
    }
  }
}
.domainList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;
  .value {
    margin-right: 30px;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    min-width: 160px;
  }
  .delIcon {
    cursor: pointer;
    color: red;
  }
}
</style>
