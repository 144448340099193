// 导入axios
import axios from "axios";
import { Message } from "view-ui-plus";
import { getCookies, removeCookies } from "@/libs/util";
import router from "@/router";
import { useLoginStore } from "@/store/loginStatus";
import { storeToRefs } from "pinia";

const request = axios.create({
  // baseURL 将自动加在 url`前面，除非 url 是一个绝对 URL。
  // 它可以通过设置一个 baseURL 便于为 axios 实例的方法传递相对 URL
  baseURL: process.env.VUE_APP_API_URL,
  // timeout设置一个请求超时时间，如果请求时间超过了timeout，请求将被中断，单位为毫秒（ms）
  timeout: 10000,
});
axios.defaults.withCredentials = true; // 携带cookie

// 请求拦截器
request.interceptors.request.use(
  (config) => {
    // 在请求发送之前可以做一些处理，比如添加请求头等
    const token = getCookies("token");
    if (token) {
      config.headers["Authori-zation"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 响应拦截器
request.interceptors.response.use(
  (response) => {
    // console.log(response);
    let status = response.data ? response.data.status : 0;
    const code = status;
    switch (code) {
      case 200:
        return response.data;
      case 300:
        return response.data;
      case 400:
      case 400011:
      case 400012:
        return Promise.reject(response.data || { msg: "未知错误" });
      case 410000:
        removeCookies("token");
        Message.error(response.msg || "请先登录！");
        router.replace({ path: "/" });
        const loginStore = useLoginStore();
        const { isLoggedIn, isModalVisible } = storeToRefs(loginStore);
        isLoggedIn.value = false;
        isModalVisible.value = 1;
      // console.log(isModalVisible.value);
      case 410001:
      case 410002:
        localStorage.clear();
        removeCookies("token");
        Message.error(response.msg);
        router.replace({ path: "/" });
        break;
      default:
        break;
    }
  },
  (error) => {
    // 响应错误处理
    // console.log(error);
    Message.error(error);
    return Promise.reject(error);
  }
);

export default request;
