<template>
  <Layout>
    <Header>
      <home-menu></home-menu>
    </Header>
    <Content>
      <router-view :key="route.fullPath" />
    </Content>
    <global-footer></global-footer>
  </Layout>
</template>

<script setup>
import HomeMenu from "@/components/home-menu";
import GlobalFooter from "@/components/global-footer";
// export default {
//   name: "GlobalLayout",
//   components: {
//     HomeMenu,
//     GlobalFooter,
//   },
//   methods: {},
// };


import { useRoute } from 'vue-router'
    const route = useRoute()
</script>

<style lang="less" scoped>
.layout {
  border: 1px solid #d7dde4;
  background: #f5f7f9;
  position: relative;
  border-radius: 4px;
  overflow: hidden;
}
.ivu-layout-header {
  padding: 0px;
}

.layout-footer-center {
  text-align: center;
}
.dev-run-preview .dev-run-preview-edit {
  display: none;
}
.ivu-layout-header {
  background-color: #fff;
}

/deep/ .ivu-menu-horizontal {
  height: 66px !important;
}

.ivu-menu-horizontal.ivu-menu-light:after {
  display: none;
}
</style>
