<template>
  <div
    class="orderDetail"
    :class="$store.state.media.isDesktop == true ? 'orderDetail-Pc' : 'orderDetail-notPc'"
  >
    <div class="routerBox">
      <Breadcrumb separator=">">
        <BreadcrumbItem key="1" to="/user" class="breadItem"> 个人中心 </BreadcrumbItem>
        <BreadcrumbItem key="2" to="/order" class="breadItem"> 我的订单 </BreadcrumbItem>
        <BreadcrumbItem key="3" to="/orderDetail" class="breadItem">
          订单详情
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
    <div class="process">
      <div class="section-hd">状态：{{ orderData.status_txt }}</div>
      <!-- <div class="section-bd" v-if="orderData.status_attr == -2">
        <Steps
          current="4"
          status="error"
          :direction="$store.state.media.isDesktop == true ? 'horizontal' : 'vertical'"
        >
          <Step title="待付款" :content="orderData.add_time"></Step>
          <Step title="已支付" :content="orderData.pay_time"></Step>
          <Step title="申请资料" :content="orderData.cert.add_time"></Step>
          <Step title="已退款" :content="orderData.refund_reason_time"></Step>
        </Steps>
      </div> -->
      <div class="section-bd">
        <Steps
          :current="current"
          :direction="$store.state.media.isDesktop == true ? 'horizontal' : 'vertical'"
        >
          <Step title="待付款" :content="orderData.add_time"></Step>
          <Step title="已支付" :content="orderData.pay_time"></Step>
          <Step title="申请资料" :content="orderData.cert.add_time"></Step>
          <Step title="审核信息" content=""></Step>
          <Step title="签发信息" content=""></Step>
        </Steps>
      </div>
    </div>
    <!-- 订单信息 -->
    <div class="processDetail" v-if="current === 0 || current === 1">
      <div class="detail-title">订单信息</div>
      <div class="detail-content">
        <div class="content-item">
          <div class="item-label">订单编号：</div>
          <div>{{ orderData.order_id }}</div>
        </div>
        <div class="content-item">
          <div class="item-label">产品名称：</div>
          <div>{{ orderData.product.store_name }}</div>
        </div>

        <div class="content-item">
          <div class="item-label">附加域名：</div>
          <div>{{ orderData.san }}</div>
        </div>
        <div class="content-item">
          <div class="item-label">购买时长：</div>
          <div>{{ orderData.periods_text }}</div>
        </div>
        <div class="content-item">
          <div class="item-label">订单价格：</div>
          <div>
            <span class="order_price">{{ orderData.pay_price }}元</span>
          </div>
        </div>
      </div>
      <div class="detail-footer">
        <div class="footer-btn acea-row row-right">
          <Button
            class="pay"
            type="error"
            v-if="current === 0 && orderData.status_attr === 0"
            @click="goPay(orderData.order_id)"
            >立即付款</Button
          >
          <Button class="apply" type="primary" @click="apply" v-if="current === 1"
            >去申请</Button
          >
        </div>
      </div>
    </div>
    <!-- 申请资料 -->
    <div class="processDetail" v-if="current === 2">
      <div class="detail-title">申请资料</div>
      <div class="detail-content">
        <div class="applyForm">
          <Form
            ref="applyCertForm"
            :model="formItem"
            :rules="ruleItem"
            label-position="left"
            :label-width="100"
          >
            <FormItem
              label="主域名"
              prop="common_name"
              v-if="orderData.product.ssl_type != 2"
            >
              <Input
                v-model="formItem.common_name"
                type="textarea"
                :autosize="{ minRows: 10, maxRows: 15 }"
                :placeholder="orderData.placeholder.san_effect"
              ></Input>
            </FormItem>
            <FormItem label="邮箱" prop="email">
              <Input v-model="formItem.email" placeholder="请输入联系人 Email"></Input>
            </FormItem>
            <!-- OV EV 代码签名 需要公司信息 -->
            <FormItem
              label="公司"
              prop="company_id"
              v-if="
                orderData.product.ssl_type == 2 ||
                orderData.product.validation_level == 1 ||
                orderData.product.validation_level == 2
              "
            >
              <Select
                v-model="formItem.company_id"
                placeholder="请选择公司"
                :clearable="true"
              >
                <Option v-for="item in companyList" :value="item.id" :key="item.id">{{
                  item.organization
                }}</Option>
              </Select>
              <div class="tips">
                无公司信息？<a @click="showCompanyDrwaer">点我添加</a>
              </div>
            </FormItem>
            <FormItem label="Csr：" prop="csr_type">
              <div class="csr">
                <div class="apply">
                  <Select
                    v-model="formItem.csr_type"
                    style="width: 150px"
                    placeholder="请选择"
                  >
                    <Option value="1" label="自动生成"> </Option>
                    <Option value="0" label="粘贴CSR"> </Option>
                  </Select>
                  <Select
                    v-model="formItem.cert_type"
                    style="width: 150px"
                    placeholder="请选择"
                    v-show="formItem.csr_type != 0"
                  >
                    <Option value="0" label="RSA"> </Option>
                    <Option value="3" label="ECDSA"> </Option>
                  </Select>
                  <Select
                    v-model="formItem.hash_algorithms"
                    v-if="formItem.csr_type != 0"
                    style="width: 150px"
                    placeholder="请选择"
                  >
                    <Option value="sha256" label="SHA256"> </Option>
                    <Option value="sha384" label="SHA384"> </Option>
                    <Option value="sha512" label="SHA512"> </Option>
                  </Select>
                  <Select
                    v-model="formItem.keysize"
                    style="width: 150px"
                    v-show="formItem.csr_type != 0"
                    placeholder="请选择"
                  >
                    <Option
                      v-for="ks in keysizeList"
                      :key="ks.label"
                      :value="ks.value"
                      :label="ks.label"
                    >
                    </Option>
                  </Select>
                </div>
                <Input
                  v-if="formItem.csr_type == 0"
                  v-model="formItem.csr"
                  type="textarea"
                  :autosize="{ minRows: 10, maxRows: 15 }"
                  placeholder="请在这里粘贴你的CSR"
                ></Input>
              </div>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="detail-footer">
        <div class="footer-btn">
          <Button class="apply" type="primary" @click="applyCert" :loading="applyLoading"
            >提交申请</Button
          >
        </div>
      </div>
    </div>
    <!-- 审核信息 -->
    <div class="processDetail" v-if="current == 3">
      <div class="detail-title">审核信息</div>
      <div class="detail-top">
        <ButtonGroup>
          <Button type="primary" :loading="noticeLoading" @click="noticeCACheck"
            >重新验证</Button
          >
          <Dropdown>
            <Button type="primary">
              <Icon type="ios-arrow-down"></Icon>
            </Button>
            <template #list>
              <DropdownMenu>
                <DropdownItem>跟踪状态</DropdownItem>
              </DropdownMenu>
            </template>
          </Dropdown>
        </ButtonGroup>
        <span>
          请在全部添加完成再验证，否则因为DNS TTL等原因会导致下次验证需要等待更久
        </span>
      </div>
      <div class="detail-content">
        <div class="b-table">
          <Table
            :loading="loading"
            border
            :disabled-hover="true"
            :columns="dcv_info"
            :data="
              orderData.cert.place_order_data
                ? orderData.cert.place_order_data.dcv_info
                : []
            "
          >
            <template #authType="{ row }">
              <Select
                v-model="row.method"
                style="width: 100%"
                :transfer="true"
                @on-change="changeDcv(row)"
              >
                <Option value="dns">DNS</Option>
                <Option value="http">HTTP</Option>
                <Option value="https">HTTPS</Option>
                <OptionGroup
                  label="邮箱验证"
                  v-if="row.subdomain && row.subdomain != '*'"
                >
                  <Option :value="'admin@' + row.domain">admin@{{ row.domain }}</Option>
                  <Option :value="'administrator@' + row.domain"
                    >administrator@{{ row.domain }}</Option
                  >
                  <Option :value="'hostmaster@' + row.domain"
                    >hostmaster@{{ row.domain }}</Option
                  >
                  <Option :value="'postmaster@' + row.domain"
                    >postmaster@{{ row.domain }}</Option
                  >
                  <Option :value="'webmaster@' + row.domain"
                    >webmaster@{{ row.domain }}</Option
                  >

                  <Option :value="'admin@' + row.topdomain"
                    >admin@{{ row.topdomain }}</Option
                  >
                  <Option :value="'administrator@' + row.topdomain"
                    >administrator@{{ row.topdomain }}</Option
                  >
                  <Option :value="'hostmaster@' + row.topdomain"
                    >hostmaster@{{ row.topdomain }}</Option
                  >
                  <Option :value="'postmaster@' + row.topdomain"
                    >postmaster@{{ row.topdomain }}</Option
                  >
                  <Option :value="'webmaster@' + row.topdomain"
                    >webmaster@{{ row.topdomain }}</Option
                  >
                </OptionGroup>
                <OptionGroup label="邮箱验证" v-else>
                  <Option :value="'admin@' + row.topdomain"
                    >admin@{{ row.topdomain }}</Option
                  >
                  <Option :value="'administrator@' + row.topdomain"
                    >administrator@{{ row.topdomain }}</Option
                  >
                  <Option :value="'hostmaster@' + row.topdomain"
                    >hostmaster@{{ row.topdomain }}</Option
                  >
                  <Option :value="'postmaster@' + row.topdomain"
                    >postmaster@{{ row.topdomain }}</Option
                  >
                  <Option :value="'webmaster@' + row.topdomain"
                    >webmaster@{{ row.topdomain }}</Option
                  >
                </OptionGroup>
              </Select>
            </template>

            <template #dcvExplain="{ row }">
              <div class="dcv" v-if="row.method == 'dns'">
                <div class="dcv-content">
                  <span class="copy-title">请添加主机名</span>
                  （<span class="copy" @click="copy(row.dns_host)">复制</span>）
                  <div class="dns-host">
                    <div class="textBox" v-if="row.subdomain">
                      {{ row.dns_host }}
                    </div>
                    <div class="textBox" v-else>{{ row.dns_host }}</div>
                  </div>
                </div>
                <div class="dcv-content">
                  <span class="copy-title">类型</span>
                  <div class="dns-host">
                    <div class="textBox">{{ row.dns_type }}</div>
                  </div>
                </div>
                <div class="dcv-content dcv-foot">
                  <span class="copy-title">值</span>（<span
                    class="copy"
                    @click="copy(row.dns_value)"
                    >复制</span
                  >）
                  <div class="dns-host">
                    <div class="textBox">{{ row.dns_value }}</div>
                  </div>
                  <span class="copy-title">的DNS记录</span>
                </div>
              </div>

              <div class="dcv" v-else-if="row.method == 'http' || row.method == 'https'">
                <div class="dcv-content">
                  <span class="copy-title">请创建</span>（<span
                    class="copy"
                    @click="copy(row[row.method + '_filename'])"
                    >复制</span
                  >）
                  <div class="dns-host">
                    <div class="textBox">{{ row[row.method + "_filename"] }}</div>
                  </div>
                </div>
                <div class="dcv-content">
                  <span class="copy-title">内容为</span>（<span
                    class="copy"
                    @click="copy(row[row.method + '_filecontent'])"
                    >复制</span
                  >）
                  <div class="dns-host">
                    <div class="textBox">{{ row[row.method + "_filecontent"] }}</div>
                  </div>
                </div>
                <div class="dcv-content dcv-foot">
                  <span class="copy-title">上传到</span>
                  <div class="dns-host">
                    <div class="textBox">{{ row[row.method + "_verifylink"] }}</div>
                  </div>
                  <span class="copy-title">(上传后务必自测是否可访问)</span>
                </div>
              </div>
              <div class="dcv" v-else>
                <div class="dcv-content dcv-foot">
                  <span class="copy-title">请进入</span>
                  <div class="dns-host">
                    <div class="textBox">{{ row.method }}</div>
                  </div>
                  <span class="copy-title">邮箱收信</span>
                </div>
              </div>
            </template>

            <template #authStatus="{ row }">
              <div class="dcv">
                <div class="dcv-content">
                  <span class="copy-title">{{ row.status }}</span>
                </div>
                <div class="dcv-content">
                  <Button @click="checkDcv(row)">检查结果</Button>
                </div>
              </div>
            </template>
          </Table>
        </div>
      </div>
    </div>
    <!-- 签发信息 -->
    <div class="processDetail" v-if="current == 4">
      <div class="detail-title">签发信息</div>
      <div class="detail-content">
        <div class="toolBox">
          证书类型：
          <RadioGroup type="button" button-style="solid" v-model="certSignData.key_type">
            <Radio label="0">RSA</Radio>
            <Radio label="3">EC</Radio>
          </RadioGroup>
        </div>
        <div class="toolBox">
          <div class="item-title">下载、转换</div>
          <div class="item-ctrlBtn">
            <Button type="default" @click="exoprtCert('cert')">签发证书</Button>
            <Button type="default" @click="exoprtCert('issuer')">中间证书</Button>
            <Button type="default" @click="exoprtCert('both')">签发证书+中间证书</Button>
            <Button type="default" @click="exoprtCert('pfx')"
              >导出IlS(pfx格式)证书</Button
            >
          </div>
        </div>
        <div class="toolBox">
          <div class="item-title">签发证书</div>
          <div class="item-ctrlBtn">
            <pre class="buildPfx" v-if="certSignData.key_type == 0">{{
              orderData.cert.cert_ificate
            }}</pre>
            <pre class="buildPfx" v-if="certSignData.key_type == 3">{{
              orderData.cert.ec_cert_ificate
            }}</pre>
          </div>
        </div>
        <div class="toolBox">
          <div class="item-title">中间证书</div>
          <div class="item-ctrlBtn">
            <!-- <div class="buildPfx" v-html='orderData.cert.cert_ificate'></div> -->
            <pre class="buildPfx" v-if="certSignData.key_type == 0">{{
              orderData.cert.ca_cert_ificate
            }}</pre>
            <pre class="buildPfx" v-if="certSignData.key_type == 3">{{
              orderData.cert.ec_ca_cert_ificate
            }}</pre>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-model="showExoprtCertModal"
      :closable="true"
      :mask-closable="false"
      :loading="true"
      title="导出IIS证书"
    >
      <Form
        class="form"
        ref="chargeForm"
        :model="chargeData"
        :rules="ruleValidate"
        label-width="100"
        :label-position="labelPosition"
      >
        <Row :gutter="24" type="flex">
          <Col span="24">
            <FormItem label="PFX密码" prop="amount">
              <Input type="text" v-model="pfxPwd" placeholder="0.01~10000" size="large" />
            </FormItem>
          </Col>
        </Row>
      </Form>
      <template #footer>
        <div class="footerBtn">
          <Button type="primary" size="large" @click="exportIISCert('zip')"
            >转换PFX格式, 并打包 ZIP</Button
          >
          <Button type="success" size="large" @click="exportIISCert('')"
            >转换PFX格式
          </Button>
          <Button type="primary" size="large" @click="showExoprtCertModal = false"
            >取消</Button
          >
        </div>
      </template>
    </Modal>
    <companyDetailDrawer
      @refresh="reFreshCompanyList"
      ref="companyDetailRef"
    ></companyDetailDrawer>
  </div>
</template>

<script setup>
import {
  orderDetailApi,
  certApplyApi,
  checkDcvApi,
  changeDcvApi,
  noticeCACheckApi,
  companyApi,
  userInfoApi,
} from "@/api/personalCenter";
import { certExportApi } from "@/api/cert";
import CompanyDetailDrawer from "@/components/companyDetailDrawer";

import {
  ref,
  reactive,
  watchEffect,
  onMounted,
  defineProps,
  getCurrentInstance,
} from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { Message, Copy } from "view-ui-plus";
import { log } from "mathjs";

const { proxy } = getCurrentInstance(); // 获取组件实例

let router = useRouter();
let route = useRoute();
let current = ref(0);
let companyDetailRef = ref(null);

const order_id = route.query.id;
const orderData = reactive({
  add_time: "",
  pay_time: "",
  product: {
    store_name: "",
    support_san: 0,
    default_protect_domain: 0,
    max_domain: 0,
    ssl_free: 0,
  },
  placeholder: { common_effect: "" },
  cert: {
    ca_id: "",
    add_time: "",
    place_order_data: {
      dcv_info: [],
    },
  },
  refund_reason_time: "",
});
// 申请资料表单
let formItem = reactive({
  order_id: order_id,
  common_name: "",
  san: "",
  email: "",
  csr_type: "1",
  cert_type: "0",
  hash_algorithms: "sha256",
  keysize: "2048",
  csr: "",
  company_id: "",
});
// 签发证书表单
let certSignData = reactive({
  cert_id: "",
  key_type: "",
  type: "",
});
// 申请资料表单验证
const ruleItem = reactive({
  common_name: [{ required: true, message: "主域名不能为空", trigger: "blur" }],
  email: [
    { required: true, message: "联系人 Email 不能为空", trigger: "blur" },
    { type: "email", message: "请输入正确的 Email", trigger: "blur" },
  ],
  company_id: [
    { required: true, type: "number", message: "请选择公司", trigger: "change" },
  ],
});
const dcv_info = reactive([
  {
    title: "FQDN",
    key: "domain",
    minWidth: 150,
  },
  {
    title: "验证类型",
    slot: "authType",
    minWidth: 80,
  },
  {
    title: "说明",
    slot: "dcvExplain",
    minWidth: 600,
  },
  {
    title: "验证状态",
    slot: "authStatus",
    minWidth: 100,
  },
]);
let keysizeList = reactive([]);
const buildCert = ref("");
let applyLoading = ref(false);
let loading = ref(false);
let noticeLoading = ref(false);
let companyList = reactive([]);
let exportCert = ref("");
let pfxPwd = ref("");
let userInfo = reactive({})
// ----- --接口请求----------
const orderDetail = (data) => {
  orderDetailApi(data).then((res) => {

    if (res.status == 200) {
      Object.assign(orderData, res.data.info);
      if (orderData.cert == null) {
        orderData.cert = {
          ca_id: "",
          add_time: "",
        };
      } else {
        formItem = orderData.cert.place_order_data;
        certSignData.key_type = orderData.cert.key_type.toString();
        certSignData.cert_id = orderData.cert.id;
      }
      current.value = res.data.info.status_current;
    }
  });
  // .catch((res) => {
  //   Message.error(res.msg);
  // });
};
function getCompanyList(data) {
  companyApi(data).then((res) => {
    if (res.status === 200) {
        // console.log(res.data.list);
      companyList.length = 0; // 清空数组
      companyList.push(...res.data.list); // 添加新数据到数组
    }
  });
}
// -------操作按钮----------
// 去申请 按钮
const apply = () => {
  current.value = 2;
  keysizeList.length = 0;
  let List = [];
  if (current.value == 2 && orderData.product.ssl_type != 2) {
    List = [
      {
        value: "2048",
        label: "2048",
      },
      {
        value: "3072",
        label: "3072",
      },
      {
        value: "4096",
        label: "4096",
      },
    ];
  } else {
    List = [
      {
        value: "4096",
        label: "4096",
      },
    ];
  }
  keysizeList.push(...List);
  formItem.keysize = keysizeList[0].value;
};
const showCompanyDrwaer = () => {
  companyDetailRef.value.addCompany();
};
const reFreshCompanyList = () => {
  getCompanyList();
};
// 提交申请
const applyCert = () => {
  applyLoading.value = true;
  proxy.$refs.applyCertForm.validate((valid) => {
    if (valid) {
      console.log(1111);
      certApplyApi(formItem)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            Message.success("申请成功");
            orderDetail({ id: order_id });
            current.value = 3;
          }
          applyLoading.value = false;
        })
        .catch((err) => {
          Message.error(err.msg)
          applyLoading.value = false;
        });
    } else {
      applyLoading.value = false;
      return;
    }
  });
};
// 检查Dcv
const checkDcv = (row) => {
  let data = {
    order_id: order_id,
    domain: row.domain,
    type: row.method,
  };
  checkDcvApi(data)
    .then((res) => {
      row.status = "验证成功";
      Message.success("验证成功");
    })
    .catch((res) => {
      Message.error(res.msg);
    });
};
// 切换验证方式
const changeDcv = (row) => {
  let data = {
    order_id: order_id,
    domain: row.domain,
    type: row.method,
  };
  loading.value = true;
  changeDcvApi(data)
    .then((res) => {
      loading.value = false;
      Message.success("切换成功");
      orderDetail({ id: order_id });
    })
    .catch((res) => {
      loading.value = false;
      Message.error(res.msg);
    });
};
const noticeCACheck = () => {
  noticeCACheckApi({ id: order_id })
    .then((res) => {
      noticeLoading.value = false;
      Message.success(res.msg || "已通知CA检查");
    })
    .catch((res) => {
      noticeLoading.value = false;
      Message.error(res.msg);
    });
};
// 立刻付款按钮
const goPay = (id) => {
  router.push({
    path: "/payment",
    query: {
      order_id: id,
    },
  });
};
const copy = (v) => {
  Copy({
    text: v,
  });
};
const exoprtCert = (type) => {
  certSignData.type = type;

  certExportApi(certSignData)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.path != "") {
          window.location.href = `https://h.ssl-zs.com/api/download?path=${res.data.path}`;
        }
        Message.success("下载成功");
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
const showModal = () => {
  userInfoApi().then((res) => {
    if (res.status == 200) {
      pfxPwd.value = res.data.info.pfx_password;
    }
  });
  showExoprtCertModal.value = true;
};
const exportIISCert = (type) => {
  let data = {
    id: order_id,
    key_type: certSignData.key_type,
    action: type,
  };
  if (orderData.cert.place_order_data.csr_type == "0") {
    data.key = cert.place_order_data.csr;
  }
  iisCertDownApi(data)
    .then((res) => {
      if (res.status == 200) {
        if (res.data.path != "") {
          window.location.href = `https://h.ssl-zs.com/api/download?path=${res.data.path}`;
        }
        Message.success("下载成功");
      }
    })
    .catch((err) => {
      Message.error(err.msg);
    });
};
function getUserInfo(){
  userInfoApi().then(res=>{
    if(res.status ==200){
      // Object.assign(userInfo , res.data.info)
      formItem.email = res.data.info.email
    }
  })
}
onMounted(() => {
  orderDetail({ id: order_id });
  // orderDetail({id:id})
  getCompanyList();
  getUserInfo()
});
defineExpose({
  applyCertForm: proxy.$refs.applyCertForm,
});
</script>

<style lang="less" scoped>
.orderDetail-Pc {
  padding: 25px 5% 40px;

  .routerBox {
    margin-bottom: 15px;
  }

  .process {
    background-color: #fff;
    padding: 20px 40px;
    margin-bottom: 50px;

    .section-hd {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
    }
  }

  .processDetail {
    background-color: #fff;

    .detail-title {
      padding: 26px 40px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .detail-top {
      padding: 26px 40px;
      display: flex;
      flex-direction: row-reverse;
      align-items: center;

      span {
        margin: 0 10px;
      }
    }

    .detail-content {
      padding: 37px 40px;

      border-top: #f3f3f3 solid 1px;
      border-bottom: #f3f3f3 solid 1px;

      .content-item {
        display: flex;
        flex-direction: row;
        color: #000;
        margin-bottom: 15px;

        .item-label {
          min-width: 70px;
        }

        .order_price {
          color: red;
        }

        // font-family: SourceHanSansSC-regular;
      }

      .b-table {
        .dcv-content {
          margin-top: 10px;

          .copy-title {
            font-weight: 700;
          }

          .copy {
            color: #337ab7;
            cursor: pointer;
          }

          .dns-host {
            .textBox {
              // margin: 14px 0;
              padding: 14px 10px;
              border-radius: 8px;
              background-color: #f5f5f5;
              border: 1px solid #cecece;
            }
          }
        }
      }

      .toolBox {
        color: #000;
        margin-bottom: 15px;

        .item-title {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .item-ctrlBtn {
          button {
            margin-right: 5px;
          }
        }

        .buildPfx {
          width: 100%;
          height: 450px;
          overflow: scroll;
          background-color: #f5f5f5;
          border: 1px solid #cecece;
          padding: 14px 10px;
          border-radius: 5px;
        }
      }
    }

    .detail-footer {
      padding: 35px 40px;
      text-align: right;

      button {
        height: 40px;
        width: 120px;
      }
    }
  }
}

.orderDetail-notPc {
  padding: 25px 5% 20px;

  .routerBox {
    margin-bottom: 15px;
  }

  .process {
    background-color: #fff;
    padding: 20px 20px;
    margin-bottom: 25px;

    .section-hd {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      margin-bottom: 25px;
    }
  }

  .processDetail {
    background-color: #fff;

    .detail-title {
      padding: 26px 40px;
      font-size: 16px;
      font-weight: 600;
      color: #000;
    }

    .detail-top {
      padding: 26px 40px;
      display: flex;
      flex-direction: column;

      span {
        margin: 0 10px;
      }
    }

    .detail-content {
      padding: 37px 20px;
      border-top: #e3e3e3 solid 1px;
      border-bottom: #e3e3e3 solid 1px;

      .content-item {
        display: flex;
        flex-direction: row;
        color: #000;
        margin-bottom: 15px;

        .item-label {
          min-width: 70px;
        }

        .order_price {
          color: red;
        }

        // font-family: SourceHanSansSC-regular;
      }

      .b-table {
        .dcv-content {
          margin-top: 10px;

          .copy-title {
            font-weight: 700;
          }

          .copy {
            color: #337ab7;
            cursor: pointer;
          }

          .dns-host {
            .textBox {
              // margin: 14px 0;
              padding: 14px 10px;
              border-radius: 8px;
              background-color: #f5f5f5;
              border: 1px solid #cecece;
            }
          }
        }
      }

      .toolBox {
        color: #000;
        margin-bottom: 15px;

        .item-title {
          font-weight: 600;
          margin-bottom: 10px;
        }

        .item-ctrlBtn {
          button {
            margin-right: 5px;
            margin-bottom: 5px;
          }
        }

        .buildPfx {
          width: 100%;
          background-color: #f5f5f5;
          border: 1px solid #cecece;
          padding: 14px 10px;
          border-radius: 5px;
        }
      }
    }

    .detail-footer {
      padding: 20px 20px;
      text-align: right;

      button {
        height: 40px;
        width: 120px;
      }
    }
  }
}
</style>
